import React, { Component, Fragment } from 'react';
import { AJAX_REQUEST } from "../../../Constants/AppConstants";
import history from '../../../history';
import classnames from 'classnames';
import { connect } from 'react-redux';
import validateUserProfile from '../../../Validations/userProfile';
import AlertWrapper from '../../Common/AlertWrapper';
import AlertWrapperSuccess from '../../Common/AlertWrapperSuccess';
import { Row, Col } from 'react-bootstrap';

class EditAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            displayname: "",
            email: "",
            current_password: "",
            new_password: "",
            confirm_new_password: "",
            username: "",
            role: "",
            photo: "",
            affiliate_url: "",
            paypal_account: "",
            w9_form: "",
            agreement_form: "",
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            server_message: '',
            success_alert_wrapper_show: false,
            loading: true,
            saving: false
        }
        document.title = "Account Details -Prestige Labs";
    }

    componentDidMount() {
        if (this.props.user) {
            if (this.props.user.new_agreement_required == "yes") {
                history.push('/agreement');
            }
        }
        document.querySelector("body").scrollIntoView();
        AJAX_REQUEST("POST", "user/details", {}).then(results => {
            const response = results.response;
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    displayname: response.data.displayname,
                    email: response.data.email,
                    loading: false,
                    // server_message: response.message,
                });
            } else {
                this.setState({
                    loading: false,
                    server_message: response.message,
                    isFormValid: false,
                });
            }
        });
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    timeOut = (timedata) => {
        setTimeout(function () {
            this.setState({
                success_alert_wrapper_show: false
            });
        }.bind(this), timedata);
    }

    onSubmit = (e) => {
        e.preventDefault();
        const val_return = validateUserProfile(this.state);
        this.setState(val_return);
        if (val_return.isValid) {
            this.setState({ errors: {}, isLoading: true, saving: true, server_message: '', });

            AJAX_REQUEST("POST", "user/updateInfo", this.state).then(results => {
                const response = results.response;
                if (parseInt(response.code) === 1000) {
                    this.setState({
                        server_message: response.message,
                        isFormValid: true,
                        isLoading: false,
                        saving: false,
                        success_alert_wrapper_show: true,
                        current_password: "",
                        new_password: "",
                        confirm_new_password: ""
                    });
                    this.timeOut(5000);
                } else {
                    this.setState({
                        server_message: response.message,
                        isFormValid: false,
                        isLoading: false,
                        saving: false,
                    });
                }
            });
        }
    }

    render() {
        const { errors, server_message, success_alert_wrapper_show, saving } = this.state;
        const full_state = this.state;
        const errors_data = server_message;
        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <Fragment>
                            <form onSubmit={this.onSubmit} method="post" className="edit_account_form action_form account_details_form" encType="multipart/form-data">
                                <AlertWrapper errors_data={errors_data} isFormValid={this.state.isFormValid} />
                                <AlertWrapperSuccess errors_data={errors_data} success_alert_wrapper_show={success_alert_wrapper_show} />

                                <Row className="account_row pt_9">
                                    <Col md={6}>
                                        <div className="add_new_card m-0 h-100 form_input_blk">
                                            <h3 className="text_black mb_21 font_16 fw_ar_bold">Account Information</h3>
                                            <div className="form-group">
                                                <label className={classnames(null, { 'pl_error_label': errors.first_name })} htmlFor="first_name & last_name">First Name & Last Name  <span className="required">*</span></label>
                                                <div className={classnames("form-control d-flex align-items-center", { 'pl_error_input': errors.first_name })} name="first_name" id="first_name" value={full_state.first_name}>
                                                    <input onChange={this.changeHandler} type="text" name="first_name" id="first_name" value={full_state.first_name} />
                                                    <input onChange={this.changeHandler} type="text" className='last_name_input' name="last_name" id="last_name" value={full_state.last_name} placeholder='last name' />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className={classnames(null, { 'pl_error_label': errors.displayname })} htmlFor="displayname">Display name <span className="required">*</span></label>
                                                <input onChange={this.changeHandler} type="text" className={classnames("form-control", { 'pl_error_input': errors.displayname })} name="displayname" id="displayname" value={full_state.displayname} />
                                                <small className='font_12 text_default font_italic fw_ar_reg'>This will be how your name will be displayed in the account section and in reviews</small>
                                            </div>
                                            <div className="">
                                                <label className={classnames(null, { 'pl_error_label': errors.email })} htmlFor="email">Email address <span className="required">*</span></label>
                                                <input onChange={this.changeHandler} type="email" className={classnames("form-control", { 'pl_error_input': errors.email })} name="email" id="email" value={full_state.email} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="add_new_card m-0 h-100 form_input_blk">
                                            <h3 className="text_black mb_21 font_16 fw_ar_bold">Password Change</h3>
                                            <div className="form-group">
                                                <label className={classnames(null, { 'pl_error_label': errors.current_password })} htmlFor="current_password">Current Password (leave blank to leave unchanged) <span className="required">*</span></label>
                                                <input onChange={this.changeHandler} type="password" className={classnames("form-control", { 'pl_error_input': errors.current_password })} name="current_password" id="current_password" />
                                            </div>
                                            <div className="form-group">
                                                <label className={classnames(null, { 'pl_error_label': errors.new_password })} htmlFor="new_password">New Password (leave blank to leave unchanged) <span className="required">*</span></label>
                                                <input onChange={this.changeHandler} type="password" className={classnames("form-control", { 'pl_error_input': errors.new_password })} name="new_password" id="new_password" />
                                            </div>
                                            <div className="">
                                                <label className={classnames(null, { 'pl_error_label': errors.confirm_new_password })} htmlFor="confirm_new_password">Confirm New Password<span className="required">*</span></label>
                                                <input onChange={this.changeHandler} type="password" className={classnames("form-control", { 'pl_error_input': errors.confirm_new_password })} name="confirm_new_password" id="confirm_new_password" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="mt_66 d-flex justify-content-center">
                                    <button type="submit" disabled={this.state.isLoading} className="cursor-pointer add_new_btn d-flex font_16 text_white fw_ar_reg" name="login" value="Login"> {this.state.action_btn_label ? "Saving..." : "Save Changes"} </button>
                                </div>
                            </form>
                        </Fragment>
                }
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    }
}

export default connect(mapStateToProps)(EditAccount);