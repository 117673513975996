import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { AJAX_REQUEST, CURRENCY_FORMAT } from "../../../../Constants/AppConstants";
import Pagination from '../../../Common/Pagination';

class MemberCommission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            member_commission: [],
            error_meg: '',
            total_records: 0,
            total_page: 0,
            per_page: 0,
            pagenum: 1,
            loading: true
        }
        document.title = "Member Commission -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        this.pagenationHandle(1);
    }

    pagenationHandle = (pageNumber) => {
        this.setState({ loading: true });
        const pagenum = parseInt(pageNumber);
        AJAX_REQUEST("POST", "distributor/getMemberCommission", { pagenum: pagenum }).then(results => {
            if (parseInt(results.response.code) === 1000) {
                let data = results.response.data;
                this.setState({
                    member_commission: data.member_commission,
                    total_records: parseInt(data.total_records),
                    total_page: parseInt(data.total_page),
                    per_page: parseInt(data.per_page),
                    pagenum: parseInt(data.pagenum),
                    error_meg: results.response.message,
                    loading: false,
                });
            } else {
                this.setState({
                    loading: false,
                    error_meg: results.response.message,
                    total_records: 0,
                    total_page: 0,
                    per_page: 0,
                    pagenum: 1,
                    member_commission: []
                });
            }
        });
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <Fragment>
                            <div className="woocommerce-MyAccount-content inner_content">
                            <div className="mb_15 d-flex align-items-center justify-content-between">
                                    <div>
                                        <h2 className="font_26 m-0 text_black let_spa_2 fw_el_bold">Team Member Commission Payout(UP TO LAST PAYOUT DATE)</h2>
                                        <h3 className="font_16 m-0 text_black fw_ar_reg">Affiliate Dashboard &gt; Orders &gt; Team Member Commission Payout</h3>
                                    </div>
                                    <Link className="font_16 text_black text_underline fw_ar_reg" to="/my-account/member-commission/running-commission">RUNNING COMMISSION</Link>
                                </div>
                               <div className="table-responsive">
                               <table className="w-100 subscribtion_table">
                                    <thead>
                                        <tr>
                                            <th className="order-number">Payout Period</th>
                                            <th className="order-number">Total Member</th>
                                            <th className="order-date">Order Count</th>
                                            <th className="order-status">Order Amount</th>
                                            <th className="order-representative">Commission Total</th>
                                            <th className="order-total">Cancel/Refund Amount</th>
                                            <th className="order-actions">Payable Commission</th>
                                            <th className="order-actions">Payout Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.member_commission.length <= 0 ? <tr><td className="text-center" colSpan="8">{this.state.error_meg}</td></tr> :
                                                this.state.member_commission.map(function (order, key) {
                                                    return (
                                                        <tr className="order" key={key}>
                                                            <td className="order-number" data-title="Payout Period">
                                                                <Link className=" pull-right mob_left_right_none" to={`/my-account/member-commission/${order.payout_id}`}>{order.payout_period}</Link>
                                                            </td>
                                                            <td className="order-date" data-title="Total Member">
                                                                {order.total_member}
                                                            </td>
                                                            <td className="order-date" data-title="Order Count">
                                                                {order.order_count}
                                                                <span className="count-total-wrap"><span>S: {order.supplement_order_count}</span><span> M: {order.meal_order_count}</span></span>
                                                            </td>
                                                            <td className="order-status" data-title="Order Amount">
                                                                {order.order_amount}
                                                                <span className="count-total-wrap"><span>S: {order.supplement_order_amount}</span><span> M: {order.meal_order_amount}</span></span>
                                                            </td>
                                                            <td className="order-representative" data-title="Commission Total">
                                                                {order.commission_total}
                                                                <span className="count-total-wrap"><span>S: {order.supplement_commission_total}</span><span> M: {order.meal_commission_total}</span></span>
                                                            </td>
                                                            <td className="order-total" data-title="Cancel/Refund Amount">
                                                                {order.cancel_refund_amount}
                                                                <span className="count-total-wrap"><span>S: {order.supplement_cancel_refund_amount}</span><span> M: {order.meal_cancel_refund_amount}</span></span>
                                                            </td>
                                                            <td className="order-total" data-title="Payable Commission">
                                                                {order.payable_commission}
                                                                <span className="count-total-wrap"><span>S: {order.supplement_payable_commission}</span><span> M: {order.meal_payable_commission}</span></span>
                                                            </td>
                                                            <td className="order-actions toTitleCase" data-title="Payout Status">
                                                                {order.payout_status}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                        }
                                    </tbody>
                                </table>
                               </div>

                                <div className="table-note font_16 mt_10 text_black fw_ar_reg">
                                    <span>*</span><span>S=Supplement</span> <span>M=Meal</span>
                                </div>

                                <Pagination
                                    pagenationHandle={this.pagenationHandle}
                                    total_records={this.state.total_records}
                                    total_page={this.state.total_page}
                                    per_page={this.state.per_page}
                                    pagenum={this.state.pagenum}
                                />

                            </div>
                        </Fragment>
                }
            </Fragment>
        );
    }
}
export default MemberCommission;