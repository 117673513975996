import React, { PureComponent, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import $ from "jquery";
import DashboardIcon from '../../../Assets/images/customer_images/dashboard_icon.svg'
import DashboardIconActive from '../../../Assets/images/customer_images/dashboard_icon_active.svg'
import OrderIconActive from '../../../Assets/images/customer_images/orders_icon_active.svg'
import SubscriptionIconActive from '../../../Assets/images/customer_images/subscription_icon_active.svg'
import AddressIconActive from '../../../Assets/images/customer_images/address_icon_active.svg'
import AccountIconActive from '../../../Assets/images/customer_images/account_detail_icon_active.svg'
import CardIconActive from '../../../Assets/images/customer_images/my_card_icon_active.svg'
import OrderIcon from '../../../Assets/images/customer_images/orders_icon.svg'
import SubscriptionIcon from '../../../Assets/images/customer_images/subscription_icon.svg'
import AddressIcon from '../../../Assets/images/customer_images/address_icon.svg'
import AccountIcon from '../../../Assets/images/customer_images/account_detail_icon.svg'
import CardIcon from '../../../Assets/images/customer_images/my_card_icon.svg'
import MenuDropdown from '../../../Assets/images/affiliate_imges/affi_dropdown.svg'
import PaymentIcon from '../../../Assets/images/affiliate_imges/payment_icon.svg'
import TaxIcon from '../../../Assets/images/affiliate_imges/tax_icon.svg'
import TaxActiveIcon from '../../../Assets/images/affiliate_imges/tax_active_icon.svg'

class MasterAffSideMenuLink extends PureComponent {

    render() {
        return (
            <Fragment>
                <li>
                    <NavLink to="/my-affiliate-account" activeClassName="active d-flex align-items-center font_16 fw_ar_bold" exact><img src={DashboardIcon} alt="dashboard_icon" className='inactive_icon' /> <img src={DashboardIconActive} alt="dashboard_icon" className='active_icon' /> Dashboard</NavLink>
                </li>
                <li>
                    <NavLink to="/my-affiliate-account/affiliate-list" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"><img src={OrderIcon} alt="orderIcon" className='inactive_icon' /><img src={OrderIconActive} alt="OrderIconActive" className='active_icon' /> Affiliate List</NavLink>
                </li>
                <li>
                    <NavLink to="/my-affiliate-account/commission-earned" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"><img src={SubscriptionIcon} alt="SubscriptionIcon" className='inactive_icon' /><img src={SubscriptionIconActive} alt="SubscriptionIconActive" className='active_icon' /> Commission Earned</NavLink>
                </li>
                <li>
                    <NavLink to="/my-affiliate-account/payment-received" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"><img src={AddressIcon} alt="AddressIcon" className='inactive_icon' /><img src={AddressIconActive} alt="AddressIconActive" className='active_icon' /> Payment Received</NavLink>
                </li>
                {/*<li>
                    <NavLink to="/my-affiliate-account/w-9-form" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"><i className="fa fa-calendar-check-o" aria-hidden="true"></i>W-9 Form</NavLink>
                </li>*/}
                <li>
                    <NavLink to="/my-affiliate-account/paypal-account" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"><img src={CardIcon} alt="CardIcon" className='inactive_icon' /><img src={CardIconActive} alt="CardIconActive" className='active_icon' /> PayPal Email</NavLink>
                </li>
                <li>
                    <NavLink to="/my-affiliate-account/edit-account" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"><img src={AccountIcon} alt="AccountIcon" className='inactive_icon' /><img src={AccountIconActive} alt="AccountIconActive" className='active_icon' /> Account Details</NavLink>
                </li>
            </Fragment>
        );
    }
}

export default withRouter(MasterAffSideMenuLink);