import React, { Component, Fragment } from 'react';
import { CURRENCY_FORMAT } from '../../../Constants/AppConstants';

class StatisticsReportsRightContent extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Fragment>
                {
                    this.props.reportLoading ?
                        <div className="loading"></div>
                        :
                        <Fragment>
                            <div className="tab-pane fade show active white_bg sub_billing_blk" id="tab_data" role="tabpanel" aria-labelledby="today-tab">
                                <div className="analytics_wrapper">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <h3 className="font_16 mb_5 text_black fw_ar_bold">Success Order Amount</h3>
                                                <h3 className="font_20 m-0 text-black fw_ar_bold">{this.props.report_data.success_order_amount}</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <h3 className="font_16 mb_5 text_black fw_ar_bold">Number of Success Order</h3>
                                                <h3 className="font_20 m-0 text-black fw_ar_bold">{this.props.report_data.number_of_success_order}</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <h3 className="font_16 mb_5 text_black fw_ar_bold">Success Order Ratio</h3>
                                                <h3 className="font_20 m-0 text-black fw_ar_bold">{this.props.report_data.success_order_ratio}</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <h3 className="font_16 mb_5 text_black fw_ar_bold">Refund Amount</h3>
                                                <h3 className="font_20 m-0 text-black fw_ar_bold">{this.props.report_data.refund_amount}</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <h3 className="font_16 mb_5 text_black fw_ar_bold">Refund Ratio</h3>
                                                <h3 className="font_20 m-0 text-black fw_ar_bold">{this.props.report_data.refund_ratio}</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <h3 className="font_16 mb_5 text_black fw_ar_bold">Canceled Amount</h3>
                                                <h3 className="font_20 m-0 text-black fw_ar_bold">{this.props.report_data.canceled_amount}</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <h3 className="font_16 mb_5 text_black fw_ar_bold">Commission Earned</h3>
                                                <h3 className="font_20 m-0 text-black fw_ar_bold">{this.props.report_data.commission_earned}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                }
            </Fragment>
        );
    }
}

export default StatisticsReportsRightContent;