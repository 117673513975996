import React, { Fragment, PureComponent } from 'react';
import { SET_STORAGE, GET_STORAGE, NEXT_MONTH, AJAX_REQUEST, BUNDLE_CART_COUNT } from "../../Constants/AppConstants";
import OutOfStockButton from "./OutOfStockButton";
import $ from "jquery";
import history from "../../history";

class AddToCartButton extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            addToCart: "Add to Cart",
        }
    }

    addToCart = (e) => {

        let cart = [];
        let data = [];
       console.log(BUNDLE_CART_COUNT(), 'BUNDLE_CART_COUNT');
        if(BUNDLE_CART_COUNT() != 0){
            $(`#${'alertModel'}`).modal({
                backdrop: 'static',
                keyboard: false,
            });
        } else{
            if (!GET_STORAGE("cart")) {
                SET_STORAGE("cart", JSON.stringify(cart));
            }
            cart = JSON.parse(GET_STORAGE("cart"));

            let newItem = {
                cart_product_id: e.target.getAttribute('cart_product_id'),
                cart_product_name: e.target.getAttribute('cart_product_name'),
                cart_product_description: e.target.getAttribute('cart_product_description'),
                cart_product_sku: e.target.getAttribute('cart_product_sku'),
                cart_product_isBundle: e.target.getAttribute('cart_product_isBundle'),
                cart_image: e.target.getAttribute('cart_image'),
                cart_variation_id: e.target.getAttribute('cart_variation_id'),
                cart_variation_name: e.target.getAttribute('cart_variation_name'),
                cart_sale_price: e.target.getAttribute('cart_sale_price'),
                subscription: e.target.getAttribute('subscription'),
                cart_subscription_msg: e.target.getAttribute('cart_subscription_msg'),
                cart_discount_price: e.target.getAttribute('cart_discount_price'),
                quantity: parseInt(e.target.getAttribute('quantity')),
                in_stock: parseInt(e.target.getAttribute('cart_quantity')),
                in_stock: parseInt(e.target.getAttribute('cart_quantity')),
                warehouse: e.target.getAttribute('warehouse'),
            }

            const addToCartDb = this.addToCartDb;

            if (cart.length > 0) {
                cart.forEach(function (item, key) {
                    if ((item.cart_variation_id == e.target.getAttribute('cart_variation_id')) && (item.subscription.toLowerCase() == e.target.getAttribute('subscription').toLowerCase())) {
                        if (parseInt(item.quantity) >= parseInt(e.target.getAttribute('cart_quantity'))) {
                            alert("Out Of Stock") // Check product quantity
                        } else {
                            item.quantity = Number(Number(item.quantity) + 1);
                        }
                        data.push(item);
                        addToCartDb(item); // Cart add to db  
                        newItem = null;

                    } else {
                        data.push(item);
                    }
                });
                if (newItem != null) {
                    data.push(newItem);
                    addToCartDb(newItem); // Cart add to db
                }
            } else {
                data.push(newItem);
                addToCartDb(newItem); // Cart add to db
            }

            SET_STORAGE("cart", JSON.stringify(data));
            this.props.cartState.itemCount(); // Update cart item_count method
            this.addToCartLabelChange();
        }
    }

    addToCartDb = (data) => {
        AJAX_REQUEST("POST", "cart/saveItem", data).then(results => {
            if (parseInt(results.response.code) !== 1000) {
                this.setState({ error: results.response.message });
                this.props.updateError(results.response.message);
            }
        });
    }

    handleCart = () => {
        history.push({
            pathname: "/cart",
            state: { productType: 'regular_product' }
        });
    }

    addToCartLabelChange = (e) => {
        this.setState({
            addToCart: "Adding..."
        })

        setTimeout(function () {
            this.setState({
                addToCart: "Thank You"
            })
        }.bind(this), 1000)

        setTimeout(function () {
            this.setState({
                addToCart: "Add More ..."
            })
        }.bind(this), 2000)
    }

    render() {
        let cart = this.props.cartState;

        if (this.props.firstMonth) {

            if (cart.cartFirstQuantity <= 0) {
                return (
                    <OutOfStockButton />
                )
            }

            return (
                <Fragment>
                <input
                    onClick={this.addToCart}
                    cart_product_id={cart.cartProductId}
                    cart_product_name={cart.cartProductName}
                    cart_product_description={cart.cartProductDescription}
                    cart_product_sku={cart.cartProductSKU}
                    cart_product_isBundle={cart.cartProductIsBundle}
                    cart_image={cart.cartImage}
                    cart_variation_id={cart.cartFirstVariationId}
                    cart_variation_name={cart.cartFirstVariationName}
                    cart_sale_price={cart.cartFirstSalePrice}
                    subscription={cart.subscription}
                    cart_subscription_msg={cart.cartSubscriptionMsg}
                    cart_discount_price={cart.cartDiscountPrice}
                    cart_quantity={cart.cartFirstQuantity}
                    quantity={1}
                    warehouse={cart.warehouse}
                    value={this.state.addToCart} name="addToCart" className="cart_add_product_btn" type="button" />

                    <div className="modal fade" id="alertModel" tabIndex="-1" role="dialog" aria-labelledby="alertModel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header cus-modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body montserrat">
                                    <p className="font_16 text_black fw_ar_reg m-0">
                                        You cannot add a custom bundle product to your cart while a regular product is already in it, You can delete custom bundle product to proceed with the regular product.
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.handleCart} type="button" className="cus_button" data-dismiss="modal">OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {

            if (cart.cartQuantity <= 0) {
                return (
                    <OutOfStockButton />
                )
            }

            return (
                <Fragment>
                <input
                    onClick={this.addToCart}
                    cart_product_id={cart.cartProductId}
                    cart_product_name={cart.cartProductName}
                    cart_product_description={cart.cartProductDescription}
                    cart_product_sku={cart.cartProductSKU}
                    cart_product_isBundle={cart.cartProductIsBundle}
                    cart_image={cart.cartImage}
                    cart_variation_id={cart.cartVariationId}
                    cart_variation_name={cart.cartVariationName}
                    cart_sale_price={cart.cartSalePrice}
                    subscription="no"
                    cart_subscription_msg=""
                    cart_discount_price="0.00"
                    cart_quantity={cart.cartQuantity}
                    quantity={1}
                    value={this.state.addToCart} name="addToCart" className="cart_add_product_btn" type="button" />

                    <div className="modal fade" id="alertModel" tabIndex="-1" role="dialog" aria-labelledby="alertModel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header cus-modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body montserrat">
                                    <p className="font_16 text_black fw_ar_reg m-0">
                                        You cannot add a custom bundle product to your cart while a regular product is already in it, You can delete custom bundle product to proceed with the regular product.
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.handleCart} type="button" className="cus_button" data-dismiss="modal">OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
        
    }
}

export default AddToCartButton;
