import React, { Component, Fragment } from 'react';
import { CURRENCY_FORMAT } from "../../../Constants/AppConstants";
import productImage from '../../../Assets/images/customer_images/order_product_img.svg'

class Subscriptions extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    removeSubscription = (event) => {
        if (this.props.removeSubscItem !== '') {
            const theItemId = event.currentTarget.dataset.item;
            if (window.confirm('Are you sure you want remove this item from your subscription?')) this.props.removeSubscItem(theItemId);
        }
    }

    render() {
        return (

            <Fragment>
                <div className="order_pro_detail d-flex align-items-center justify-content-between">
                    <div className="left_pro_de d-flex align-items-center w-50">
                        <img src={productImage} alt="productImage" className='product_image' />
                        <div className="pl_16">
                            <h5 className="font_14 mb_6 text_black fw_el_bold let_spa_2">{this.props.item.hasOwnProperty('name') ? this.props.item.name : ''}</h5>
                            {
                                (this.props.subscriptionType == 'meal') ? '' :
                                    (this.props.paymentMethod == 'paypal') ? (
                                        (this.props.tenDaysDifference == true) ? (
                                            <a title="Delete" data-item={this.props.item.hasOwnProperty('item_id') ? this.props.item.item_id : ''} onClick={this.removeSubscription} className="font_12 red_text d-block fw_ar_reg cursor-pointer">Remove</a>
                                        ):  ''
                                    ): 
                                <a title="Delete" data-item={this.props.item.hasOwnProperty('item_id') ? this.props.item.item_id : ''} onClick={this.removeSubscription} className="font_12 red_text d-block fw_ar_reg cursor-pointer">Remove</a>
                            }
                        </div>
                    </div>
                    <div className="right_pro d-flex flex-column align-items-end">
                        <h5 className="font_14 text_black let_spa_2 fw_ar_bold m-0">{this.props.item.hasOwnProperty('total_price') ? CURRENCY_FORMAT(this.props.item.total_price) : ''}</h5>
                    </div>
                </div>
                {/* <span></span> / {this.props.item.billing_interval} {this.props.item.duration} */}
            </Fragment>
        );
    }
}

export default Subscriptions;
