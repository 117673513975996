import React, { Fragment, PureComponent } from "react";
import { NavLink } from "react-router-dom";
import { AJAX_PUBLIC_REQUEST, AJAX_REQUEST, ITEM_COUNT, MEAL_COUNT, GET_STORAGE, BASE_URL, BUNDLE_ITEM_COUNT } from "../../Constants/AppConstants";
import PropTypes from "prop-types";
import history from '../../history';
import { connect } from 'react-redux';
import $ from "jquery";
import Parser from 'html-react-parser';
import { Row, Col } from "react-bootstrap";
import bannerSlider1 from '../../Assets/images/affiliate_imges/affiliate_slider_1.svg'
import SearchIcon from './../../Assets/images/customer_images/header_search.svg'

import ProductsListWithFlavors from "./ProductsListWithFlavors";
import ProductsList from "./ProductsList";
import SingleProductNotAvailableModal from "./SingleProductNotAvailableModal";
import OwlCarousel from 'react-owl-carousel';

class OrderPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: '',
            products: [],
            products_filtered: [],
            banner_image: [],
            url:'',
            searchName: '',
            cart_count: 0,
            productNotAvailable: [],
            tag_id: this.props.match.params.tag_id,
            tagName: ''
        }
        document.title = "Order -Prestige Labs";
    }

    itemCount = (e) => {
        if(ITEM_COUNT() != 0){
            const count = Number(ITEM_COUNT()) + Number(MEAL_COUNT());
            this.setState({ cart_count: count });
        } else{
            let bundle = GET_STORAGE("BundleCart") ? JSON.parse(GET_STORAGE("BundleCart")) : [];
            const bundle_count = (BUNDLE_ITEM_COUNT() > 1) ? 1: 0;
            this.setState({ cart_count: bundle_count });
        }
        
    }

    updateError = (error) => {
        // document.querySelector("body").scrollIntoView();
        // this.setState({
        //     error
        // })
        // setTimeout(function () {
        //     this.setState({
        //         error: ''
        //     })
        // }.bind(this), 5000)
    }

    componentDidMount() {
        if (this.props.user) {
            if (this.props.user.new_agreement_required == "yes") {
                history.push('/agreement');
            }
        }
        if ((this.props.user.roles != undefined) && !Object.values(this.props.user.roles).includes('distributor') && Object.values(this.props.user.roles).includes('master_affiliate')) {
            history.push('/my-account');
        }
        document.querySelector("body").scrollIntoView();
      
        this.getTaggedProducts();
        this.getNewBanners();
        this.itemCount();

        let tagData = JSON.parse(GET_STORAGE('tagData'));
        let selectedTag = tagData.find(tag => tag.id === this.state.tag_id);
        this.setState({ tagName: selectedTag.tag_name });
    }
    getTaggedProducts = () => {
        const data = {
            tag_id: this.state.tag_id
        }
        AJAX_REQUEST("POST", "product/getList", data).then(results => {
            if (results.response.code === 1000) {
                this.setState({
                    products: results.response.data,
                    products_filtered: results.response.data,
                    loading: false,
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                    // Pagination Config
                    item_count: 0,
                    total_records: 0,
                    total_page: 0,
                    per_page: 0,
                    pagenum: 1,
                })
            }
        });
    }

    getNewBanners = () => {
        const data ={
            site: 'affiliate'
        }
        AJAX_PUBLIC_REQUEST("POST", "product/getBannerData", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    banner_image: results.response.data,
                    url: results.response.data.url
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                })
            }
        });
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    filterProduct = (e) => {
        this.setState({
            searchName: e.target.value,
            products_filtered: this.state.products.filter(function (product) {
                if (e.target.value === '') {
                    return product;
                } else {
                    let string = product.title.toUpperCase();
                    let substring = e.target.value.toUpperCase();
                    if (string.includes(substring)) {
                        return product;
                    }
                }
            }.bind(this))
        });
    }

    options1 = {
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            1025: {
                items: 1
            },
        }
    };

    render() {

        const notAvailablePopup = this.notAvailablePopup;
        // Added ads banner
        let offer_banner_showing_status = false;
        let offer_banner_image = require('../../Assets/images/slideshow_3.jpg');
        let settings = GET_STORAGE('settings');
        if (settings) {
            settings = JSON.parse(settings);
            if (settings) {
                if (settings.offer_banner_showing_status && settings.offer_banner_showing_status == "yes") {
                    offer_banner_showing_status = true;
                    offer_banner_image = settings.offer_banner_image;
                }
            }
        }

        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className="loading container full_page_loader"></div>
                        :
                        <Fragment>
                            <div className="banner_section public_ban_section position-relative">
                                <div className="banner_section public_ban_section position-relative public_bundle_product">
                                    <OwlCarousel className='owl-theme' {...this.options1} dots={false} loop nav margin={0} autoplay autoplaySpeed={100} autoplayHoverPause>
                                        {this.state.banner_image.length > 0 ? (
                                            this.state.banner_image.map((banner, index) => (
                                                <div className='item' key={index}>
                                                    <a className="d-flex w-100">
                                                        <img src={banner.large_image} alt={`Banner ${index}`} className="banner_section w-100" />
                                                    </a>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="carousel-item active">
                                                {/* <p className='text_black text-center font_16 m-0' style="padding: 20px 10px;">No banners available.</p> */}
                                            </div>
                                        )}
                                    </OwlCarousel>
                                </div>
                            </div>
                            {/* Banner HTML Ends */}
                            <div className="order_container ">
                                <div className="container">
                                    <div className="d-flex align-items-center mb_28 justify-content-between affilate_create_blk">
                                        <h2 className="product_itle text_black let_spa_2 font_24 fw_el_semi m-0">{this.state.tagName} Product Lists</h2>
                                       <div className="d-flex justify-content-end aff_bundle_blk">
                                            <form className="product-search-form product_search_input position-relative form_input_blk">
                                                <div className="search-input-group">
                                                    <input onChange={this.filterProduct} value={this.state.searchName}
                                                        className="form-control" placeholder="Search" name="searchName" id="filter-by" type="text" />
                                                </div>
                                                <div className="search_icon position-absolute d-flex align-items-center justify-content-center">
                                                    <img src={SearchIcon} alt="search icon" />
                                                </div>
                                            </form>
                                            <NavLink className="shop_more take_quiz_btn font_16 text_white fw_ar_reg ml_20 cursor-pointer" to={"/custom-bundle"} >Build Your Own Bundle</NavLink>
                                       </div>
                                    </div>

                                    <div className="order_pro_list_blk">
                                        {
                                            (this.state.products_filtered.length <= 0) ?
                                                <tr><td className="text-center" colSpan="3">No Product Added to {this.state.tagName}!</td></tr>
                                                :
                                                this.state.products_filtered.map(function (product, key) {
                                                    product.notAvailable = this.state.productNotAvailable.includes(product.title) ? true : false;
                                                    // Added cart item_count
                                                    product.item_count = this.itemCount;

                                                    return (
                                                        <Fragment key={key}>
                                                            {
                                                                product.hasOwnProperty('flavors') ?
                                                                    <ProductsListWithFlavors key={product.product_id} product={product} updateError={this.updateError} />
                                                                    :
                                                                    <ProductsList key={product.product_id} product={product} updateError={this.updateError} />
                                                            }
                                                        </Fragment>
                                                    )
                                                }.bind(this))
                                        }
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <main>

                                                <Fragment>
                                                    {
                                                        (offer_banner_showing_status) ?
                                                            <div className="affiliate-banner">
                                                                <img className="" src={offer_banner_image} alt="Banner Image" />
                                                            </div>
                                                            : ''
                                                    }
                                                </Fragment>

                                                <div className="product-list-container">
                                                    {
                                                        this.state.error != '' ?
                                                            <div className="alert-wrapper alert-error">
                                                                <ul className="alert-error">
                                                                    <li><i className="fa fa-times-circle" aria-hidden="true"></i> <strong>Error:</strong> {Parser(this.state.error)}</li>
                                                                </ul>
                                                            </div>
                                                            :
                                                            ''
                                                    }
                                                   
                                                </div>
                                            </main>

                                            <div className="shopping-cart-wrapper">
                                                <NavLink to="/cart" title="Shopping cart">
                                                    <div className="shopping-cart">
                                                        <span id="expressCartItemCount" className="item-count"> {this.state.cart_count} </span> item(s)
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <SingleProductNotAvailableModal />
                        </Fragment>
                }
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    }
}

export default connect(mapStateToProps)(OrderPage);
