import React, { PureComponent, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

class CustSideMenuLink extends PureComponent {

    render() { 
        return ( 
            <Fragment>
                      
            </Fragment>
         );
    }
}

export default CustSideMenuLink;