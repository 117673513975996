import React, { Fragment, PureComponent } from 'react';
import { NavLink } from "react-router-dom";
import { AJAX_REQUEST, CURRENCY_FORMAT, ENABLE_NEW_LOGIN } from "../../../Constants/AppConstants";
import $ from "jquery";
import Parser from 'html-react-parser';
import { Row, Col } from 'react-bootstrap';
import CopyIcon from './../../../Assets/images/customer_images/copy_icon.svg'
import FacebookIcon from './../../../Assets/images/customer_images/dashboard_facebook_icon.svg'
import InstagramIcon from './../../../Assets/images/customer_images/dashboard_instagram_icon.svg'

class Dashboard extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            userInfo: [],
            loading: true,
            errorMeg: '',
        }
        document.title = "My Account -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        this.getUserInfo();
    }

    copyAffiliateURL = (e) => {
        var copyText = document.getElementById("affiliateURL");
        copyText.select();
        document.execCommand("copy");
        $("#copyMsg").show();
        $("#copyMsg").hide(1000);
    }

    copyMealAffiliateURL = (e) => {
        var copyText = document.getElementById("mealAffiliateURL");
        copyText.select();
        document.execCommand("copy");
        $("#copyMealMsg").show();
        $("#copyMealMsg").hide(1000);
    }

    getUserInfo = () => {
        AJAX_REQUEST("POST", "master_affiliate/dashboard", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    userInfo: results.response.data,
                    loading: false,
                });
            } else {
                this.setState({
                    errorMeg: results.response.message,
                    loading: false,
                })
            }
        });
    }

    render() {
        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className="loading"></div>
                        :
                        <Fragment>
                            {
                                ENABLE_NEW_LOGIN && this.state.userInfo.video_embed !== '' ?
                                    <div className="d_embeed_video user_login_join_video" style={{ overflow: 'hidden', width: '100%', marginBottom: '25px' }}>
                                        {
                                            (this.state.userInfo.video_embed != null) && (this.state.userInfo.video_embed != '') ?
                                                <div className="d_embeed_video" style={{ overflow: 'hidden', width: '100%', marginBottom: '10px' }}>
                                                    {Parser(this.state.userInfo.video_embed)}
                                                    {/* <div className="wistia_embed wistia_async_rpyx2ikvy4 popover=true popoverAnimateThumbnail=true" style={{display:'inline-block', height:'150px', position:'relative', width:'300px',}}>&nbsp;</div> */}
                                                </div>
                                                : ''
                                        }
                                    </div>
                                    : ''
                            }

                            {
                                ENABLE_NEW_LOGIN && this.state.userInfo.content !== '' ?
                                    <div className="d_embeed_video user_login_join_video" style={{ overflow: 'hidden', width: '100%', marginBottom: '25px' }}>
                                        {
                                            (this.state.userInfo.content != null) && (this.state.userInfo.content != '') ?
                                                <Fragment>
                                                    {Parser(this.state.userInfo.content)}

                                                </Fragment>
                                                : ''
                                        }
                                    </div>
                                    : ''
                            }

                            <h2 className="font_26 m-0 text_black let_spa_2 fw_el_bold">Dashboard</h2>
                            <h3 className="font_16 mb_15 text_black fw_ar_reg">Master Affiliate Dashboard &gt; Dashboard</h3>
                            <Row className='dashboard_row aff_dashboard_row'>
                                <Col md={6}>
                                    <div className="dashboar_col">
                                        <h3 className="font_16 text_black fw_el_bold m-0 text-uppercase">Summary</h3>
                                        <div className="aff_order_div d-flex flex-column justify-content-start">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb_33">
                                                        <h3 className="font_16 mb_5 text_black fw_ar_bold">Today's Earnings</h3>
                                                        <h3 className="font_20 m-0 text-black fw_ar_bold"><NavLink to="/my-affiliate-account/commission-earned" className="text_black">{this.state.userInfo.hasOwnProperty('todays_earn') ? this.state.userInfo.todays_earn : ''}</NavLink></h3>
                                                    </div>
                                                    <div className="">
                                                        <h3 className="font_16 mb_5 text_black fw_ar_bold">Lifetime Earnings</h3>
                                                        <h3 className="font_20 m-0 text-black fw_ar_bold"><NavLink className="text_black" to="/my-affiliate-account/commission-earned">{this.state.userInfo.hasOwnProperty('lifetime_earn') ? this.state.userInfo.lifetime_earn : ''}</NavLink></h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="">
                                                        <h3 className="font_16 mb_5 text_black fw_ar_bold">Total Affiliate:</h3>
                                                        <h3 className="font_20 m-0 text-black fw_ar_bold"><NavLink className="text_black" to="/my-affiliate-account/affiliate-list">{this.state.userInfo.hasOwnProperty('total_distributor') ? this.state.userInfo.total_distributor : ''}</NavLink></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="dashboar_col">
                                        <h3 className="font_16 text_black fw_el_bold mb_11 text-uppercase">Commission (Since Last Payout)</h3>
                                        <ul className="tier_ul">
                                            <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Total Earned by Affiliates </span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg"><NavLink className="text_black" to="/my-affiliate-account/commission-earned"> {this.state.userInfo.hasOwnProperty('todays_distributor_earn') ? CURRENCY_FORMAT(this.state.userInfo.todays_distributor_earn) : ''}</NavLink></span>
                                            </li>
                                            <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Total I've Earned</span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg"><NavLink className="text_black" to="/my-affiliate-account/commission-earned">{this.state.userInfo.hasOwnProperty('total_earn') ? CURRENCY_FORMAT(this.state.userInfo.total_earn) : ''}</NavLink></span>
                                            </li>
                                            <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Total Received</span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg"><NavLink className="text_black" to="/my-affiliate-account/payment-received">{this.state.userInfo.hasOwnProperty('total_received') ? CURRENCY_FORMAT(this.state.userInfo.total_received) : ''}</NavLink></span>
                                            </li>
                                            <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Total Refund/Chargeback</span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg"><NavLink className="text_black" to="/my-affiliate-account/commission-earned">{this.state.userInfo.hasOwnProperty('total_cancel_refund') ? CURRENCY_FORMAT(this.state.userInfo.total_cancel_refund) : ''}</NavLink></span>
                                            </li>
                                            <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Total Adjustment</span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg"><NavLink className="text_black" to="/my-affiliate-account/commission-earned">{this.state.userInfo.hasOwnProperty('total_adjustment') ? CURRENCY_FORMAT(this.state.userInfo.total_adjustment) : ''}</NavLink></span>
                                            </li>
                                            <li className="d-flex align-items-center flex-wrap justify-content-between">
                                                <span className="total_span let_spa_2 d-block font_16 fw_ar_bold">Current Balance</span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_bold"><NavLink className="text_black" to="/my-affiliate-account/commission-earned">{this.state.userInfo.hasOwnProperty('current_balance') ? CURRENCY_FORMAT(this.state.userInfo.current_balance) : ''}</NavLink></span>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>

                                {
                                    this.state.userInfo.affiliate_url && (this.state.userInfo.affiliate_url != '') ?
                                        <Col md={4}>
                                            <div className="dashboar_col position-relative">
                                                <h3 className="font_16 text_black fw_el_bold mb_7 text-uppercase">Referral Link</h3>
                                                {/* <p className="para font_14 line_height_22 text_black mb_42 fw_ar_reg">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod </p> */}
                                                <input type="text" className='mb_15 font_16 text_black fw_ar_reg' value={this.state.userInfo.hasOwnProperty('affiliate_url') ? this.state.userInfo.affiliate_url : ''} id="affiliateURL" readOnly />
                                                <button className="w-100 d-flex align-items-center mb_17 justify-content-center copied_btn" onClick={this.copyAffiliateURL}>
                                                    <img src={CopyIcon} alt="copy_icon" />
                                                    <span className="pl_5 font_12 text_black fw_ar_bold ">Copy referral link</span>
                                                </button>
                                                <span id="copyMsg" className='font_14 fw_ar_reg' style={{ color: 'green', display: 'none' }}>Copied!</span>
                                                {/* <span className="text-center d-block mb_14 font_12 let_spa_2 text_black">or Share via</span>
                                                <div className="d-flex justify-content-center">
                                                    <NavLink to={'#'}><img src={InstagramIcon} alt="instagrram icon" className="mr_8" /></NavLink>
                                                    <NavLink to={'#'}><img src={FacebookIcon} alt="facebook icon" /></NavLink>
                                                </div> */}
                                            </div>
                                        </Col>
                                        : ''
                                }
                            </Row>
                        </Fragment>
                }
            </Fragment>
        );
    }
}

export default Dashboard;