import React, { PureComponent, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Pagination from "../../Common/Pagination";
import { AJAX_REQUEST, CURRENCY_FORMAT } from "../../../Constants/AppConstants";
import Parser from 'html-react-parser';

class CommissionEarned extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            commissionEarnedList: [],
            loading: true,
            message: '',
            // Pagination Config
            total_records: 0,
            total_page: 0,
            per_page: 0,
            pagenum: 1,
        }
        document.title = "Commission Earned - Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        this.getAllCommissionEarnedList(this.state.pagenum);
    }

    pagenationHandle = (pageNumber) => {
        this.setState({ loading: true });
        this.getAllCommissionEarnedList(pageNumber);
    }

    getAllCommissionEarnedList = (pageNumber) => {
        let data = { pagenum: parseInt(pageNumber) }
        AJAX_REQUEST("POST", "master_affiliate/getCommissionEarnedList", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    commissionEarnedList: results.response.data.commission_earned,
                    message: results.response.message,
                    total_earned: results.response.data.total_earned,
                    payment_received: results.response.data.payment_received,
                    adjustment: results.response.data.adjustment,
                    balance: results.response.data.balance,
                    loading: false,
                    // Pagination Config
                    total_records: parseInt(results.response.data.total_records),
                    total_page: parseInt(results.response.data.total_page),
                    per_page: parseInt(results.response.data.per_page),
                    pagenum: parseInt(results.response.data.pagenum),
                });
            }
            else {
                this.setState({
                    message: results.response.message,
                    loading: false,
                    // Pagination Config
                    total_records: 0,
                    total_page: 0,
                    per_page: 0,
                    pagenum: 1,
                })
            }
        });
    }

    render() {
        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className="loading"></div>
                        :
                        <Fragment>
                            <div className="woocommerce-MyAccount-content inner_content">
                                <h2 className="font_26 m-0 text_black let_spa_2 fw_el_bold"> Commission Earned <span>(Since Last Payout)</span></h2>
                                <h3 className="font_16 mb_15 text_black fw_ar_reg">Master Affiliate Dashboard &gt; Payment &gt; Commission Earned</h3>
                                <div className="row dashboard_row">
                                    <div className="col-md-3">
                                        <div className="sub_billing_blk w-10 white_bg">
                                            <p class="font_16 mb_4 text_black fw_ar_reg">
                                                Total Earned
                                            </p>
                                            <p class="font_24 m-0 text_black fw_ar_bold">
                                                {CURRENCY_FORMAT(this.state.total_earned)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="sub_billing_blk w-10 white_bg">
                                            <p class="font_16 mb_4 text_black fw_ar_reg">
                                                Payment Received
                                            </p>
                                            <p class="font_24 m-0 text_black fw_ar_bold">
                                                {CURRENCY_FORMAT(this.state.payment_received)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="sub_billing_blk w-10 white_bg">
                                            <p class="font_16 mb_4 text_black fw_ar_reg">
                                                Adjustment
                                            </p>
                                            <p class="font_24 m-0 text_black fw_ar_bold">
                                                {CURRENCY_FORMAT(this.state.adjustment)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="sub_billing_blk w-10 white_bg">
                                            <p class="font_16 mb_4 text_black fw_ar_reg">
                                                Balance
                                            </p>
                                            <p class="font_24 m-0 text_black fw_ar_bold">
                                                {CURRENCY_FORMAT(this.state.balance)}
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="table-responsive">
                                    <table className="w-100 subscribtion_table">
                                        <thead>
                                            <tr>
                                                <th>Payout Period</th>
                                                <th>Affiliate Count</th>
                                                <th>Affiliate Earnings</th>
                                                <th>My Earned Commissions</th>
                                                <th>Commission Adjustment</th>
                                                <th>Commission Total</th>
                                                <th>Commission Received</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                this.state.commissionEarnedList <= 0 ? <tr><td className="text-center" colSpan="7">{Parser(this.state.message)}</td></tr> :
                                                    this.state.commissionEarnedList.map(function (commission, key) {
                                                        return (
                                                            <tr className="order" key={key}>
                                                                <td className="order-number text-left" data-title="Payout Period">
                                                                    <NavLink className="text_decoration_border mob_left_right_none text_underline" to={`/my-affiliate-account/commission-earned-details/${commission.payout_id}`}>{commission.payout_period}</NavLink>
                                                                </td>
                                                                <td data-title="Affiliate Count">
                                                                    {commission.hasOwnProperty('distributor_count') ? commission.distributor_count : ''}
                                                                </td>
                                                                <td data-title="Affiliate Earnings">
                                                                    {commission.hasOwnProperty('distributor_earnings') ? CURRENCY_FORMAT(commission.distributor_earnings) : ''}
                                                                </td>
                                                                <td data-title="Earning Commission">
                                                                    {commission.hasOwnProperty('earning_commission') ? CURRENCY_FORMAT(commission.earning_commission) : ''}
                                                                </td>
                                                                {
                                                                    Number(commission.commission_adjustment) > 0 ?
                                                                        <td className="order-actions  text-right" data-title="Commission Adjustment"><NavLink to={`/my-affiliate-account/commission-earned/adjustment-details/${commission.payout_id}`}>{commission.hasOwnProperty('commission_adjustment') ? CURRENCY_FORMAT(commission.commission_adjustment) : ''}</NavLink></td>
                                                                        :
                                                                        <td data-title="Commission Adjustment">
                                                                            {commission.hasOwnProperty('commission_adjustment') ? CURRENCY_FORMAT(commission.commission_adjustment) : ''}
                                                                        </td>
                                                                }
                                                                <td data-title="commission total">
                                                                    {commission.hasOwnProperty('commission_total') ? CURRENCY_FORMAT(commission.commission_total) : ''}
                                                                </td>
                                                                <td data-title="commission received">
                                                                    {commission.hasOwnProperty('commission_received') ? CURRENCY_FORMAT(commission.commission_received) : ''}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination
                                    pagenationHandle={this.pagenationHandle}
                                    total_records={this.state.total_records}
                                    total_page={this.state.total_page}
                                    per_page={this.state.per_page}
                                    pagenum={this.state.pagenum}
                                />
                            </div>
                        </Fragment>
                }
            </Fragment>
        );
    }
}


export default CommissionEarned;