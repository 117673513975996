import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { AJAX_REQUEST, CURRENCY_FORMAT } from "../../../Constants/AppConstants";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrderProductDetails from './OrderProductDetails';
import Accordion from 'react-bootstrap/Accordion';
import productImage from '../../../Assets/images/customer_images/order_product_img.svg'
import DropdownIcon from '../../../Assets/images/customer_images/order_dropdown_icon.svg'
import AvatarIcon from '../../../Assets/images/affiliate_imges/avatar_icon.svg'
import user_icon from '../../../Assets/images/user_icon.svg'
import location_icon from '../../../Assets/images/location_icon.svg'
import member_downarrow from '../../../Assets/images/member_downarrow.svg'

import TeamMemberLists from './TeamMemberLists';

class OrderLists extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error_meg_dtm: '',
            active_team_member: {
                full_name: "-------",
                id: ""
            },
            changed_team_member: '',
            team_member_id: '',
            member_edit_meg: '',
            edit_form_show: false,
            show_edit_form_button: true,
            update_loading: false
        }
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        if (this.props.order.hasOwnProperty('active_team_member')) {
            this.setState({
                active_team_member: this.props.order.active_team_member
            });
        }
    }

    openEditOption = (e) => {
        this.setState({
            edit_form_show: true,
            show_edit_form_button: false
        });
    }

    closeEditOption = (e) => {
        this.setState({
            edit_form_show: false,
            show_edit_form_button: true
        });
    }

    selectedMember = (event) => {
        const memberId = event.target.value;
        this.setState({
            changed_team_member: memberId
        });
    }

    update = (e) => {
        const id = e.currentTarget.dataset.row_id;
        this.setState({
            update_loading: true
        });
        // this.props.updateMember(id);
        const data = { order_id: this.props.order.order_id, team_member_id: this.state.changed_team_member }

        AJAX_REQUEST("POST", "order/assignTeamMember", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    active_team_member: results.response.data,
                    member_edit_meg: results.response.message,
                    changed_team_member: '',
                    edit_form_show: false,
                    show_edit_form_button: true,
                    update_loading: false
                });
            }
            else {
                alert(results.response.message);
                this.setState({
                    edit_form_show: false,
                    show_edit_form_button: true,
                    update_loading: false
                });
            }
        });
    }

    render() {
        const { user } = this.props.auth;
        return (
            <Fragment>

                <div className="order_accordatio">
                    <div className="order_acc_header d-flex align-items-center justify-content-between">
                        <div className="left_acc d-flex w-50 align-items-center">
                            <h2 className="m-0 font_26 text_black let_spa_2 fw_ar_bold">{'#'}{this.props.order.hasOwnProperty('order_id') ? this.props.order.order_id : ''}</h2>
                            <span className='d-flex status_active order_status ml_20 text_white font_14 fw_ar_reg'>{this.props.order.hasOwnProperty('status') ? this.props.order.status : ''}</span>
                        </div>
                        <div className="rgt_acc d-flex align-items-center justify-content-end">
                            <NavLink className='font_16 text_black fw_ar_reg text_underline ml_18' to={`/reorder/${this.props.order.order_id}`}>Reorder</NavLink>
                            <NavLink className='font_16 text_black fw_ar_reg text_underline ml_18' to={`/my-account/view-order/${this.props.order.hasOwnProperty('order_id') ? this.props.order.order_id : ''} `}>View Order Details</NavLink>
                        </div>
                    </div>
                    <Accordion.Body>
                        <div className="order_acc_body">
                            <div className="order_acc_body p-0 mb_15">
                                {
                                    this.props.order.order_details.map(function (product, key) {
                                        return (
                                            <OrderProductDetails key={`op${key}`} product={product} />
                                        )
                                    }.bind(this))
                                }
                            </div>
                            
                        </div>
                    </Accordion.Body>

                    <div className="order_acc_footer d-flex align-items-center justify-content-between">
                        <div className='w-50'>
                        <div className='d-flex align-items-center mb_10 '>
                            <img src={user_icon} alt="dropdown_icon" class="mr_3"/>
                            <h3 className="font_16 text_black mb_0 mr_10 fw_ar_reg">{this.props.order.fullname}</h3>
                            <h3 className="font_16 mb_0 mr_10 items_text fw_ar_reg let_spa_2">{this.props.order.items ? this.props.order.items : ''}</h3>
                            <h3 className="font_16 text_black mb_0 fw_ar_reg">{ this.props.order.hasOwnProperty('date') ? this.props.order.date : ''}</h3>
                        </div>
                        <div className='d-flex align-items-center'>
                            <img src={location_icon} alt="dropdown_icon" class="mr_3"/>
                            <h3 className="font_16 text_black mb_0 fw_ar_reg">{this.props.order.shipping_address_1}, {this.props.order.shipping_address_2 ? this.props.order.shipping_address_2 : ''}, {this.props.order.shipping_city}, {this.props.order.shipping_state}, {this.props.order.shipping_postcode}</h3>
                            
                        </div>
                        </div>
                        <div class="w-50 d-flex align-items-center justify-content-between">
                        {
                                user.sales_agent_feature === "enable" ?
                                    <Fragment>
                                        <div className="order-representative" data-title="Team Member">
                                            <div className="w-100 form_input_blk">
                                                <div className="d-flex align-items-start" id={'remove_parent' + this.props.order.order_id}>
                                                    <div className="d-flex align-items-center">
                                                        {/* <img src={AvatarIcon} alt="avatar_icon" className="w-35" /> */}
                                                        <dic className="">
                                                            <span class="d-block font_12 items_text fw_ar_reg mb_3">Team Member</span>
                                                            <span className="d-block font_14 text_black fw_ar_reg ">
                                                                {this.state.active_team_member.full_name}
                                                            </span>
                                                        </dic>
                                                    </div>
                                                    <span title="Edit" style={{ cursor: 'pointer' }} onClick={this.openEditOption} id={this.props.order.order_id} data-row_id={this.props.order.order_id} className="order-rsp-change member_edit ml_10 mt_5 d-flex"><img src={ member_downarrow } alt="dropdown_icon"></img></span>
                                                </div>
                                            </div>

                                            {
                                                this.state.edit_form_show ?
                                                    <div class="modal d-block" tabindex="-1" role="dialog">
                                                        <div class="modal-dialog" role="document">
                                                            <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h5 class="font_16 text_black fw_ar_bold m-0" id="exampleModalLabel">Update Team Member</h5>
                                                                    <button type="button" class="close text_black fw_ar_bold" onClick={this.closeEditOption} id={this.props.order.order_id} data-row_id={this.props.order.order_id}>
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div className=" form_input_blk ">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <select onChange={this.selectedMember} data-the_order_id={this.props.order.order_id} className="form-control" name="team_member_id">
                                                                                    <option value="">Select One</option>
                                                                                    {
                                                                                        this.props.dist_team_members.length <= 0 ? null :
                                                                                            this.props.dist_team_members.map(function (disTeamMember, key) {
                                                                                                return (
                                                                                                    <TeamMemberLists
                                                                                                        key={key}
                                                                                                        disTeamMember={disTeamMember}
                                                                                                    />
                                                                                                )
                                                                                            })
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            <div className="col-md-12 mt_23 d-flex align-items-center">
                                                                                <span onClick={this.update} data-row_id={this.props.order.order_id} className="w-fit-content cursor-pointer add_new_btn d-flex font_16 text_white fw_ar_reg">{this.state.update_loading ? "Updateing..." : "Update"}</span>
                                                                                {/* <span className="cursor-pointer buy_now fw_ar_reg font_16 text_black btn_cancel" onClick={this.closeEditOption} id={this.props.order.order_id} data-row_id={this.props.order.order_id}>Cancel</span> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    : null
                                            }

                                        </div>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <div></div>
                                    </Fragment>
                            }
                        <div className="d-flex align-items-center justify-content-end">
                            <h3 className="font_16 m-0 items_text fw_ar_reg">TOTAL</h3>
                            <h2 className="ml_10 font_16 text_black fw_ar_bold mb_0">{this.props.order.hasOwnProperty('total') ? CURRENCY_FORMAT(this.props.order.total) : ''}</h2>
                            <Accordion.Header className='ml_23 mb_0'>
                                <span className='order_accor_click d-flex cursor-pointer'><img src={DropdownIcon} alt="dropdown_icon" /></span>
                            </Accordion.Header>
                        </div>
                        </div>
                    </div>
                </div>
                {/* <tr className="order">
                    <td className="order-number" data-title="Order">
                        <NavLink to={`/my-account/view-order/${this.props.order.hasOwnProperty('order_id') ? this.props.order.order_id : ''} `}> {'#'}{this.props.order.hasOwnProperty('order_id') ? this.props.order.order_id : ''}
                        </NavLink>
                    </td>
                    <td className="order-date" data-title="Date">
                        <time dateTime="2018-12-14T02:41:13+00:00">
                            
                        </time>
                    </td>
                    <td className="order-status toTitleCase" data-title="Status">
                        
                    </td>
                    <td className="order-status toTitleCase" data-title="Order Type">
                        {(this.props.order.order_type == 'supplement') ? 'Product' : 'Meal'}
                    </td>
                    {
                        user.sales_agent_feature === "enable" ?
                            <Fragment>
                                <td className="order-representative" data-title="Team Member">
                                    {
                                        this.state.show_edit_form_button ?
                                            <div className="team_member_action" id={'remove_parent' + this.props.order.order_id}>
                                                <span className="rsp_name">
                                                    {this.state.active_team_member.full_name}
                                                </span>
                                                <span title="Edit" style={{ cursor: 'pointer' }} onClick={this.openEditOption} id={this.props.order.order_id} data-row_id={this.props.order.order_id} className="order-rsp-change member_edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                            </div>
                                            : null
                                    }

                                    {
                                        this.state.edit_form_show ?
                                            <div className="team_member_edit">
                                                <select onChange={this.selectedMember} data-the_order_id={this.props.order.order_id} className="cus_field" name="team_member_id">
                                                    <option value="">Select One</option>
                                                    {
                                                        this.props.dist_team_members.length <= 0 ? null :
                                                            this.props.dist_team_members.map(function (disTeamMember, key) {
                                                                return (
                                                                    <TeamMemberLists
                                                                        key={key}
                                                                        disTeamMember={disTeamMember}
                                                                    />
                                                                )
                                                            })
                                                    }
                                                </select>
                                                <div className="">
                                                    <span onClick={this.update} data-row_id={this.props.order.order_id} className="roboto_condensed wc-forward team_update_btn">{this.state.update_loading ? "UPDATEING..." : "UPDATE"}</span>
                                                    <span style={{ cursor: 'pointer' }} onClick={this.closeEditOption} id={this.props.order.order_id} data-row_id={this.props.order.order_id} className="roboto_condensed member_edit_cancel">Cancel</span>
                                                </div>
                                            </div>
                                            : null
                                    }

                                </td>
                            </Fragment>
                            :
                            <Fragment>
                                <td>-------</td>
                            </Fragment>
                    }
                    <td className="affiliate_order toTitleCase text-center" data-title="Is Referer">
                        {this.props.order.is_affiliate_sale}
                    </td>
                    <td className="order-total" data-title="Total">
                        <span>
                             &nbsp;
                        </span>
                        
                    </td>

                    <td className="order-actions" data-title="&nbsp;">
                        <NavLink className="roboto_condensed wc-forward" to={`/my-account/view-order/${this.props.order.hasOwnProperty('order_id') ? this.props.order.order_id : ''} `}>View</NavLink>
                    </td>
                </tr> */}
            </Fragment>
        );
    }
}


OrderLists.propTypes = {
    auth: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps)(OrderLists);