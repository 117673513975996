import React, { Component, Fragment } from 'react';
import { AJAX_REQUEST } from "../../../Constants/AppConstants";
import CanceledSubscriptionsList from './CanceledSubscriptionsList';
import Pagination from '../../Common/Pagination';

class CanceledSubscriptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptions: [],
            error_meg: '',
            total_records: 0,
            total_page: 0,
            per_page: 0,
            pagenum: 1,
            loading: true,
        }
        document.title = "Canceled Subscription -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        AJAX_REQUEST("POST", "subscription/getCanceledItems", { pagenum: 1 }).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    subscriptions: results.response.data.subscriptions,
                    total_records: parseInt(results.response.data.total_records),
                    total_page: parseInt(results.response.data.total_page),
                    per_page: parseInt(results.response.data.per_page),
                    pagenum: parseInt(results.response.data.pagenum),
                    error_meg: results.response.message,
                    loading: false,
                })
            } else {
                this.setState({
                    error_meg: results.response.message,
                    loading: false,
                });
            }
        });
    }

    pagenationHandle = (pageNumber) => {
        this.setState({
            loading: true
        });
        document.querySelector("body").scrollIntoView();
        const pagenum = parseInt(pageNumber);
        AJAX_REQUEST("POST", "subscription/getCanceledItems", {
            pagenum: pagenum
        }).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    pagenum: parseInt(results.response.data.pagenum),
                    subscriptions: results.response.data.subscriptions,
                    total_records: parseInt(results.response.data.total_records),
                    total_page: parseInt(results.response.data.total_page),
                    per_page: parseInt(results.response.data.per_page),
                    error_meg: results.response.message,
                });
            } else {
                this.setState({
                    loading: false,
                    error_meg: results.response.message,
                    total_records: 0,
                    total_page: 0,
                    per_page: 0,
                    pagenum: 1,
                    subscriptions: []
                });
            }
        });
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <div className="woocommerce-MyAccount-content inner_content">
                            <h2 className="font_26 m-0 text_black let_spa_2 fw_el_bold">CANCELED SUBSCRIPTION</h2>
                            <h3 className="font_16 mb_15 text_black fw_ar_reg">Affiliate Dashboard &gt; Orders &gt; Cancelled Subscription</h3>
                            <div className="table-responsive">
                                <table className="w-100 subscribtion_table">
                                    <thead>
                                        <tr>
                                            <th>Subscription</th>
                                            <th>Subscription Start Date</th>
                                            <th>Subscription Cancel Date</th>
                                            <th>Team Member</th>
                                            <th>Canceled By</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {
                                            this.state.subscriptions.length <= 0 ? <tr><td className="text-center" colSpan="5">{this.state.error_meg}</td></tr> :
                                                this.state.subscriptions.map(function (subscription, key) {
                                                    return (
                                                        <CanceledSubscriptionsList
                                                            key={key}
                                                            subscription={subscription}
                                                        />
                                                    )
                                                })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                pagenationHandle={this.pagenationHandle}
                                total_records={this.state.total_records}
                                total_page={this.state.total_page}
                                per_page={this.state.per_page}
                                pagenum={this.state.pagenum}
                            />

                        </div>
                }
            </Fragment>
        );
    }
}

export default CanceledSubscriptions;