import React, { Component, Fragment } from 'react';
import ReactImageFallback from "react-image-fallback";
import { NavLink } from 'react-router-dom';
import { AJAX_REQUEST, CURRENCY_FORMAT, GET_STORAGE } from "../../../Constants/AppConstants";
import AlertWrapperSuccess from '../../Common/AlertWrapperSuccess';
import { Row, Col } from 'react-bootstrap';
import productImage from '../../../Assets/images/customer_images/order_product_img.svg'

class SubscriptionCancellationDetails extends Component {
    constructor(props) {
        super(props)
        // Check Tax
        let settings = '';
        if (GET_STORAGE('settings')) {
            settings = JSON.parse(GET_STORAGE('settings'));
        }
        this.state = {
            loading: true,
            taxStatus: settings ? settings.tax_status : 0,
            subscription_id: parseInt(this.props.match.params.subscription_id),
            items: [],
            subtotal: 0,
            shipping_charge: 0,
            tax_amount: 0,
            shipping_method: "",
            payment_method: "",
            subscription_type: "",
            duration: "",
            total: 0,
            error_meg: '',
            success_alert_wrapper_show: false
        }
        document.title = "Subscription Item Details -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView() // Scroll to Top
        // document.getElementById("pageTitle").innerHTML = "SUBSCRIPTION #" + this.state.subscription_id;
        this.getSubscriptionDetails();
    }

    getSubscriptionDetails = () => {
        let data = {
            subscription_id: this.state.subscription_id
        }
        AJAX_REQUEST("POST", "subscription/getDetails", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                let subs = results.response.data.subscription
                this.setState({
                    items: subs.items,
                    subtotal: subs.subtotal,
                    shipping_charge: subs.shipping_charge,
                    tax_amount: subs.tax_amount,
                    shipping_method: subs.shipping_method,
                    payment_method: subs.payment_method,
                    subscription_type: subs.subscription_type,
                    duration: subs.duration,
                    total: subs.total,
                    loading: false,
                });
            } else {
                this.setState({
                    error_meg: results.response.message,
                    loading: false,
                });
            }
        });
    }

    removeSubscriptionItem = (itemId) => {
        if (window.confirm('Are you sure you want remove this item from your subscription?')) {
            document.querySelector("body").scrollIntoView() // Scroll to Top
            this.setState({
                loading: true,
                error_meg: '',
                success_alert_wrapper_show: false
            });

            let data = {
                subscription_id: this.state.subscription_id,
                subscription_item_id: itemId
            }
            AJAX_REQUEST("POST", "subscription/removedItem", data).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    this.setState({
                        error_meg: results.response.message,
                        loading: false,
                        success_alert_wrapper_show: true
                    });
                    this.getSubscriptionDetails();
                    setTimeout(function () {
                        this.setState({
                            success_alert_wrapper_show: false
                        });
                    }.bind(this), 3000);
                } else {
                    this.setState({
                        error_meg: results.response.message,
                        loading: false,
                        success_alert_wrapper_show: false
                    });
                }
            });
        }
    }

    render() {

        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <div className="MyAccount-content">
                            <AlertWrapperSuccess errors_data={this.state.error_meg} success_alert_wrapper_show={this.state.success_alert_wrapper_show} />
                            <section className="woocommerce-order-details">
                                <div className="mb_15 d-flex align-items-center justify-content-between">
                                    <h2 className="font_26 m-0 text_black let_spa_2 fw_el_bold">SUBSCRIPTION ITEM DETAILS</h2>
                                    {
                                        (this.state.subscription_type == 'supplement') ?
                                            <NavLink to={`/subscription-item/${this.state.subscription_id}`} className="text_black font_16 fw_ar_reg text_underline">Add New Item</NavLink>
                                            :
                                            <NavLink to={`/meals?subscription_id=${this.state.subscription_id}`} className="text_black font_16 fw_ar_reg text_underline">Change Meal Item</NavLink>
                                    }
                                </div>
                                <div className="order_det_blk white_bg">
                                    <Row>
                                        <Col md={12}>
                                            <h4 className="font_16 text_black fw_ar_bold mb_8 let_spa_2 pay_top">Order Summary</h4>
                                            <div className="order_acc_body p-0 mb_23">
                                                {
                                                    this.state.items.length <= 0 ? <tr><td className="text-center" colSpan="3">No Item Found!</td></tr> :
                                                        this.state.items.map(function (item, key) {
                                                            if ((this.state.status === "Cancelled") || (this.state.status === "Failed")) {
                                                                return (
                                                                    <div className="order_pro_detail d-flex align-items-center justify-content-between">
                                                                        <div className="left_pro_de d-flex align-items-center w-50">
                                                                        <img src={productImage} alt="productImage" className="product_image" />
                                                                            <div className="pl_16">
                                                                                <h5 className="font_14 mb_6 text_black fw_el_bold let_spa_2">{item.hasOwnProperty('name') ? item.name : ''}</h5>
                                                                                <span className="font_12 items_text d-block fw_ar_reg">Monthly</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="right_pro d-flex flex-column align-items-end">
                                                                            <h5 className="font_14 text_black let_spa_2 fw_ar_bold m-0">$68.62</h5>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div className="order_pro_detail d-flex align-items-center justify-content-between">
                                                                        <div className="left_pro_de d-flex align-items-center w-50">
                                                                        <img src={productImage} alt="productImage" className="product_image" />
                                                                            <div className="pl_16">
                                                                                <h5 className="font_14 mb_6 text_black fw_el_bold let_spa_2">{item.hasOwnProperty('name') ? item.name : ''}</h5>
                                                                                <span className="font_12 items_text d-block fw_ar_reg">Monthly</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="right_pro d-flex flex-column align-items-end">
                                                                            <h5 className="font_14 text_black let_spa_2 fw_ar_bold m-0">$68.62</h5>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                        }.bind(this))
                                                }

                                            </div>
                                            <div className="order_det_total">
                                                <ul className="pro_cart_det">
                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Sub Total</span>
                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.subtotal)}</span>
                                                    </li>
                                                    {
                                                        (this.state.discounts && this.state.discounts.length > 0) ?
                                                            this.state.discounts.map(function (discount, key) {
                                                                return (
                                                                    <Fragment key={key}>
                                                                        <li className="d-flex align-items-center flex-wrap mb_7">
                                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">{discount.label}</span>
                                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(discount.amount)}</span>
                                                                        </li>
                                                                    </Fragment>
                                                                )
                                                            }.bind(this))
                                                            : null
                                                    }
                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Shipping</span>
                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.shipping_cost)}</span>
                                                    </li>
                                                    {
                                                        (this.state.taxStatus == 1) ?
                                                            <Fragment>
                                                                <li className="d-flex align-items-center flex-wrap mb_7">
                                                                    <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Tax</span>
                                                                    <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.tax_amount)}</span>
                                                                </li>
                                                            </Fragment>
                                                            : null
                                                    }
                                                    <li className="d-flex align-items-center flex-wrap">
                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Total</span>
                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.total)}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <span className="d-block font_12 text_black fw_ar_reg font_italic">Note : Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span>
                                        </Col>
                                    </Row>
                                </div>
                            </section>
                        </div>
                }
            </Fragment>
        );
    }
}

export default SubscriptionCancellationDetails;