
import React, { Component, Fragment } from 'react';
import $ from 'jquery';
import history from '../../../history';
import ActiveSubscriptionList from './ActiveSubscriptionList';
import Pagination from '../../Common/Pagination';
import { AJAX_REQUEST, CURRENCY_FORMAT } from "../../../Constants/AppConstants";
import Parser from 'html-react-parser';

class ActiveSubscription extends Component {
    constructor(props) {
        super(props)
        this.state = {
            subscriptions: [],
            error_meg: '',
            message: '',
            total_records: 0,
            total_page: 0,
            per_page: 0,
            pagenum: 1,
            loading: true,
            filter_loading: false
        }
        document.title = "Active Subscriptions -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        AJAX_REQUEST("POST", "subscription/getActiveItems", { pagenum: this.state.pagenum }).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    subscriptions: results.response.data.subscriptions,
                    total_records: results.response.data.total_records,
                    total_page: results.response.data.total_page,
                    per_page: results.response.data.per_page,
                    pagenum: results.response.data.pagenum,
                    message: results.response.message
                });
            }
            else {
                this.setState({
                    error_meg: results.response.message,
                    loading: false,
                })
            }
        });
    }

    pagenationHandle = (pageNumber) => {
        this.setState({
            loading: true
        });
        document.querySelector("body").scrollIntoView();
        const pagenum = parseInt(pageNumber);
        AJAX_REQUEST("POST", "subscription/getActiveItems", { pagenum: pagenum }).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    subscriptions: results.response.data.subscriptions,
                    total_records: results.response.data.total_records,
                    total_page: results.response.data.total_page,
                    per_page: results.response.data.per_page,
                    pagenum: results.response.data.pagenum,
                    error_meg: results.response.message
                });
            }
            else {
                this.setState({
                    error_meg: results.response.message,
                    loading: false,
                })
            }
        });
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <div className="woocommerce-MyAccount-content inner_content">
                            <div className="mb_15">
                                <h2 className="font_26 m-0 text_black let_spa_2 fw_el_bold">Active Subscription</h2>
                                <h3 className="font_16 m-0 text_black fw_ar_reg">Affiliate Dashboard &gt; Orders &gt; Active Subscription</h3>
                            </div>
                            <div className="table-responsive">
                                <table className="w-100 subscribtion_table">
                                    <thead>
                                        <tr>
                                            <th>Subscription ID</th>
                                            <th>Subscription Total</th>
                                            <th>Start Date</th>
                                            <th>Next Payment</th>
                                            <th>Customer</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            this.state.subscriptions.length <= 0 ? <tr><td className="text-center" colSpan="6">{Parser(this.state.error_meg)}</td></tr> :
                                                this.state.subscriptions.map(function (subscription, key) {
                                                    return (
                                                        <ActiveSubscriptionList
                                                            key={key}
                                                            subscription={subscription}
                                                        />
                                                    )
                                                })
                                        }
                                    </tbody>
                                </table>
                            </div>


                            <Pagination
                                pagenationHandle={this.pagenationHandle}
                                total_records={this.state.total_records}
                                total_page={this.state.total_page}
                                per_page={this.state.per_page}
                                pagenum={this.state.pagenum}
                            />

                        </div>
                }
            </Fragment>
        );
    }
}

export default ActiveSubscription;