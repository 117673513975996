import React, { PureComponent, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import RegistrationFormNew from './RegistrationFormNew';
import history from '../../history';
import { logout } from '../../Store/actions/loginActions';
import { alertMessageRemoval } from '../../Store/actions/signupActions';
import Parser from 'html-react-parser';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import { ECOM_URL, AJAX_REQUEST, AJAX_PUBLIC_REQUEST, DELETE_COOKIE, PUBLIC_URL, SITE, GET_COOKIE, ENABLE_NEW_LOGIN } from '../../Constants/AppConstants';


class LoginNew extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            title: '',
            disclaimer_text: '',
            content: '',
            button_text: '',
            video_embed: '',
            agreement_text: '',
            registration_link: PUBLIC_URL
        }
        if (this.props.isAuthenticated) {
            AJAX_REQUEST("POST", "user/details", {}).then(results => {
                const response = results.response;
                if (parseInt(response.code) === 1000) {
                    history.push('/');
                } else {
                    this.props.logout();
                }
            });
        }
        document.title = "Login - Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();

        if (GET_COOKIE("af") != "") {
            this.setState({
                registration_link: PUBLIC_URL + "affiliate-signup-request?af=" + GET_COOKIE("af") + '&site=' + SITE
            })
        } else {
            this.setState({
                registration_link: PUBLIC_URL + 'affiliate-signup-request?site=' + SITE
            })
        }

        AJAX_PUBLIC_REQUEST("POST", "distributor/getSignupSettings", {}).then(results => {
            const response = results.response;
            if (parseInt(response.code) === 1000) {
                this.setState({
                    title: response.data.title,
                    disclaimer_text: response.data.disclaimer_text,
                    content: response.data.content,
                    button_text: response.data.button_text,
                    video_embed: response.data.video_embed,
                    agreement_text: response.data.agreement_text,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        });
    }

    clickRegistration = (e) => {
        e.preventDefault();
        DELETE_COOKIE("af");
        window.location.href = this.state.registration_link;
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading container full_page_loader"></div>
                        :

                        <div className="login_container contact_container d-flex  justify-content-center">
                            {
                                ENABLE_NEW_LOGIN ?
                                    <Fragment>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <div className="user_login_join_video">
                                                        {
                                                            (this.state.video_embed != null) && (this.state.video_embed != '') ?
                                                                <Fragment>
                                                                    {Parser(this.state.video_embed)}
                                                                </Fragment>
                                                                : ''
                                                        }

                                                        {/* <div className="wistia_embed wistia_async_rpyx2ikvy4 popover=true popoverAnimateThumbnail=true" style={{display:'inline-block', height:'200px', position:'relative', width:'100%',}}>&nbsp;</div> */}
                                                        {/* <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, </p> */}
                                                        {/* <button href={this.state.registration_link} className="cus_button cus_signup_button" onClick={this.clickRegistration} onClick={this.clickRegistration}> {this.state.button_text}</button> */}
                                                        {/* <button type="submit" className="cus_button cus_signup_button" name="login" value="Login">Sign Up</button> */}
                                                        {
                                                            (this.state.content != null) && (this.state.content != '') ?
                                                                <p className="user_login_join_content">{Parser(this.state.content)}</p>
                                                                : ''
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">



                                                <div className="col-md-3 empty"></div>
                                                <div className="col-md-6">
                                                    <main className="user_login  aff_login_blk">
                                                        <Tabs
                                                            defaultActiveKey="login"
                                                            id="uncontrolled-tab-example"
                                                            className="login_tabs"
                                                        >
                                                            <Tab eventKey="login" title="Login">
                                                                <div className="user_login user_login-new">
                                                                    <LoginForm />
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="Register" title="Register">
                                                                <div className="user_login user_login_join">
                                                                    <RegistrationFormNew agreement_text={this.state.agreement_text} />
                                                                </div>
                                                            </Tab>
                                                        </Tabs>
                                                    </main>
                                                </div>
                                                <div className="col-md-3 empty"></div>
                                            </div>
                                        </div>
                                    </Fragment>
                                    :
                                    <div className="container">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <div className="user_login_join_video">
                                                        {
                                                            (this.state.video_embed != null) && (this.state.video_embed != '') ?
                                                                <Fragment>
                                                                    {Parser(this.state.video_embed)}
                                                                </Fragment>
                                                                : ''
                                                        }

                                                        {/* <div className="wistia_embed wistia_async_rpyx2ikvy4 popover=true popoverAnimateThumbnail=true" style={{display:'inline-block', height:'200px', position:'relative', width:'100%',}}>&nbsp;</div> */}
                                                        {/* <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, </p> */}
                                                        {/* <button href={this.state.registration_link} className="cus_button cus_signup_button" onClick={this.clickRegistration} onClick={this.clickRegistration}> {this.state.button_text}</button> */}
                                                        {/* <button type="submit" className="cus_button cus_signup_button" name="login" value="Login">Sign Up</button> */}
                                                        {
                                                            (this.state.content != null) && (this.state.content != '') ?
                                                                <p className="user_login_join_content">{Parser(this.state.content)}</p>
                                                                : ''
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">



                                                <div className="col-md-3 empty"></div>
                                                <div className="col-md-6">
                                                    <main className="user_login  aff_login_blk">
                                                        <Tabs
                                                            defaultActiveKey="login"
                                                            id="uncontrolled-tab-example"
                                                            className="login_tabs"
                                                        >
                                                            <Tab eventKey="login" title="Login">
                                                                <div className="user_login user_login-new">
                                                                    <LoginForm />
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="Register" title="Register">
                                                                <div className="user_login user_login_join">
                                                                    <RegistrationFormNew agreement_text={this.state.agreement_text} />
                                                                </div>
                                                            </Tab>
                                                        </Tabs>
                                                    </main>
                                                </div>
                                                <div className="col-md-3 empty"></div>
                                            </div>
                                        </div>
                            }



                        </div>
                }
            </Fragment>
        );
    }
}

LoginNew.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    alertMessageRemoval: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }
}

export default connect(mapStateToProps, { logout, alertMessageRemoval })(LoginNew);