import React, { Component, Fragment } from 'react';
import { AJAX_REQUEST } from "../../../Constants/AppConstants";
import classnames from 'classnames';
import validatePaypalEmail from '../../../Validations/paypalEmail';
import AlertWrapper from '../../Common/AlertWrapper';
import AlertWrapperSuccess from '../../Common/AlertWrapperSuccess';

class PaypalAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            active_email: "",
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            server_message: '',
            error_meg: '',
            success_alert_wrapper_show: false,
            loading: true,
            saving: false
        }
        document.title = "PayPal Email -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        AJAX_REQUEST("POST", "distributor/getPaypalInfo", {}).then(results => {
            const response = results.response;
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    logs: response.data.logs,
                    active_email: response.data.active_email,
                    loading: false,
                    error_meg: response.message,
                });
            } else {
                this.setState({
                    loading: false,
                    server_message: response.message,
                    error_meg: response.message,
                    isFormValid: false,
                });
            }
        });
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    timeOut = (timedata) => {
        setTimeout(function () {
            this.setState({
                success_alert_wrapper_show: false
            });
            // for global message dismiss
            let globalMessageBtn = document.querySelector('#globalMessageBtn');
            if (globalMessageBtn) {
                document.getElementById("globalMessageBtn").click();
            }
        }.bind(this), timedata);
    }

    onSubmit = (e) => {
        e.preventDefault();
        const val_return = validatePaypalEmail(this.state);
        this.setState(val_return);
        if (val_return.isValid) {
            this.setState({ errors: {}, isLoading: true, saving: true, server_message: '', });
            AJAX_REQUEST("POST", "distributor/updatePaypalEmail", { paypal_email: this.state.active_email }).then(results => {
                const response = results.response;
                if (parseInt(response.code) === 1000) {
                    this.setState({
                        server_message: response.message,
                        isFormValid: true,
                        isLoading: false,
                        success_alert_wrapper_show: true,
                        saving: false,
                        error_meg: response.message,
                        logs: response.data.logs,
                        active_email: response.data.active_email,
                    });
                    this.timeOut(5000);
                } else {
                    this.setState({
                        server_message: response.message,
                        isFormValid: false,
                        isLoading: false,
                        saving: false,
                        error_meg: response.message,
                    });
                }
            });
        }
    }

    render() {
        const { errors, server_message, success_alert_wrapper_show, saving, active_email, logs } = this.state;
        const errors_data = server_message;
        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <Fragment>
                            <div className="woocommerce-MyAccount-content inner_content account_details_form">
                                <h2 className="font_26 m-0 text_black let_spa_2 fw_el_bold">Paypal Email</h2>
                                <h3 className="font_16 mb_15 text_black fw_ar_reg">Affiliate Dashboard &gt; Orders &gt; Paypal Email</h3>
                                <AlertWrapper errors_data={errors_data} isFormValid={this.state.isFormValid} />
                                <AlertWrapperSuccess errors_data={errors_data} success_alert_wrapper_show={success_alert_wrapper_show} />
                                <div className="add_new_card">
                                    <form className="w-100 form_input_blk" action="" method="post" onSubmit={this.onSubmit}>
                                        <p className='mb_16 text_black font_16 fw_ar_reg'>
                                            Please fill out your PayPal account information to get your payout. Please note that payouts can not be processed if PayPal account is not provided.
                                        </p>
                                        <div className="form-group">
                                            <label htmlFor="active_email" className={classnames(null, { 'pl_error_label': errors.active_email })} >PayPal E-mail <span className="required">*</span></label>
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <input type="text" onChange={this.changeHandler} className={classnames("form-control", { 'pl_error_input': errors.active_email })} name="active_email" id="active_email" value={active_email} />
                                                </div>
                                                <div className="col-md-4">
                                                    <button type="submit" disabled={this.state.isLoading} className="w-100 cursor-pointer add_new_btn d-flex font_16 text_white fw_ar_reg" name="save_paypal_account" >{saving ? "Saving..." : "Save changes"}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <h2 className="font_20 mb_10 text_black let_spa_2 fw_el_bold">Paypal Email Update Logs</h2>
                                <div className="table-responsive">
                                    <table className="w-100 subscribtion_table">
                                        <thead>
                                            <tr>
                                                <th>PayPal Email</th>
                                                <th>Added</th>
                                                <th>Changed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                logs.length <= 0 ? <tr><td className="text-center" colSpan="3"> No Data Found!</td></tr> :
                                                    logs.map(function (order, key) {
                                                        return (
                                                            <tr className="order alternate" key={key}>
                                                                <td data-title="PayPal Email" className="order-number">{order.paypal_email}</td>
                                                                <td data-title="Added" className="order-number">{order.added}</td>
                                                                <td data-title="Changed" className="order-number">{order.changed}</td>
                                                            </tr>
                                                        )
                                                    })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Fragment>
                }
            </Fragment>
        );
    }
}

export default PaypalAccount;