import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { AJAX_REQUEST, CURRENCY_FORMAT } from '../../../../Constants/AppConstants';

class TeamMembersList extends Component {
    state = {}

    render() {
        return (<Fragment>
            <tr>
                <td data-title="Name">
                    <NavLink className="font_16 text_black fw_ar_bold" to={`/my-account/manage-representative/${this.props.member.hasOwnProperty('team_member_id') ? this.props.member.team_member_id : ''} `}>{this.props.member.hasOwnProperty('name') ? this.props.member.name : ''} </NavLink>
                </td>
                <td data-title="Email">
                    {this.props.member.hasOwnProperty('email') ? this.props.member.email : ''}
                </td>
                <td data-title="Total Sales">
                    {this.props.member.hasOwnProperty('total_sales') ? CURRENCY_FORMAT(this.props.member.total_sales) : ''}
                </td>
                <td data-title="Refer Link">
                    <div style={{ wordWrap: "break-word" }}>
                        <a href={this.props.member.hasOwnProperty('refer_link') ? this.props.member.refer_link : ''}>{this.props.member.hasOwnProperty('refer_link') ? this.props.member.refer_link : ''}</a>
                    </div>
                </td>
                <td data-title="Status">
                    {this.props.member.hasOwnProperty('status') ? this.props.member.status : ''}
                </td>
                <td data-title="Action">
                    <div className="action_th d-flex align-items-center">
                        <NavLink title="Edit" className="font_16 text_black text_underline d-block" to={`/my-account/manage-representative/action/edit/${this.props.member.hasOwnProperty('team_member_id') ? this.props.member.team_member_id : ''} `}>Edit</NavLink>
                        <span>|</span>
                        <NavLink className="font_16 text_black text_underline d-block" to={`/my-account/manage-representative/action/customer_handover/${this.props.member.hasOwnProperty('team_member_id') ? this.props.member.team_member_id : ''} `}>Reassign</NavLink>
                        {
                            (this.props.member.deletable_status == 1) ?
                                <Fragment>
                                    <NavLink title="Delete" className="font_16 text_black text_underline d-block" onClick={(e) => this.props.deleteTeamMember(e, this.props.member.team_member_id)} to="#">Delete</NavLink>
                                </Fragment>
                                : ''
                        }
                    </div>

                </td>
            </tr>
        </Fragment>);
    }
}

export default TeamMembersList;