import React, { PureComponent, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from "jquery";
import history from '../../history';
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST, AJAX_REQUEST, ENABLE_AFFILIATE_REQUEST, CUSTOMER_URL, GET_STORAGE, SET_STORAGE, BLOG_URL } from '../../Constants/AppConstants';
import { logout } from '../../Store/actions/loginActions';
// import PrestigeLogo from './../../Assets/images/prestige_logo.svg'
import PrestigeLogo from './../../Assets/images/new_prestige_logo.svg'
import SearchIcon from './../../Assets/images/affiliate_imges/header_search_icon.svg'
import MyAccount from './../../Assets/images/customer_images/my_account_icon.svg'
import ReachUsIcon from './../../Assets/images/affiliate_imges/reach_us_icon.svg'
import CheckoutLogo from '../../Assets/images/refer_images/checkout_page_logo.svg'
import MenuDropdown from './../../Assets/images/customer_images/menu_dropdown.svg'
// import MenuDropdownBlack from './../../Assets/images/customer_images/menu_dropdown_black.svg'
import BestSeller from './../../Assets/images/customer_images/best_sellers_img.svg'
import Basecollect from './../../Assets/images/customer_images/base_collection_img.svg'
import BoostCollect from './../../Assets/images/customer_images/boost_collection_img.svg'
import Bundles from './../../Assets/images/customer_images/bundles_icon.svg'
import BalanceCollect from './../../Assets/images/customer_images/balance_col_img.svg'
import Protein from './../../Assets/images/customer_images/protein_img.svg'
import Workout from './../../Assets/images/customer_images/pro_workout_img.svg'
import WeightLoss from './../../Assets/images/customer_images/weight_loss_img.svg'
import BundleMuscle from './../../Assets/images/customer_images/build_muscle_img.svg'
import EveryDayHealth from './../../Assets/images/customer_images/everyday_helth_img.svg'
import QuestionImg from './../../Assets/images/customer_images/question_img.svg'
import Chart3d from './../../Assets/images/customer_images/chart_3d_img.svg'
import ContactImg from './../../Assets/images/customer_images/contactus_img.svg'
import FaqImg from './../../Assets/images/customer_images/faq_img.svg'
import AffLab from './../../Assets/images/customer_images/aff_lab.svg'
import { Row, Col } from "react-bootstrap";

class Header extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showusermenu: false,
            page: '',
            training_url: 'https://prestigelabs.mykajabi.com/offers/Na7LT2mg',
            menus: [],
            tags: []
        }

        setInterval(function () {
            if (this.props.auth) {
                if (this.props.auth.user) {
                    if (this.props.auth.user.remember) {
                        AJAX_REQUEST("POST", "user/updateAccessToken", {}).then(results => {
                            if (parseInt(results.response.code) === 1000) {
                                // console.log(results.response.code);
                            }
                        });
                    }
                }
            }
        }.bind(this), 540000);
    }

    onCliclActiveMob = (e) => {
        const elements = document.querySelectorAll('.mob_site_content ul li');
        [].forEach.call(elements, function (el) {
            el.classList.remove("active");
        });
        e.currentTarget.classList.add('active');
    }

    onCliclActive = (e) => {
        const elements = document.querySelectorAll('.menu-main-menu-container ul li');
        [].forEach.call(elements, function (el) {
            el.classList.remove("active");
        });
        e.currentTarget.classList.add('active');
    }

    showMenu = () => {
        this.setState({
            showusermenu: this.state.showusermenu ? false : true
        });
    }

    showMobSideMenu = () => {
        $("body").toggleClass("current", 1000);
    }

    componentDidMount() {
        AJAX_REQUEST("POST", "menu/tag_index", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    tags: results.response.data.tag_data,
                    loading: false,
                });
                SET_STORAGE("tagData", JSON.stringify(results.response.data.tag_data));
            } else {
                this.setState({
                    error: results.response.message,
                    loading: false,
                })
            }
        });
        AJAX_PUBLIC_REQUEST("POST", "menu/getMenuInfo", { type: 'primary' }).then(results => {
            if (parseInt(results.response.code) === 1000) {
                let training_url = results.response.additional_data && results.response.additional_data.training_url ? results.response.additional_data.training_url : this.state.training_url;
                this.setState({
                    menus: results.response.data,
                    training_url: training_url
                });
            }
        });
        AJAX_PUBLIC_REQUEST("POST", "page/getContactInfo", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    page: results.response.data,
                });
            } else {
                // console.log(results.response.message);
            }
        });
    }

    logout = (e) => {
        e.preventDefault();
        AJAX_REQUEST("POST", "user/logout", {}).then(results => {
            /*if (parseInt(results.response.code) === 1000) { } else {
                // console.log(results.response.message);
            }*/
        });
        this.props.logout();
    }

    render() {
        const { user } = this.props.auth;
        const email = this.state.page.hasOwnProperty('email') ? Parser(this.state.page.email) : '';
        const phone = this.state.page.hasOwnProperty('phone') ? Parser(this.state.page.phone) : '';

        let settings = null;
        if (GET_STORAGE("settings")) {
            settings = JSON.parse(GET_STORAGE("settings"));
        }

        let meal_menu_active = false;
        let enable_new_signup = true;
        // if(settings && settings.enable_new_signup == "yes"){
        //     enable_new_signup = true;
        // }
        if (settings && settings.meal_menu_public == "yes") {
            meal_menu_active = true;
        } else {
            if (this.props) {
                if (this.props.auth) {
                    if (this.props.auth.user) {
                        if (this.props.auth.user.meal_menu_activated) {
                            meal_menu_active = true;
                        }
                    }
                }
            }
        }
        const tags = this.state.tags


        return (
            <React.Fragment>
                <header className="montserrat site-header header_blk">
                    <div className="mob_menu_wrapper d-sm-block d-md-none">
                        <div className="site_menu_wrapper_inner headere_mobile">
                            <div className="mob_site_menu" onClick={this.showMobSideMenu}>
                                <div className="main_menu_blk">
                                    <ul className="mob_site_content">
                                        <li className="menu_close d-flex justify-content-end">
                                            <span className="d-block font_20 text_black fw_ar_bold" onClick={this.showMobSideMenu}>&times;</span>
                                        </li>
                                        {
                                            ENABLE_AFFILIATE_REQUEST ?
                                                <Fragment>
                                                    {
                                                        (this.props.auth.isAuthenticated && Object.values(user.roles).includes('distributor') && !Object.values(user.roles).includes('master_affiliate')) ?
                                                            <li key={Math.random()} className="pull-right"><NavLink activeClassName="active" className="menu_item" to="/affiliate-request"><span>Refer Friends to Join Prestige Labs</span></NavLink></li>
                                                            : ''
                                                    }
                                                </Fragment>
                                                : ''
                                        }
                                        {
                                            (this.state.menus.length <= 0) ? null :
                                                this.state.menus.map(function (menu, key) {
                                                    if ((user.roles != undefined) && Object.values(user.roles).includes('master_affiliate') && !Object.values(user.roles).includes('distributor')) {
                                                        if ((menu.url === '') || (menu.url === '/') || (menu.url === '/meals')) {

                                                        } else {
                                                            if (menu.type === "external") {
                                                                if (menu.manual_position != 'last') {
                                                                    return (<li key={'ddm' + key}><a target={menu.open_new_tab == "yes" ? "_blank" : "_self"} className={`menu_item${history.location.pathname === menu.url ? ' active' : ''}`} href={menu.url}><span>{menu.label}</span></a></li>)
                                                                }
                                                            } else {
                                                                if (menu.url === "/my-account") {
                                                                    return (<li key={'ddm' + key}><NavLink activeClassName="active" className="menu_item" to="/my-affiliate-account" exact><span>{enable_new_signup ? 'Master Affiliate Dashboard' : 'Master Affiliate Panel'}</span></NavLink></li>)
                                                                } else {
                                                                    return (<li key={'ddm' + key}><NavLink activeClassName="active" className="menu_item" to={menu.url} exact><span>{menu.label}</span></NavLink></li>)
                                                                }
                                                            }
                                                        }
                                                    } else {
                                                        if (menu.type === "external") {
                                                            if (menu.manual_position != 'last') {
                                                                return (<li key={'ddm' + key}><a target={menu.open_new_tab == "yes" ? "_blank" : "_self"} className={`menu_item${history.location.pathname === menu.url ? ' active' : ''}`} href={menu.url}><span>{menu.label}</span></a></li>)
                                                            }
                                                        } else {
                                                            if (menu.url === "/my-account") {
                                                                if ((user.roles != undefined) && Object.values(user.roles).includes('distributor')) {
                                                                    return (<li key={'ddm' + key}><NavLink activeClassName="active" className="menu_item" to={menu.url} exact><span>{enable_new_signup ? 'Affiliate Dashboard' : 'Affiliate Panel'}</span></NavLink></li>)
                                                                } else {
                                                                    return (<li key={'ddm' + key}><NavLink activeClassName="active" className="menu_item" to={menu.url} exact><span>Team Member Panel</span></NavLink></li>)
                                                                }
                                                            } else {
                                                                if (menu.url === '/meals') {
                                                                    if (meal_menu_active) {
                                                                        return (<li key={'ddm' + key}><NavLink activeClassName="active" className="menu_item" to={menu.url} exact><span>{menu.label}</span></NavLink></li>)
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                } else {
                                                                    if (menu.label == 'Supplements') {
                                                                        return (
                                                                          <li className="dropdown position-static header_sub_menu">
                                                                            <a id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#">
                                                                              <span>{menu.label}</span>
                                                                              <img src={MenuDropdown} alt="dropDown" />
                                                                            </a>
                                                                            <div className="dropdown-menu shop_menu_blk" aria-labelledby="dropdownMenuButton">
                                                                              <div className="w-100">
                                                                                <ul className="d-flex p-0 m-0 flex-wrap shop_menu align-items-center justify-content-center">
                                                                                <div className="w-100">
                                                                                    <ul className="d-flex p-0 m-0 shop_menu flex-wrap align-items-center justify-content-center tags_centre">
                                                                                        {tags.map((tag, index) => (
                                                                                            tag.header_tag == 1 && (
                                                                                                <li key={index} className="d-flex align-items-center justify-content-center">
                                                                                                    <a href={tag.tag_name == 'Build Your Own Bundle' ? '/custom-bundle' : `/tagged-products/${tag.id}`} className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">
                                                                                                    {tag.tag_name}
                                                                                                    </a>
                                                                                                </li>
                                                                                            )
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>
                                                                                </ul>
                                                                              </div>
                                                                            </div>
                                                                          </li>
                                                                        );
                                                                      } else {
                                                                        return (
                                                                          <li key={'ddm' + key}>
                                                                            <NavLink activeClassName="active" className="menu_item" to={menu.url} exact>
                                                                              <span>{menu.label}</span>
                                                                            </NavLink>
                                                                          </li>
                                                                        );
                                                                      }
                                                                }
                                                            }
                                                        }
                                                    }
                                                })
                                        } 
                                        {
                                            (this.props.auth.isAuthenticated && (user.roles != undefined) && Object.values(user.roles).includes('distributor') && Object.values(user.roles).includes('master_affiliate')) ?
                                                <li key={Math.random()}><NavLink activeClassName="active" className="menu_item" to="/my-affiliate-account"><span>{enable_new_signup ? 'Master Affiliate Dashboard' : 'Master Affiliate Panel'}</span></NavLink></li>
                                                : ''
                                        }

                                        {
                                            (this.props.auth.isAuthenticated && (user.roles != undefined) && Object.values(user.roles).includes('customer')) ?
                                                // <li key={Math.random()}><a className="menu_item" href={CUSTOMER_URL + 'serviceLogin?token=' + user.token}><span>{enable_new_signup ? 'My Orders' : 'My Account'}</span></a></li>
                                                <li key={Math.random()}><a className="menu_item" href="/my-account/view-order"><span>{enable_new_signup ? 'Personal Orders' : 'My Account'}</span></a></li>
                                                : ''
                                        }

                                        {
                                            (this.props.auth.isAuthenticated && (Object.values(user.roles).includes('master_affiliate') || Object.values(user.roles).includes('distributor'))) ?
                                                <li key={Math.random()}><a className="menu_item" href={this.state.training_url} target='_blank'><span>Training</span></a></li>
                                                : ''
                                        }

                                        {
                                            (this.props.auth.isAuthenticated && Object.values(user.roles).includes('team_member')) ?
                                                <li key={Math.random()}><a className="menu_item" href={this.state.training_url} target='_blank'><span>Training</span></a></li>
                                                : ''
                                        }
                                        {
                                            <li key={Math.random()}><a className="menu_item" href={BLOG_URL}>BLOG</a></li>
                                        }
                                        {/* Shop HTML */}
                                        {/* <li class="dropdown position-static header_sub_menu">
                                            <a id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>SHOP</span><img src={MenuDropdown} alt="dropDown" /></a>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <div className="container">
                                                    <Row className="shop_menu align-items-center">
                                                        <Col md={10}>
                                                            <Row className="shop_menurow">
                                                                <Col className="w-20">
                                                                    <a href="/top-sellers" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/efa_img.png")} alt="best seller" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Best Sellers</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="/bundle-product" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={Bundles} alt="bundles" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Bundles</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/sleep-multiplier-img.png")} alt="Base Collection" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Base Collection</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/test-storm-img.png")} alt="Boost Collection" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Boost Collection</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/multi-power-vitality-for-her-img.png")} alt="Balance Collection" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Balance Collection</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={Protein} alt="Protein" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Protein</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/pre-1-img.png")} alt="Pre/Intra Workout" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Pre/Intra Workout</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/am_formula_img.png")} alt="Weight Loss" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Weight Loss</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={BundleMuscle} alt="Build Muscle" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Build Muscle</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/power-greens-img.png")} alt="Everyday Health" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Everyday Health</span>
                                                                    </a>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={2} className="d-flex align-items-center justify-content-center">
                                                            <a href="/products" className="font_16 text_white fw_el_bold d-flex shop_all_link let_spa_3">Shop All</a>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </li> */}
                                        {/* Shop HTML Ends */}
                                        {
                                            (this.state.menus.length <= 0) ? null :
                                                this.state.menus.map(function (menu, key) {
                                                    if (menu.type === "external") {
                                                        if (menu.manual_position == 'last') {
                                                            return (<li key={'ddm' + key}><a target={menu.open_new_tab == "yes" ? "_blank" : "_self"} className={`menu_item${history.location.pathname === menu.url ? ' active' : ''}`} href={menu.url}><span>Apparel</span></a></li>)
                                                        }
                                                    }
                                                })
                                        }

                                        {
                                            this.props.auth.isAuthenticated && this.props.auth.user.activate_meal ?
                                                <li key={Math.random()} className="pull-right"><NavLink activeClassName="active" className="menu_item" to="/activate-meal"><span>Activate Meal</span></NavLink></li>
                                                : ''
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="header_right_blk aff_header_right_blk">
                                {
                                    (user.roles != undefined) && Object.values(user.roles).includes('master_affiliate') && !Object.values(user.roles).includes('distributor') ?
                                        <NavLink to="/my-affiliate-account" className="mobile_logo_blk" exact strict>
                                            <img src={PrestigeLogo} alt="Prestige Labs" />
                                        </NavLink>
                                        :
                                        <NavLink to="/" exact strict className="mobile_logo_blk">
                                            <img src={PrestigeLogo} alt="Prestige Labs" />
                                        </NavLink>
                                }
                                <ul className="right_blk_menu d-flex align-items-center">
                                    {
                                        (this.props.auth.isAuthenticated) ?
                                            <li class="dropdown">
                                                <span activeClassName="active" className="menu_item d-flex cursor-pointer d-flex align-items-center" exact id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="d-block pr_12">
                                                        <span className="d-block text-right mb_2 font_16 text_black fw_ar_reg mobil_hide">Welcome</span>
                                                        <span className="text-capitalize d-block text-right font_16 text_black fw_ar_reg">{this.props.auth.user.first_name}</span>
                                                    </span>
                                                    <img src={MyAccount} alt="MyAccount" />
                                                </span>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <NavLink to="/my-account/edit-account" className="font_16 text_black fw_el_reg">Edit Account</NavLink>
                                                    <a onClick={this.logout} title="Prestige Labs" className='font_16 text_black fw_el_reg' href="javascript.void(0)" >Logout</a>
                                                </div>
                                            </li>
                                            : ""
                                    }
                                </ul>
                            </div>
                            {/* <div className="mob_main_user" onClick={this.showMenu}>
                                {
                                    this.state.showusermenu ?
                                        <div className="mob_main_user_wrapper">
                                            <ul>
                                                {
                                                    (user.roles != undefined) && Object.values(user.roles).includes('master_affiliate') && !Object.values(user.roles).includes('distributor') ?
                                                        <li><NavLink to="/my-affiliate-account">{enable_new_signup ? 'Affiliate Dashboard' : 'Affiliate Panel'}</NavLink></li>
                                                        :
                                                        <li><NavLink to="/my-account">{enable_new_signup ? 'Affiliate Dashboard' : 'Affiliate Panel'}</NavLink></li>
                                                }

                                            </ul>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <NavLink to="/cart"><div className="mob_main_cart"></div></NavLink> */}
                        </div>

                        <div className="mob_text_order topemailphonecolor">
                            <p className="m-0 font_14 text_black fw_ar_reg">Reach us at <a className='text_black text_underline' href={`mailto:${email}`}>{email}</a> or <a className='text_black text_underline' href={`tel:${phone}`}>{phone}</a></p>
                        </div>
                    </div>

                    <div className="hide_small_screen">
                        <div className="container-fluid">
                            <div className="logo-wrapper">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="site-logo">
                                        {
                                            (user.roles != undefined) && Object.values(user.roles).includes('master_affiliate') && !Object.values(user.roles).includes('distributor') ?
                                                <NavLink title="Prestige Labs" activeClassName='active' to="/my-affiliate-account" exact>
                                                    <img src={PrestigeLogo} className="attachment-full size-full" alt="" title="" />
                                                </NavLink>
                                                :
                                                <NavLink title="Prestige Labs" activeClassName='active' to="/" exact>
                                                    <img src={PrestigeLogo} className="attachment-full size-full" alt="" title="" />
                                                </NavLink>
                                        }
                                    </div>
                                    <div className="col-md-8">
                                        {/* <form action="" className="form_input_blk search_pro_form position-relative">
                                            <input type="text" className="form-control height_37" placeholder='Search for Products' />
                                            <div className="search_icon position-absolute d-flex align-items-center justify-content-center">
                                                <img src={SearchIcon} alt="search icon" />
                                            </div>
                                        </form> */}
                                    </div>
                                    <div className="header_right_blk aff_header_right_blk">
                                        <ul className="right_blk_menu d-flex align-items-center">
                                            {
                                                (this.props.auth.isAuthenticated) ?
                                                    <li class="dropdown">
                                                        <span activeClassName="active" className="menu_item d-flex cursor-pointer d-flex align-items-center" exact id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <span className="d-block pr_12">
                                                                <span className="d-block text-right mb_2 font_16 text_black fw_ar_reg">Welcome</span>
                                                                <span className="text-capitalize d-block text-right font_16 text_black fw_ar_reg">{this.props.auth.user.first_name}</span>
                                                            </span>
                                                            <img src={MyAccount} alt="MyAccount" />
                                                        </span>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <NavLink to="/my-account/edit-account" className="font_16 text_black fw_el_reg">Edit Account</NavLink>
                                                            <a onClick={this.logout} title="Prestige Labs" className='font_16 text_black fw_el_reg' href="javascript.void(0)" >Logout</a>
                                                        </div>
                                                    </li>
                                                    : ""
                                            }
                                        </ul>
                                    </div>
                                    {/* <div className="col-md-8">
                                        <div className="top-header distributorTopHeader">
                                            <div className="header-top  header_contact">
                                                <div className="pull-right top-widgets-right">
                                                   

                                                    <div className="top-widgets-date-time topemailphonecolor">
                                                        Reach us at <a href={`mailto:${email}`}>{email}</a> or <a href={`tel:${phone}`}>{phone}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                            </div>
                        </div>

                        {/* <section id="nav-for-scroll"> */}
                        <div className="site_menu sub_header white_bg">
                            <div className="container-fluid d-flex align-items-center justify-content-between">
                                <nav className="col-md-8 p-0 d-flex align-items-center position-static">
                                    {/* <div className="header-logo-fix position-static">
                                        {
                                            (user.roles != undefined) && Object.values(user.roles).includes('master_affiliate') && !Object.values(user.roles).includes('distributor') ?
                                                <NavLink to="/my-affiliate-account" exact strict>
                                                    <img src={CheckoutLogo} alt="Prestige  Labs" title="" />
                                                </NavLink>
                                                :
                                                <NavLink to="/" exact strict>
                                                    <img src={CheckoutLogo} alt="Prestige  Labs" title="" />
                                                </NavLink>
                                        }
                                    </div> */}
                                    {
                                        this.props.auth.isAuthenticated ?
                                            <Fragment>
                                                {
                                                    ((user.new_agreement_required == "yes") && Object.values(user.roles).includes('distributor')) ? '' :
                                                        // user.new_agreement_required=="no"?
                                                        <ul id="menu-main-menu" className="menu sub_header_menu">
                                                            {
                                                                (this.state.menus.length <= 0) ? null :
                                                                    this.state.menus.map(function (menu, key) {

                                                                        if ((user.roles != undefined) && Object.values(user.roles).includes('master_affiliate') && !Object.values(user.roles).includes('distributor')) {
                                                                            if ((menu.url === '') || (menu.url === '/') || (menu.url === '/meals')) {

                                                                            } else {
                                                                                if (menu.type === "external") {
                                                                                    if (menu.manual_position != 'last') {
                                                                                        return (<li key={'ddm' + key}><a target={menu.open_new_tab == "yes" ? "_blank" : "_self"} className={`menu_item${history.location.pathname === menu.url ? ' active' : ''}`} href={menu.url}><span>{menu.label}</span></a></li>)
                                                                                    }
                                                                                } else {
                                                                                    if (menu.url === "/my-account") {
                                                                                        return (<li key={'ddm' + key}><NavLink activeClassName="active" className="menu_item" to="/my-affiliate-account" exact><span>{enable_new_signup ? 'Master Affiliate Dashboard' : 'Master Affiliate Panel'}</span></NavLink></li>)
                                                                                    } else {
                                                                                        return (<li key={'ddm' + key}><NavLink activeClassName="active" className="menu_item" to={menu.url} exact><span>{menu.label}</span></NavLink></li>)
                                                                                    }
                                                                                }
                                                                            }
                                                                        } else {
                                                                            if (menu.type === "external") {
                                                                                if (menu.manual_position != 'last') {
                                                                                    return (<li key={'ddm' + key}><a target={menu.open_new_tab == "yes" ? "_blank" : "_self"} className={`menu_item${history.location.pathname === menu.url ? ' active' : ''}`} href={menu.url}><span>{menu.label}</span></a></li>)
                                                                                }
                                                                            } else {
                                                                                if (menu.url === "/my-account") {
                                                                                    if ((user.roles != undefined) && Object.values(user.roles).includes('distributor')) {
                                                                                        return (<li key={'ddm' + key}><NavLink activeClassName="active" className="menu_item" to={menu.url} exact><span>{enable_new_signup ? 'Affiliate Dashboard' : 'Affiliate Panel'}</span></NavLink></li>)
                                                                                    } else {
                                                                                        return (<li key={'ddm' + key}><NavLink activeClassName="active" className="menu_item" to={menu.url} exact><span>Team Member Panel</span></NavLink></li>)
                                                                                    }
                                                                                } else {
                                                                                    if (menu.url === "/meals") {
                                                                                        if (meal_menu_active) {
                                                                                            return (<li key={'ddm' + key}><NavLink activeClassName="active" className="menu_item" to={menu.url} exact><span>{menu.label}</span></NavLink></li>)
                                                                                        } else {
                                                                                            return null
                                                                                        }
                                                                                    } else {
                                                                                        if (menu.label == 'Supplements') {
                                                                                            return (
                                                                                              <li className="dropdown position-static header_sub_menu">
                                                                                                <a id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#">
                                                                                                  <span>{menu.label}</span>
                                                                                                  <img src={MenuDropdown} alt="dropDown" />
                                                                                                </a>
                                                                                                <div className="dropdown-menu shop_menu_blk" aria-labelledby="dropdownMenuButton">
                                                                                                  <div className="w-100">
                                                                                                    <ul className="d-flex p-0 m-0 flex-wrap shop_menu align-items-center justify-content-center">
                                                                                                        <div className="w-100">
                                                                                                          <ul className="d-flex p-0 m-0 shop_menu flex-wrap align-items-center justify-content-center tags_centre">
                                                                                                            {tags.map((tag, index) => (
                                                                                                              tag.header_tag == 1 && (
                                                                                                                <li key={index} className="d-flex align-items-center justify-content-center">
                                                                                                                  <a href={tag.tag_name == 'Build Your Own Bundle'? '/custom-bundle': `/tagged-products/${tag.id}`}
                                                                                                                    className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">
                                                                                                                    {tag.tag_name}
                                                                                                                  </a>
                                                                                                                </li>
                                                                                                              )
                                                                                                            ))}
                                                                                                          </ul>
                                                                                                        </div>
                                                                                                    </ul>
                                                                                                  </div>
                                                                                                </div>
                                                                                              </li>
                                                                                            );
                                                                                          } else {
                                                                                            return (
                                                                                              <li key={'ddm' + key}>
                                                                                                <NavLink activeClassName="active" className="menu_item" to={menu.url} exact>
                                                                                                  <span>{menu.label}</span>
                                                                                                </NavLink>
                                                                                              </li>
                                                                                            );
                                                                                          }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                    })
                                                            }
                                                           
                                                            {
                                                                (this.props.auth.isAuthenticated && Object.values(user.roles).includes('distributor') && Object.values(user.roles).includes('master_affiliate')) ?
                                                                    <li key={Math.random()}><NavLink activeClassName="active" className="menu_item" to="/my-affiliate-account"><span>{enable_new_signup ? 'Master Affiliate Dashboard' : 'Master Affiliate Panel'}</span></NavLink></li>
                                                                    : ''
                                                            }

                                                            {
                                                                (this.props.auth.isAuthenticated && Object.values(user.roles).includes('customer')) ?
                                                                    <li key={Math.random()}><a className="menu_item" href={CUSTOMER_URL + 'serviceLogin?token=' + user.token}><span>{enable_new_signup ? 'Personal Orders' : 'My Account'}</span></a></li>
                                                                    // <li key={Math.random()}><a className="menu_item" href="/my-account/view-order"><span>{enable_new_signup ? 'My Orders' : 'My Account'}</span></a></li>
                                                                    : ''
                                                            }

                                                            {
                                                                (this.props.auth.isAuthenticated && (Object.values(user.roles).includes('master_affiliate') || Object.values(user.roles).includes('distributor'))) ?
                                                                    <li key={Math.random()}><a className="menu_item" href={this.state.training_url} target='_blank'><span>Training</span></a></li>
                                                                    : ''
                                                            }

                                                            {
                                                                (this.props.auth.isAuthenticated && Object.values(user.roles).includes('team_member')) ?
                                                                    <li key={Math.random()}><a className="menu_item" href={this.state.training_url} target='_blank'><span>Training</span></a></li>
                                                                    : ''
                                                            }
                                                            {
                                                                <li key={Math.random()}><a className="menu_item" href={BLOG_URL}>BLOG</a></li>
                                                            }
                                                            {/* Shop HTML */}
                                                            {/* <li class="dropdown position-static header_sub_menu">
                                                                <a id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>SHOP</span><img src={MenuDropdown} alt="dropDown" /></a>
                                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    <div className="container">
                                                                        <Row className="shop_menu align-items-center">
                                                                            <Col md={10}>
                                                                                <Row className="shop_menurow">
                                                                                    <Col className="w-20">
                                                                                        <a href="/top-sellers" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                                            <img src={require("../../Assets/images/refer_images/efa_img.png")} alt="best seller" />
                                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Best Sellers</span>
                                                                                        </a>
                                                                                    </Col>
                                                                                    <Col className="w-20">
                                                                                        <a href="/bundle-product" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                                            <img src={Bundles} alt="bundles" />
                                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Bundles</span>
                                                                                        </a>
                                                                                    </Col>
                                                                                    <Col className="w-20">
                                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                                            <img src={require("../../Assets/images/refer_images/sleep-multiplier-img.png")} alt="Base Collection" />
                                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Base Collection</span>
                                                                                        </a>
                                                                                    </Col>
                                                                                    <Col className="w-20">
                                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                                            <img src={require("../../Assets/images/refer_images/test-storm-img.png")} alt="Boost Collection" />
                                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Boost Collection</span>
                                                                                        </a>
                                                                                    </Col>
                                                                                    <Col className="w-20">
                                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                                            <img src={require("../../Assets/images/refer_images/multi-power-vitality-for-her-img.png")} alt="Balance Collection" />
                                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Balance Collection</span>
                                                                                        </a>
                                                                                    </Col>
                                                                                    <Col className="w-20">
                                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                                            <img src={Protein} alt="Protein" />
                                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Protein</span>
                                                                                        </a>
                                                                                    </Col>
                                                                                    <Col className="w-20">
                                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                                            <img src={require("../../Assets/images/refer_images/pre-1-img.png")} alt="Pre/Intra Workout" />
                                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Pre/Intra Workout</span>
                                                                                        </a>
                                                                                    </Col>
                                                                                    <Col className="w-20">
                                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                                            <img src={require("../../Assets/images/refer_images/am_formula_img.png")} alt="Weight Loss" />
                                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Weight Loss</span>
                                                                                        </a>
                                                                                    </Col>
                                                                                    <Col className="w-20">
                                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                                            <img src={BundleMuscle} alt="Build Muscle" />
                                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Build Muscle</span>
                                                                                        </a>
                                                                                    </Col>
                                                                                    <Col className="w-20">
                                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                                            <img src={require("../../Assets/images/refer_images/power-greens-img.png")} alt="Everyday Health" />
                                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Everyday Health</span>
                                                                                        </a>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col md={2} className="d-flex align-items-center justify-content-center">
                                                                                <a href="/products" className="font_16 text_white fw_el_bold d-flex shop_all_link let_spa_3">Shop All</a>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </li> */}
                                                            {/* Shop HTML Ends */}
                                                            {
                                                                (this.state.menus.length <= 0) ? null :
                                                                    this.state.menus.map(function (menu, key) {
                                                                        if (menu.type === "external") {
                                                                            if (menu.manual_position == 'last') {
                                                                                return (<li key={Math.random()}><a target={menu.open_new_tab == "yes" ? "_blank" : "_self"} className={`menu_item${history.location.pathname === menu.url ? ' active' : ''}`} href={menu.url}><span>Apparel</span></a></li>)
                                                                            }
                                                                        }
                                                                    })
                                                            }

                                                            {
                                                                this.props.auth.isAuthenticated && this.props.auth.user.activate_meal ?
                                                                    <li key={Math.random()} className="pull-right"><NavLink activeClassName="active" className="menu_item" to="/activate-meal"><span>Activate Meal</span></NavLink></li>
                                                                    : ''
                                                            }

                                                            {
                                                                ENABLE_AFFILIATE_REQUEST ?
                                                                    <Fragment>
                                                                        {
                                                                            (this.props.auth.isAuthenticated && Object.values(user.roles).includes('distributor') && !Object.values(user.roles).includes('master_affiliate')) ?
                                                                                <li key={Math.random()} className="pull-right"><NavLink activeClassName="active" className="menu_item" to="/affiliate-request"><span>Refer Friends to Join Prestige Labs</span></NavLink></li>
                                                                                : ''
                                                                        }
                                                                    </Fragment>
                                                                    : ''
                                                            }

                                                        </ul>
                                                    // :''
                                                }
                                            </Fragment>
                                            : ''
                                    }

                                </nav>
                                <div className="col-md-4 d-flex justify-content-end p-0">
                                    <div className="d-flex align-items-center">
                                        <img src={ReachUsIcon} alt="reach us Icon" />
                                        <span className="d-block font_12 text_black fw_ar_reg pl_7">Reach us at <a className='text_underline text_black' href={`mailto:${email}`}>{email}</a> or <a className='text_black' href={`tel:${phone}`}>{phone}</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </section> */}
                        {/* <div class="dropdown-menu shop_menu_blk " > */}

                        {/* </div> */}
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

Header.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default withRouter(connect(mapStateToProps, { logout })(Header));
