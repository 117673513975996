import React, { PureComponent } from 'react';

class MyAccount extends PureComponent {
    state = {  }
    render() { 
        return (
            <div>Customer Account</div>
        );
    }
}
 
export default MyAccount;