import React, { Fragment, PureComponent } from "react";
import AddToCartButton from "./AddToCartButton";
import { CURRENCY_FORMAT, GET_STORAGE, SET_STORAGE, BUNDLE_OFFER_NEW } from "../../Constants/AppConstants";
import Parser from 'html-react-parser';
import history from "../../history";
import ReactImageFallback from "react-image-fallback";
import $ from "jquery";
import { Row, Col } from "react-bootstrap";
import SubDropdown from '../../Assets/images/affiliate_imges/sub_dropdown.svg'

class CustomProductsListWithFlavors extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            addToBundle: "Add to Bundle",
            modalId: "disclaimerModal" + this.props.product.product_id,
            productDetails: this.props.product,
            subscription: this.props.subscription,
            subscription_status: '',
            monthId: "1"
        }
    }

    showHideProductDetails = () => {
        this.setState(state => ({
            isToggleOn: !state.isToggleOn
        }));
    }

    componentDidMount() {
        let product = this.state.productDetails;

        this.setState({
            monthId: (product.hasOwnProperty("months") && product.months.length > 0) ? product.months[0].id : null,
            
        })
        if (this.state.quantity) {
            this.setState({ quantity: parseInt(this.state.quantity) })
        } else {
            this.setState({ quantity: parseInt(product.quantity) })
        }
        if (product.flavors.length > 0) {
            this.activeFlavors(product);
        }
    }

    firstMonthVariation = (flavorId) => {
        const product = this.state.productDetails;
        const concateVariationId = product.hasOwnProperty("first_month") ? (product.first_month + "_" + flavorId) : null;
        const variation = product.hasOwnProperty("variations") ? product.variations[concateVariationId] : null;

        let regularPriceDisplay = false;
        let regular_price = 0;
        let sale_price = 0;
        if(variation){
            if (parseFloat(variation.sale_price) > 0) {
                regularPriceDisplay = true;
                regular_price = variation.regular_price;
                sale_price = variation.sale_price;
            } else {
                regular_price = variation.regular_price;
                sale_price = variation.regular_price;
            }
            let weight_lb = "0.00";
            this.setState({
                productId: product.product_id,
                productName: product.title,
                productSKU: variation.sku,
                firstRegularPrice: CURRENCY_FORMAT(regular_price),
                firstSalePrice: CURRENCY_FORMAT(sale_price),
                productDescription: product.short_description,
                productIsBundle: product.is_bundle,
                productWeightInlb: weight_lb,
                productSlug: product.slug,
                image: product.thumb_image,
                variationId: variation.variation_id,
                variationName: variation.variation_name,
                regularPriceDisplay: regularPriceDisplay,
                regularPrice: regular_price,
                salePrice: sale_price,
                discountPrice: ((sale_price - (sale_price * product.subscription_save_percentage) / 100)),
                subscription: this.state.subscription,
                stockStatus: variation.stock_status,
                inStock: parseInt(variation.quantity),
                quantity: 1,
                cartDiscountPrice: ((sale_price - (sale_price * product.subscription_save_percentage) / 100)),
                subscription_status: variation.subscription_status
            });
        }   
    }
    changeFirstMonthFlovour = (e) => {
        this.firstMonthVariation(e.target.value);
        this.state.firstMonthflavorId = e.target.value;
    }

    addToBundle = (e) => {

        let bundle = [];
        let data = [];
        let discount_data_new = JSON.parse(GET_STORAGE("Discount_data"));

        if (!GET_STORAGE("Bundle")) {
            SET_STORAGE("Bundle", JSON.stringify(bundle));
        }
        bundle = JSON.parse(GET_STORAGE("Bundle"));

        let newItem = {
            cart_product_id: e.target.getAttribute('cart_product_id'),
            cart_product_name: e.target.getAttribute('cart_product_name'),
            cart_product_sku: e.target.getAttribute('cart_product_sku'),
            cart_product_description: e.target.getAttribute('cart_product_description'),
            cart_product_isbundle: e.target.getAttribute('cart_product_isbundle'),
            cart_product_weight_lb: e.target.getAttribute('cart_product_weight_lb'),
            cart_product_slug: e.target.getAttribute('cart_product_slug'),
            cart_image: e.target.getAttribute('cart_image'),
            cart_variation_id: e.target.getAttribute('cart_variation_id'),
            cart_variation_name: e.target.getAttribute('cart_variation_name'),
            cart_sale_price: e.target.getAttribute('cart_sale_price'),
            subscription: e.target.getAttribute('subscription'),
            cart_subscription_msg: e.target.getAttribute('cart_subscription_msg'),
            cart_discount_price: e.target.getAttribute('cart_discount_price'),
            quantity: parseInt(e.target.getAttribute('quantity')),
            in_stock: parseInt(e.target.getAttribute('in_stock')),
            regular_price: parseInt(e.target.getAttribute('regular_price')),
            discount_data_new: discount_data_new,
            custom_bundle_discount_price: e.target.getAttribute('cart_sale_price'),
            custom_bundle_discount: 0,
            subscription_status: e.target.getAttribute('subscription_status')
        }

         if (bundle != null) {
            bundle.forEach(function (item, key) {
                if ((item.cart_variation_id == e.target.getAttribute('cart_variation_id'))) {
                    if (parseInt(item.quantity) >= parseInt(e.target.getAttribute('in_stock'))) {
                        alert("Out Of Stock") // Check product quantity
                    } else {
                        item.quantity = Number(item.quantity) + Number(newItem.quantity);
                    }
                    data.push(item);
                    newItem = null;
                } else {
                    data.push(item);
                }
            });
            if (newItem != null) {
                data.push(newItem);
            }
        } else {
            data.push(newItem);
        }

        SET_STORAGE("Bundle", JSON.stringify(data));
        this.addToCartLabelChange();
        setTimeout(() => {
            this.props.updateAddedProducts(JSON.stringify(data));
        }, 1500);
        this.getCart();
        //document.querySelector("body").scrollIntoView();

       // klaviyoAddedToCart(e.target.getAttribute('cart_variation_id'));
    }

    addToCartLabelChange = (e) => {
        this.setState({
            addToBundle: "Adding..."
        })

        setTimeout(function () {
            this.setState({
                addToBundle: "Thank You"
            })
        }.bind(this), 1000)

        setTimeout(function () {
            this.setState({
                addToBundle: "Add More ..."
            })
        }.bind(this), 2000)
    }

    getCart = () => {
        BUNDLE_OFFER_NEW();
        let discount_data_new = JSON.parse(GET_STORAGE("Discount_data"));
        this.setState({ discount_data: discount_data_new });
        if (GET_STORAGE("Bundle")) {
            const products = JSON.parse(GET_STORAGE("Bundle"));
            const discountApplied = this.state.discount_data === discount_data_new;
            if (discount_data_new.length > 0 && !discountApplied) {
                const updatedProducts = products.map((product, index) => {
                    const Inddiscount = discount_data_new[index]; 
                    const newSalePrice = (product.cart_sale_price - Inddiscount);
                    // const discountedPrice = (product.cart_sale_price - (product.cart_sale_price * (Inddiscount / 100)));
                    // const newSalePrice = discountedPrice < 0 ? 0 : discountedPrice;
                    // const discount = (product.cart_sale_price - newSalePrice);
                    return {
                        ...product,
                        custom_bundle_discount_price: newSalePrice.toFixed(2),
                        custom_bundle_discount: Inddiscount.toFixed(2)
                    };
                });
                this.setState({
                    AddedProducts: updatedProducts,
                    discount_data: discount_data_new
                });
                SET_STORAGE("Bundle", JSON.stringify(updatedProducts));
            } else {
                this.setState({ AddedProducts: products, discount_data: discount_data_new });
            }
        } else {
            this.setState({ AddedProducts: [] });
        }
    };
    
    updateAddedProducts = (newProduct) => {
        BUNDLE_OFFER_NEW();
        let discount_data_new = JSON.parse(GET_STORAGE("Discount_data"));
        const products = JSON.parse(newProduct);
        const discountApplied = this.state.discount_data === discount_data_new;
        if (discount_data_new.length > 0 && !discountApplied) {
            const updatedProducts = products.map((product, index) => {
                const Inddiscount = discount_data_new[index];
                const newSalePrice = (product.cart_sale_price - Inddiscount);
                // const discountedPrice = (product.cart_sale_price - (product.cart_sale_price * (Inddiscount / 100)));
                // const newSalePrice = discountedPrice < 0 ? 0 : discountedPrice;
                // const discount = (product.cart_sale_price - newSalePrice);
                return {
                    ...product,
                    custom_bundle_discount_price: newSalePrice.toFixed(2),
                    custom_bundle_discount: Inddiscount.toFixed(2)
                };
            });
            this.setState({
                AddedProducts: updatedProducts,
                discount_data: discount_data_new
            });
            SET_STORAGE("Bundle", JSON.stringify(updatedProducts));
        } else {
            this.setState({
                AddedProducts: JSON.parse(newProduct),
                discount_data: discount_data_new
            });
        }
    }

    activeFlavors = (product) => {
        const variationsForFirstMonth = Object.values(product.variations)
            .filter(variation => variation.month === 1);
        const activeFirstFlavorIds = variationsForFirstMonth.map(variation => variation.flavor_id);
        const updatedFirstFlavors = Object.values(product.flavors.filter(flavor => activeFirstFlavorIds.includes(flavor.id)));

        let defaultFirstFlavor = null;
        if (updatedFirstFlavors.length > 0) {
            defaultFirstFlavor = updatedFirstFlavors[0];
        }

        if (defaultFirstFlavor) {
            this.setState({ firstMonthflavorId: defaultFirstFlavor.id });
            this.setState({ firstMonthActiveFlavors: updatedFirstFlavors })
            this.filterFlavors(this.state.subscription, product, updatedFirstFlavors)
        }
    }

    filterFlavors = (subscription, product, activeFlavors) => {
        if(activeFlavors != undefined){
            if (subscription == 'yes'){
                const variationsWithSubscriptionStatus0 = Object.values(product.variations)
                .filter(variation => (variation.subscription_status == "0" || variation.subscription_status == null));
                const updatedVariations = Object.fromEntries(
                    Object.entries(product.variations)
                        .filter(([key, variation]) => 
                            !variationsWithSubscriptionStatus0.some(toRemove => toRemove.variation_id == variation.variation_id)
                        )
                );
                const flavorIdsToRemove = variationsWithSubscriptionStatus0.map(variation => variation.flavor_id);
                const updatedFlavors = activeFlavors.filter(flavor => !flavorIdsToRemove.includes(flavor.id));
                this.setState({ productDetails: { ...product, flavors: updatedFlavors } });
                let defaultFlavor = null;

                if (updatedFlavors.length > 0) {
                    defaultFlavor = updatedFlavors.find(flavor => flavor.id === this.state.flavorId) || updatedFlavors[0];
                }

                this.setState({ 
                    productDetails: { 
                        ...product, 
                        flavors: updatedFlavors,
                        variations: updatedVariations,
                        defaultFirstFlavor: defaultFlavor,
                    },
                    firstMonthActiveFlavors: updatedFlavors
                }, () => {
                    if (defaultFlavor) {
                        this.setState({ firstMonthflavorId: defaultFlavor.id });
                    }
                });
                if (defaultFlavor) {
                    this.firstMonthVariation(defaultFlavor.id);
                }
            } else {
                this.setState({
                    productDetails: {
                        ...product,
                        flavors: activeFlavors,
                        variations: product.variations,
                        defaultFirstFlavor: null,
                    },
                    firstMonthActiveFlavors : activeFlavors
                });
                this.firstMonthVariation(product.flavors[0].id);
            }
        } 
    }

    quantityIncrement = (e) => {
        this.setState({ quantity: parseInt(Number(this.state.quantity) + 1) })
    }

    quantityDecrement = (e) => {
        this.setState({ quantity: parseInt(Number(this.state.quantity) - 1) });
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const product = (this.state.productDetails.variations && Object.keys(this.state.productDetails.variations).length != 0) ? this.state.productDetails : '';
        let productExistsInBundle = false;
        const bundle = GET_STORAGE("Bundle") ? JSON.parse(GET_STORAGE("Bundle")): '';
        if (bundle && bundle.length > 0) {
            productExistsInBundle = bundle.some(item => item.cart_product_id == product.product_id);
        }
        const { firstMonthflavorId } = this.state
        const ActiveFirstMonthFlavors = this.state.firstMonthActiveFlavors
        if (!product || Object.keys(product).length == 0) {
            return null;
        }
        return (
            <Fragment>
                <div className="order_pro_list white_bg cus_pro_order_blk">
                    <Row className="m-0">
                        <Col md={6} className="p-0">
                            <div className="d-flex order_pro_left_det">
                                <div className="order_pro_img">
                                    <ReactImageFallback
                                        src={product.hasOwnProperty('list_image') ? product.list_image : null}
                                        fallbackImage={require('../../Assets/images/preloader.gif')}
                                        initialImage={require('../../Assets/images/preloader.gif')}
                                        alt={product.hasOwnProperty('title') ? product.title : null}
                                        className="" />
                                </div>
                                <div className="order_pro_det pl_16">
                                    <h2 className="text_black d-flex m-0 font_20 let_spa_2 fw_el_bold"> {product.hasOwnProperty('title') ? product.title : null} </h2>
                                    <h3 className="text_black d-flex mb_10 font_16 let_spa_2 fw_ar_reg pro_des_aff"> {product.hasOwnProperty('short_description') ? Parser(product.short_description) : null}</h3>

                                </div>
                            </div>
                        </Col>
                        <Col md={6} className="p-0">
                            <div className="row m-0 h-100">
                                <div className="col-md-12 p-0 pro_qty_cat_blk">
                                    <Col md={12} className="pl_10 h-100 pr-0 order_pro_btn d-flex flex-column align-items-end justify-content-between">
                                        <div className="d-flex align-items-center justify-content-end mb_15">
                                            {
                                                (this.state.firstRegularPrice != '$0.00 USD') ?
                                                    <span className="font_14 d-block items_text let_spa_2 fw_ar_reg line_through">{this.state.firstRegularPrice}</span>
                                                    : ""
                                            }
                                            <span className='font_16 text_black fw_ar_reg d-block pl_10'>{this.state.firstSalePrice}</span>

                                        </div>
                                        <div className="d-flex align-items-start mb_20">
                                            {(ActiveFirstMonthFlavors !== undefined) ? (
                                                <div className="subscri_blk choose_fla_blk">
                                                    <h3 className="font_16 text_black let_spa_2 fw_ar_reg m-0 ">Choose Flavor</h3>
                                                    <select onChange={this.changeFirstMonthFlovour} name="flovour" className="w-100 select_flavour font_16 let_spa_2 text-black fw_ar_bold" value={firstMonthflavorId}>
                                                        {
                                                            (ActiveFirstMonthFlavors !== undefined) ?
                                                                ActiveFirstMonthFlavors.map(function (flavor, key) {
                                                                    return (
                                                                        <option key={key} value={flavor.hasOwnProperty('id') ? flavor.id : null}>{flavor.hasOwnProperty('value') ? flavor.value : null}</option>
                                                                    )
                                                                }.bind(this)) : null
                                                        }
                                                    </select>
                                                </div>
                                            ) : (
                                                <div className="subscri_blk choose_fla_blk">
                                                    <h3 className="font_16 text_black fw_ar_bold mb_8">Currently No Flavors Active for Subscription!</h3>
                                                </div>
                                            )}
                                        </div>
                                        {(ActiveFirstMonthFlavors !== undefined) ?
                                            <div className="quan_cart_blk d-flex w-100 justify-content-end">
                                                <div className="cart_quantity quan_cart_blk">
                                                    {(this.state.product_status != "0") ?
                                                        <div data-title="Quantity" className="white_bg input-group refer_product_input_group">
                                                            <div className="input-group-prepend">
                                                                <button type="button" onClick={this.quantityDecrement} disabled={(this.state.quantity <= 1) ? "disabled" : ""} className="btn btn-sm"><i className="fa fa-minus"></i></button>
                                                            </div>
                                                            <input onChange={this.changeHandler} value={this.state.quantity} key={Math.random()} name="quantity[]" type="text" readOnly className="text-center form-control form-control-sm product_quantity_content refer_product_quantity_content" step="1" min="0" max="" title="Qty" size="4" pattern="[0-9]*" inputMode="numeric" />
                                                            <div className="input-group-prepend">
                                                                <button type="button" onClick={this.quantityIncrement} disabled={(parseInt(this.state.quantity) >= parseInt(product.in_stock)) ? "disabled" : ""} className="btn btn-sm"><i className="fa fa-plus"></i></button>
                                                            </div>
                                                        </div> : " "
                                                    }
                                                </div>
                                                {/* <AddToCartButton firstMonth="yes" cl cartState={this.state} updateError={this.props.updateError} /> */}
                                                <div className="quan_cart_blk  add_to_bundle">
                                                {this.state.inStock <= 0 ? (
                                                    <input name="addToCart" value="Out Of Stock" className="cart_add_product_btn stockout-btn disable" type="button"/>
                                                ) : (
                                                <input
                                                    onClick={this.addToBundle}
                                                    cart_product_id={this.state.productId}
                                                    cart_product_name={this.state.productName}
                                                    cart_product_sku={this.state.productSKU}
                                                    cart_product_description={this.state.productDescription}
                                                    cart_product_isbundle={this.state.productIsBundle}
                                                    cart_product_weight_lb={this.state.productWeightInlb}
                                                    cart_product_slug={this.state.productSlug}
                                                    cart_image={this.state.image}
                                                    cart_variation_id={this.state.variationId}
                                                    cart_variation_name={this.state.variationName}
                                                    cart_sale_price={this.state.salePrice}
                                                    // subscription={this.state.subscription}
                                                    // cart_subscription_msg={this.state.subscriptionMsg}
                                                    cart_discount_price={this.state.discountPrice}
                                                    quantity={this.state.quantity}
                                                    in_stock={this.state.inStock}
                                                    regular_price={this.state.regularPrice} 
                                                    subscription_status={this.state.subscription_status}
                                                    Value={this.state.addToBundle} name="addToBundle"  className='cart_add_product_btn' type="button" />
                                                )}
                                            </div>
                                            </div>
                                            :
                                            <div className="quan_cart_blk  add_to_bundle">
                                                <input
                                                    onClick={this.addToBundle}
                                                    cart_product_id={this.state.productId}
                                                    cart_product_name={this.state.productName}
                                                    cart_product_sku={this.state.productSKU}
                                                    cart_product_description={this.state.productDescription}
                                                    cart_product_isbundle={this.state.productIsBundle}
                                                    cart_product_weight_lb={this.state.productWeightInlb}
                                                    cart_product_slug={this.state.productSlug}
                                                    cart_image={this.state.image}
                                                    cart_variation_id={this.state.variationId}
                                                    cart_variation_name={this.state.variationName}
                                                    cart_sale_price={this.state.salePrice}
                                                    // subscription={this.state.subscription}
                                                    // cart_subscription_msg={this.state.subscriptionMsg}
                                                    cart_discount_price={this.state.discountPrice}
                                                    quantity={this.state.quantity}
                                                    in_stock={this.state.inStock}
                                                    regular_price={this.state.regularPrice}
                                                    subscription_status={this.state.subscription_status}
                                                    defaultValue={this.state.addToBundle} name="addToBundle" className="cart_add_product_btn" type="button" />
                                            </div>
                                        }
                                    </Col>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}

export default CustomProductsListWithFlavors;
