import React, { Component, Fragment } from 'react';
import ReactImageFallback from "react-image-fallback";
import { NavLink } from "react-router-dom";
import { AJAX_REQUEST, CURRENCY_FORMAT, GET_STORAGE } from "../../../Constants/AppConstants";
import OrderedProductsList from './OrderedProductsList';
import RelatedSubscriptionsList from './RelatedSubscriptionsList';
import history from '../../../history';
import { Row, Col } from 'react-bootstrap';
import EmailIcon from '../../../Assets/images/customer_images/email_icon.svg'
import productImage from '../../../Assets/images/customer_images/order_product_img.svg'
import PhoneIcon from '../../../Assets/images/customer_images/phone_icon.svg'

class ViewOrder extends Component {
    constructor(props) {
        super(props)
        // Check Tax
        let settings = '';
        if (GET_STORAGE('settings')) {
            settings = JSON.parse(GET_STORAGE('settings'));
        }
        this.state = {
            re_order_status: 'yes',
            taxStatus: settings ? settings.tax_status : 0,
            error_meg: '',
            products: [],
            order_id: this.props.match.params.id,
            order_note: '',
            created_at: '',
            status: '',
            shipping_cost: 0,
            tax_amount: 0,
            shipping: '',
            payment_method: '',
            order_type: '',
            total: 0,
            billing_address: [],
            shipping_address: [],
            related_subscription: [],
            discounts: [],
            loading: true
        }
        document.title = "Order Details -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        this.gettheOrderDetails();
    }

    gettheOrderDetails = () => {
        let data = {
            order_id: this.state.order_id
        }
        AJAX_REQUEST("POST", "order/getDetails", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                let data = results.response.data;
                this.setState({
                    re_order_status: data.re_order_status,
                    products: data.products,
                    total: data.total,
                    order_note: data.order_note,
                    payment_method: data.payment_method,
                    order_type: data.order_type,
                    shipping: data.shipping,
                    shipping_cost: data.shipping_cost,
                    tax_amount: data.tax_amount,
                    subtotal: data.subtotal,
                    status: data.status,
                    created_at: data.created_at,
                    billing_address: data.billing_address,
                    shipping_address: data.shipping_address,
                    related_subscription: data.related_subscription,
                    discounts: data.discounts,
                    loading: false
                });
            } else if (parseInt(results.response.code) === 4000) {
                history.push('/');
            } else {
                this.setState({
                    error_meg: results.response.message,
                    loading: false
                });
            }
        });
    }
    render() {
        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <div className="MyAccount-content">
                            <div className="d-flex align-items-center mobile_interchange justify-content-between">
                                <div className="mb_15 w-50">
                                    <h2 className="font_26 text_black fw_ar_bold mb_4 let_spa_2">#{this.state.order_id}</h2>
                                    <p className="font_12 fw_ar_reg items_text m-0">Order  placed on {this.state.created_at}and is currently {this.state.status}.</p>
                                </div>
                                <a href="/my-account/view-order" className="text_black font_16 fw_ar_reg text_underline">Go Back</a>
                            </div>
                            <div className="order_det_blk white_bg">
                                <Row>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={6}>
                                                <div className="billing_blk mb_25">
                                                    <h4 className="font_16 let_spa_2 text_black mb_8 fw_ar_bold">Billing address</h4>
                                                    <p className="bil_para font_16 text_black let_spa_2 mb_8 fw_ar_reg">{this.state.billing_address.hasOwnProperty('name') ? this.state.billing_address.name : ''} &nbsp;
                                                        {this.state.billing_address.hasOwnProperty('street_address') ? this.state.billing_address.street_address : ''} &nbsp;
                                                        {this.state.billing_address.hasOwnProperty('city') ? this.state.billing_address.city : ''} &nbsp;
                                                        {this.state.billing_address.hasOwnProperty('state') ? this.state.billing_address.state : ''} &nbsp;
                                                        {this.state.billing_address.hasOwnProperty('zip') ? this.state.billing_address.zip : ''}</p>
                                                    <div className="d-flex align-items-center mb_8">
                                                        <img src={PhoneIcon} alt="email_icon" />
                                                        <span className="font_16 text_black d-block pl_10 fw_ar_reg">{this.state.billing_address.hasOwnProperty('phone') ? this.state.billing_address.phone : ''}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb_8">
                                                        <img src={EmailIcon} alt="phone_icon" />
                                                        <span className="font_16 text_black d-block pl_10 fw_ar_reg">{this.state.billing_address.hasOwnProperty('email') ? this.state.billing_address.email : ''}</span>
                                                    </div>
                                                </div>
                                                <div className="billing_blk">
                                                    <h4 className="font_16 let_spa_2 text_black mb_8 fw_ar_bold">Shipping address</h4>
                                                    <p className="bil_para font_16 text_black let_spa_2 mb_8 fw_ar_reg">{this.state.shipping_address.hasOwnProperty('name') ? this.state.shipping_address.name : ''}&nbsp;
                                                        {this.state.shipping_address.hasOwnProperty('street_address') ? this.state.shipping_address.street_address : ''}&nbsp;
                                                        {this.state.shipping_address.hasOwnProperty('city') ? this.state.shipping_address.city : ''}&nbsp;
                                                        {this.state.shipping_address.hasOwnProperty('state') ? this.state.shipping_address.state : ''}&nbsp;
                                                        {this.state.shipping_address.hasOwnProperty('zip') ? this.state.shipping_address.zip : ''}</p>
                                                    <div className="d-flex align-items-center mb_8">
                                                        <img src={PhoneIcon} alt="email_icon" />
                                                        <span className="font_16 text_black d-block pl_10 fw_ar_reg">{this.state.billing_address.hasOwnProperty('phone') ? this.state.billing_address.phone : ''}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb_8">
                                                        <img src={EmailIcon} alt="phone_icon" />
                                                        <span className="font_16 text_black d-block pl_10 fw_ar_reg">{this.state.billing_address.hasOwnProperty('email') ? this.state.billing_address.email : ''}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <h4 className="font_16 text_black fw_ar_bold mb_8 let_spa_2 ">Payment Method</h4>
                                                <span className="d-block font_16 text_black fw_ar_reg let_spa_2">{this.state.payment_method}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <h4 className="font_16 text_black fw_ar_bold mb_8  let_spa_2 pay_top">Order Summary</h4>
                                        <div className="order_acc_body p-0 mb_23">
                                            {
                                                this.state.products.length <= 0 ? <tr><td className="text-center" colSpan="2">{this.state.error_meg}</td></tr> :
                                                    this.state.products.map(function (product, key) {
                                                        return (
                                                            <OrderedProductsList key={`op${key}`} product={product} />
                                                        )
                                                    }.bind(this))
                                            }

                                        </div>
                                        <div className="order_det_total">
                                            <ul className="pro_cart_det">
                                                <li className="d-flex align-items-center flex-wrap mb_7">
                                                    <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Sub Total</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.subtotal)}</span>
                                                </li>
                                                {
                                                    (this.state.discounts && this.state.discounts.length > 0) ?
                                                        this.state.discounts.map(function (discount, key) {
                                                            return (
                                                                <Fragment key={key}>
                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">{discount.label}</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(discount.amount)}</span>
                                                                    </li>
                                                                </Fragment>
                                                            )
                                                        }.bind(this))
                                                        : null
                                                }
                                                <li className="d-flex align-items-center flex-wrap mb_7">
                                                    <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Shipping</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.shipping_cost)}</span>
                                                </li>
                                                {
                                                    (this.state.taxStatus == 1) ?
                                                        <Fragment>
                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Tax</span>
                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.tax_amount)}</span>
                                                            </li>
                                                        </Fragment>
                                                        : null
                                                }
                                                <li className="d-flex align-items-center flex-wrap">
                                                    <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Total</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.total)}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <span className="d-block font_12 text_black fw_ar_reg font_italic">Note : Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span> */}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                }
            </Fragment>

        );
    }
}

export default ViewOrder;