import React, { PureComponent } from 'react';
import { API_KEY } from '../../Constants/AppConstants';
import history from "../../history";

import PropTypes from "prop-types";
import classnames from 'classnames';

import validatePasswordReset from '../../Validations/PasswordReset';
import FlashMessagesList from '../FlashMessages/FlashMessagesList';
import AlertWrapper from '../Common/AlertWrapper';

class PasswordResetForm extends PureComponent {
    constructor (props){
        super(props)
        this.state = {
            api_key: API_KEY,
            user_login: '',
            errors: {},
            isValid:false,
            isLoading:false,
            isFormValid:true,
            server_message:''
        }
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const val_return = validatePasswordReset(this.state);
        this.setState(val_return);
        if(val_return.isValid){
            this.setState({errors: {}, isLoading: true});
            this.props.passwordResetRequest(this.state).then
            (results => {
                if(results.response.code===1000) {
                    this.props.addFlashMessage({
                        type:"success",
                        text: results.response.message
                    });
                    this.setState({
                        isLoading:false
                    });	
                } else {                    
                    this.setState({
                        server_message: results.response.message,
                        isLoading:false,
                        isFormValid:false
                    });	
                }            
            }
            );
        }
    }

    changeUsernameHandler = (event) => {
        this.setState({ user_login: event.target.value }, this.validateInput);
      };

    validateInput = () => {
        const { user_login } = this.state;
        const pattern = /^[a-zA-Z0-9@._+-]*$/;
        const hasError = !pattern.test(user_login) || user_login.length > 50;
        this.setState({
          errors: {
            user_login: hasError
          }
        });
    };

    render() { 
        const { errors, server_message } = this.state;
        const errors_data = server_message;
        return (
            <div className="">
                <AlertWrapper errors_data={errors_data} isFormValid={this.state.isFormValid}/>
                <FlashMessagesList />
                <form method="post" className="add_new_card form_input_blk" onSubmit={this.onSubmit}>
                <p className='font_16 text_black mb_15 fw_ar_reg'>Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.</p>
                <div className="form-group">
                        <label className={classnames(null, { 'pl_error_label': errors.user_login })} htmlFor="user_login">Username or email <span className="required">*</span></label>
                        <input className={classnames("form-control", { 'pl_error_input': errors.user_login })} name="user_login" id="user_login" minLength="10" maxLength="50" onChange={this.changeUsernameHandler} autoComplete="username"/>
                        {errors.user_login && (
                        <small className="error-msg small" style={{ color: 'red', fontSize: '12px', fontFamily: 'Futura, sans-serif' }}>
                            Invalid input: Special characters are not allowed.
                        </small>
                        )}
                    </div>
                    <div className="mt_30 mb_15">
                        <button type="submit" disabled={this.state.isLoading} className=" cursor-pointer add_new_btn d-flex font_16 text_white fw_ar_reg w-100" value="Reset password">{this.state.isLoading?'Please Wait...':'Reset password'}</button>
                    </div>
                    <div className="d-flex justify-content-center">
                        <a href="/login" className="text_black font_14 fw_ar_reg text_underline">Back to Login</a>
                    </div>
                </form>
            </div>
        );
    }
}

PasswordResetForm.propTypes = {
    passwordResetRequest:PropTypes.func.isRequired,
    addFlashMessage:PropTypes.func.isRequired
}
 
export default PasswordResetForm;
