import React, { Fragment, PureComponent } from 'react';
import $ from "jquery";
import Parser from 'html-react-parser';
import { NavLink } from "react-router-dom";
import CartMonths from '../../Common/CartMonths';
import CartYears from '../../Common/CartYears';
import classnames from 'classnames';
import cardValidate from '../../../Validations/CardValidate';
import { AJAX_REQUEST, CRYPTO_ENCRYPTION } from "../../../Constants/AppConstants";

import AlertWrapper from '../../Common/AlertWrapper';
import AlertWrapperSuccess from '../../Common/AlertWrapperSuccess';
import VisaIcon from './../../../Assets/images/customer_images/visa_icon.svg'
import DeleteIcon from './../../../Assets/images/customer_images/delete_icon.svg'   
import { Row, Col, Card } from 'react-bootstrap';

class MyCards extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            showCardForm: false,
            errorMeg: '',
            myCards: [],

            name_on_card: '',
            card_number: '',
            expire_month: '',
            expire_year: '',
            cvv: '',

            // Validation
            action_btn_label: false,
            success_alert_wrapper_show: false,
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            server_message: ''
        }
        document.title = "My Cards -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        // document.getElementById("pageTitle").innerHTML = "My Cards";
        this.getMyCards();
    }

    timeOut = (timedata) => {
        setTimeout(function () {
            this.setState({
                success_alert_wrapper_show: false,
                server_message: '',
            });
        }.bind(this), timedata);
    }

    changeHandler = (e) => {
        if (e.target.name === 'name_on_card') {
            const name_on_card = e.target.value;
            this.setState({
                [e.target.name]: name_on_card.toUpperCase()
            })
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    showCardForm = (e) => {
        this.setState({ showCardForm: true })
    }

    getMyCards = () => {
        AJAX_REQUEST("POST", "customer/getSaveCardList", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    myCards: results.response.data,
                    errorMeg: results.response.message
                });
            } else {
                this.setState({
                    loading: false,
                    myCards: [],
                    errorMeg: results.response.message
                })
            }
        });
    }

    onSubmitHandler = (e) => {
        e.preventDefault();
        const data = {
            name_on_card: this.state.name_on_card,
            card_number: CRYPTO_ENCRYPTION(this.state.card_number),
            expire_month: this.state.expire_month,
            expire_year: this.state.expire_year,
            cvv: CRYPTO_ENCRYPTION(this.state.cvv),
        }
        const val_return = cardValidate(data);
        this.setState(val_return);

        if (val_return.isValid) {
            this.setState({ errors: {}, isLoading: true, action_btn_label: true });
            AJAX_REQUEST("POST", "customer/addNewCard", data).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    this.setState({ showCardForm: false })
                    this.getMyCards();
                    this.setState({
                        name_on_card: '',
                        card_number: '',
                        expire_month: '',
                        expire_year: '',
                        cvv: '',
                        server_message: results.response.message,
                        action_btn_label: false,
                        isLoading: false,
                        isFormValid: true,
                        success_alert_wrapper_show: true
                    });
                    document.getElementById("card_form").reset();
                    document.querySelector("body").scrollIntoView();
                    this.timeOut(5000);
                } else {
                    this.setState({
                        action_btn_label: false,
                        server_message: results.response.message,
                        isLoading: false,
                        isFormValid: false,
                        success_alert_wrapper_show: false
                    })
                    document.querySelector("body").scrollIntoView();
                }
            });
        } else {
            document.querySelector("body").scrollIntoView();
        }
    }

    deleteCart = (e, cartId = null, cartStatus) => {
        e.preventDefault();
        this.setState({
            success_alert_wrapper_show: false
        })
        let data = {
            card_id: cartId,
        }

        let alertMsg = "Are you sure you want delete this cart?"
        if (cartStatus == 'Active') {
            alertMsg = alertMsg + " " + "You have active subscription, Deleting this card will cause payment failure while subscription renewal.";
        }

        if (window.confirm(alertMsg)) {
            document.querySelector("body").scrollIntoView();
            AJAX_REQUEST("POST", "customer/deleteCard", data).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    this.getMyCards();
                    this.setState({
                        success_alert_wrapper_show: true,
                        server_message: results.response.message,
                    })
                    this.timeOut(5000);
                } else {
                    this.setState({
                        success_alert_wrapper_show: false,
                    })
                }
            });
        }

    }

    render() {

        const { errors, server_message, success_alert_wrapper_show } = this.state;

        return (
            <Fragment>
                <div className="">

                    <AlertWrapper errors_data={server_message} isFormValid={this.state.isFormValid} />
                    <AlertWrapperSuccess errors_data={server_message} success_alert_wrapper_show={success_alert_wrapper_show} />

                    {
                        (this.state.loading) ?
                            <div className="loading"></div>
                            :
                            <div className="woocommerce-MyAccount-content inner_content">
                                <h2 className="font_26 text_black fw_ar_bold let_spa_2 mb_13">My Card</h2>
                                <div className="card_blk">
                                    <Row className="card_row">
                                        {
                                            (this.state.myCards.length <= 0) ?
                                                <div className="text-center">{this.state.errorMeg}</div>
                                                :
                                                this.state.myCards.map(function (card, key) {
                                                    return (
                                                        <Fragment key={key}>
                                                            <Col md={4}>
                                                                <Card className="card_col">
                                                                    <div className="d-flex mb_18 align-items-center justify-content-between">
                                                                        <img src={VisaIcon} alt="visa Icon" />
                                                                        <span className="active d-flex align-items-center font_16 text_white let_spa_2 fw_ar_reg">{card.hasOwnProperty('status') ? card.status : ''}</span>
                                                                    </div>
                                                                    <h3 className="card_holder font_16 mb_5 text_black fw_ar_bold"> {card.hasOwnProperty('name_on_card') ? card.name_on_card : ''}</h3>
                                                                    <div className="d-flex align-items-end justify-content-between">
                                                                        <h3 className='font_16 text_black fw_ar_bold m-0'>{card.hasOwnProperty('card_number') ? card.card_number : ''}</h3>
                                                                        <div className="expiry_blk">
                                                                            <span className="d-block font_11 text_black fw_ar_reg">Expiry Date</span>
                                                                            <h3 className="m-0 font_16 text_black fw_ar_reg let_spa_53">{card.hasOwnProperty('expire_month') ? card.expire_month : ''}/{card.hasOwnProperty('expire_year') ? card.expire_year : ''}</h3>
                                                                        </div>
                                                                        <div className="card_delete">
                                                                            <NavLink onClick={(e) => this.deleteCart(e, card.card_id, card.status)} to="#" className="order_actions_edit d-flex"> <img src={DeleteIcon} alt="deleteIcon" /></NavLink>
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </Col>
                                                        </Fragment>
                                                    )
                                                }.bind(this))
                                        }
                                    </Row>
                                    <div className="mt_42 d-flex justify-content-center">
                                        <span onClick={this.showCardForm} className="cursor-pointer add_new_btn d-flex font_16 text_white fw_ar_reg">Add New</span>
                                    </div>
                                </div>
                                <Fragment>
                                    <div style={{ display: (!this.state.showCardForm) ? "none" : "" }} className="mt_30 form_input_blk add_new_card_form add_new_card">
                                        <h3 className="font_16 text_black fw_ar_bold mb_18">SAVE CARDS</h3>
                                        <form onSubmit={this.onSubmitHandler} id="card_form">
                                            <Row>
                                                <Col md={6}>
                                                    <div className="form-group">
                                                        <label>Name On Card <span className="required">*</span></label>
                                                        <input onChange={this.changeHandler} value={this.state.name_on_card} type="text" className={classnames("form-control", { 'pl_error_input': errors.name_on_card })} name="name_on_card" />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="form-group">
                                                        <label>Credit Card Number <span className="required">*</span></label>
                                                        <input onChange={this.changeHandler} value={this.state.card_number} type="text" className={classnames("form-control", { 'pl_error_input': errors.card_number })} name="card_number" maxLength="16" autoComplete="off" />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="form-group">
                                                        <label>Expiration Date <span className="required">*</span></label>
                                                        <Row>
                                                            <Col md={6}>
                                                                <select onChange={this.changeHandler} className={classnames("pull-left form-control", { 'pl_error_input': errors.expire_month })} name="expire_month" >
                                                                    <option value="">Month</option>
                                                                    <CartMonths />
                                                                </select>
                                                            </Col>
                                                            <Col md={6}>
                                                                <select onChange={this.changeHandler} className={classnames("pull-right form-control", { 'pl_error_input': errors.expire_year })} name="expire_year" >
                                                                    <option value="">Year</option>
                                                                    <CartYears />
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="form-group">
                                                        <label>CVV <span className="required">*</span></label>
                                                        <input onChange={this.changeHandler} value={this.state.cvv} type="text" className={classnames("form-control", { 'pl_error_input': errors.cvv })} name="cvv" maxLength="4" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className='text_default font_12 fw_ar_reg font_italic mb_18'> To verify the new card, USD 1.00 will be charged temporarily and will be refunded within 3-7 working days. </p>
                                            <div className="mt_30">
                                                <button type="submit" className="cursor-pointer add_new_btn d-flex font_16 text_white fw_ar_reg" name="save" value="save">{this.state.action_btn_label ? "Saving..." : "Save Card"}</button>
                                            </div>
                                        </form>
                                    </div>
                                </Fragment>
                            </div>
                    }
                </div>
            </Fragment>
        );
    }
}

export default MyCards;