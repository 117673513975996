import React, { Fragment, PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import $ from "jquery";
import { connect } from 'react-redux';
import {
    CRYPTO_ENCRYPTION,
    AJAX_REQUEST,
    AJAX_ACCOUNT_KIT_REQUEST,
    CURRENCY_FORMAT,
    CART_TOTAL_CURRENCY_FORMAT,
    COUNT_SUBSCRIPTION,
    SET_STORAGE,
    GET_STORAGE,
    REMOVE_STORAGE,
    CHECK_STORAGE,
    DESTROY_CART,
    NEXT_MONTH,
    NEXT_WEEK,
    MEAL_SUB_TOTAL,
    MEAL_TOTAL,
    CART_SUB_TOTAL,
    RECURRING_CART_SUB_TOTAL,
    COUPON_TOTAL,
    ITEM_COUNT,
    MEAL_COUNT,
    CanadaRestrictedProduct,
    AJAX_PUBLIC_REQUEST,
    BUNDLE_COUNT, BUNDLE_SUB_TOTAL, BUNDLE_OFFER_NEW, BUNDLE_CART_COUNT
} from "../../Constants/AppConstants";
import PropTypes from "prop-types";
import classnames from 'classnames';
import history from "../../history";
import Parser from 'html-react-parser';

import AccountKit from 'react-facebook-account-kit';

import { checkoutRequest } from '../../Store/actions/checkoutActions';
import checkoutValidate from '../../Validations/CheckoutValidate';
import AlertWrapper from '../Common/AlertWrapper';
import AlertWrapperSuccess from '../Common/AlertWrapperSuccess';
import CartMonths from '../Common/CartMonths';
import CartYears from '../Common/CartYears';
import PreventCheckoutModal from './PreventCheckoutModal';
import { Row, Col } from 'react-bootstrap';
import LeftArrow from '../../Assets/images/refer_images/left_arrow.svg'
import BackArrow from '../../Assets/images/refer_images/back_arrow.svg'
import CheckoutLogo from '../../Assets/images/refer_images/checkout_page_logo.svg'
import CartSummaryIcon from '../../Assets/images/refer_images/cart_summary_icon.svg'
import AmazonIcon from '../../Assets/images/refer_images/amazon_pay.svg'
import SezzleIcon from '../../Assets/images/refer_images/sezzle_icon.svg'
import CreditCard from '../../Assets/images/refer_images/credit_card_img.svg'
import PayPalIcon from '../../Assets/images/refer_images/paypal_img.svg'
// import ShippingSchedule from './Meals/ShippingSchedule';

class CheckoutPayment extends PureComponent {
    constructor(props) {
        super(props);

        // Check Tax
        let settings = '';
        if (GET_STORAGE('settings')) {
            settings = JSON.parse(GET_STORAGE('settings'));
        }

        let billing_data = '';
        if (JSON.parse(GET_STORAGE('billing_data'))) {
            billing_data = JSON.parse(GET_STORAGE('billing_data'));
        }

        const { user } = this.props.auth;

        this.state = {
            loading: true,
            error: '',
            affiliateErrorMessage: '',
            stockoutErrorMessage: '',
            subscriptionProductErrorMessage: '',
            terms_of_use: settings ? settings.internal_pages.terms_of_use : "/",
            privacy_policy: settings ? settings.internal_pages.privacy_policy : "/",

            payment_data: billing_data,
            billing_email_id: billing_data ? (billing_data.billing_email ? billing_data.billing_email : '') : "",
            billing_address_first: billing_data ? (billing_data.billing_address_1 ? billing_data.billing_address_1 : '') : "",
            billing_address_second: billing_data ? (billing_data.billing_address_2 ? billing_data.billing_address_2 : '') : "",
            billing_city_1: billing_data ? (billing_data.billing_city ? billing_data.billing_city : '') : "",
            billing_state_1: billing_data ? (billing_data.billing_state ? billing_data.billing_state : '') : "",
            billing_postcode_1: billing_data ? (billing_data.billing_postcode ? billing_data.billing_postcode : '') : "",
            billing_nation: billing_data ? (billing_data.billing_country ? billing_data.billing_country : '') : "",

            shipping_nation: billing_data ? (billing_data.shipping_country ? billing_data.shipping_country : '') : "",
            shipping_address_first: billing_data ? (billing_data.shipping_address_1 ? billing_data.shipping_address_1 : '') : "",
            shipping_address_second: billing_data ? (billing_data.shipping_address_2 ? billing_data.shipping_address_2 : '') : "",
            shipping_city_2: billing_data ? (billing_data.shipping_city ? billing_data.shipping_city : '') : "",
            shipping_state_2: billing_data ? (billing_data.shipping_state ? billing_data.shipping_state : '') : "",
            shipping_postcode_2: billing_data ? (billing_data.shipping_postcode ? billing_data.shipping_postcode : '') : "",

            // Facebook mobile verification
            phone_verification_on_checkout: user ? user.sms_verification_on_checkout : '',
            facebook_app_id: settings ? settings.facebook_app_id : '',
            account_kit_app_secret: settings ? settings.account_kit_app_secret : '',
            account_kit_api_version: settings ? settings.account_kit_api_version : '',
            // Check team member
            sales_agent_feature: user ? user.sales_agent_feature : '',

            // Check existing customer
            haveExistsUser: 1,
            password_check: 0,
            password: '',
            confirm_password: '',

            cartShippingCost: 0,
            recurringCartShippingCost: 0,
            cartTotal: 0,
            mealShippingCostStatus: true,

            taxStatus: settings ? settings.tax_status : 0,
            tax_amount: 0,
            tax_info: '',
            subscription_tax_amount: 0,
            subscription_tax_info: '',
            meal_tax_amount: 0,
            meal_tax_info: '',
            subscription_meal_tax_amount: 0,
            subscription_meal_tax_info: '',

            items: [],
            meals: [],
            coupons: [],
            shippingMethods: [],
            countryList: [],
            billingStateList: [],
            shippingStateList: [],
            teamMemberList: [],
            freeShipping: 0,
            taxFree: 0,
            show_coupon: false,
            coupon_code_button_loading: false,
            coupon_code: "",
            success_alert_wrapper_show: false,
            place_order_loading: false,

            // Billing Details
            billing_first_name: '',
            billing_last_name: '',
            billing_company: '',
            billing_country: '',
            billing_address_1: '',
            billing_address_2: '',
            billing_city: '',
            billing_state: '',
            billing_postcode: '',
            billing_phone: '',
            billing_email: '',

            // Address verify
            addressVerifyCode: false,
            continue_without_verify: 0,
            agree_to_receive_text: 'yes',
            send_promotion_update: 'no',
            team_member_id: '',

            // Different Shipping Address
            shippingAreaVisible: false,
            ship_to_different_address: 'no',

            shipping_first_name: '',
            shipping_last_name: '',
            shipping_company: '',
            shipping_country: '',
            shipping_address_1: '',
            shipping_address_2: '',
            shipping_city: '',
            shipping_state: '',
            shipping_postcode: '',

            order_note: '',
            session_id: '',
            force_order: 0,

            // Payment Method
            payment_method: (settings.payment_method) ? settings.payment_method : '',
            name_on_card: '',
            card_number: '',
            expire_month: '',
            expire_year: '',
            cvv: '',
            agreeTermsPolicy: false,

            // Validation
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            server_message: '',
            isCouponReapply: 0,

            //warning state
            productWarnings: [],
            warningProductCartKeys: [],
            authorize_state : false,
            hasSubscriptionYes: false,
            hasSubscriptionNo: false,
            bundleItems: [],
            subscription: GET_STORAGE("is_subscription"),
            subscription_detail: JSON.parse(GET_STORAGE("CustomSubscriptionDetail"))
        }
        document.title = "CheckOut-Prestige Labs";

    }

    changeHandler = (e) => {
        if (e.target.name === 'name_on_card') {
            const name_on_card = e.target.value;
            this.setState({
                [e.target.name]: name_on_card.toUpperCase()
            })
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }

    }

    changeHandlerWithCallTax = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.getTax(this.state.ship_to_different_address);

        if (this.state.ship_to_different_address === 'checked') {
            if (e.target.name === 'shipping_state') {
                this.warningModalHandel(this.state.shipping_nation, e.target.value);
            }
        } else if (e.target.name === 'billing_state') {
            this.warningModalHandel(this.state.billing_nation, e.target.value);
        }
    }

    warningModalCancelHandel = (e) => {
        //console.log('warningModalCancelHandel click: ', this.state.warningProductCartKeys);
        const productKeys = (this.state.warningProductCartKeys) ? this.state.warningProductCartKeys : [];
        if (productKeys.length > 0) {
            let cartItems = JSON.parse(GET_STORAGE("cart"));

            let newCart = [];
            if (cartItems.length > 0) {
                cartItems.map(function (item, productKey) {
                    if (productKeys.includes(productKey)) {
                        //console.log('cart item found: ', item);
                    } else {
                        newCart.push(item);
                    }
                })
            }

            //console.log('warningModalCancelHandel newCart: ', newCart);


            SET_STORAGE("cart", JSON.stringify(newCart));

            setTimeout(() => {
                $('#warningModal').modal('hide');
                //redirect to card page
                history.push("/cart");
            }, 100);

        }
    }
    warningModalHandel = (country, state) => {
        AJAX_PUBLIC_REQUEST("POST", "user/getWarnings", {}).then(results => {
            // console.log('cartItems results: ', results);

            if (parseInt(results.response.code) === 1000) {
                const warnings = (results.response.data) ? results.response.data : [];

                if (warnings.length > 0) {
                    let warningContents = '';
                    let warningProductKeys = [];
                    const cartItems = JSON.parse(GET_STORAGE("cart"));

                    if (cartItems.length > 0) {
                        cartItems.map(function (item, productKey) {
                            //console.log('cartItems item: ', item);
                            let productWarnings = '';
                            let cartProductId = item.cart_product_id;
                            let cartVariationId = item.cart_variation_id;
                            warnings.map(function (warning, key) {
                                //console.log('warning: ', warning);
                                //console.log('warning locations: ', warning.locations);
                                if (warning.status === 'active') {
                                    //check location
                                    if (warning.targeted_location === 'yes') {
                                        const locations = warning.locations;
                                        const locationStates = locations[country] ? locations[country] : [];
                                        if (locationStates.length > 0) {
                                            if (locationStates.includes(state)) {
                                                if (warning.targeted_location_type === 'show') {
                                                    //console.log('state found: ', state);
                                                    //check product
                                                    if (warning.targeted_product_type === 'all') {
                                                        if (productWarnings.length === 0) {
                                                            warningProductKeys.push(productKey);
                                                            productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                        }
                                                        productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                    } else if (warning.targeted_product_type === 'product') {//variation wise check
                                                        const productsIds = warning.products_ids;
                                                        if (productsIds.length > 0) {
                                                            if (productsIds.includes(parseInt(cartProductId))) {
                                                                if (productWarnings.length === 0) {
                                                                    warningProductKeys.push(productKey);
                                                                    productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                                }
                                                                productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                            }
                                                        }
                                                    } else if (warning.targeted_product_type === 'variation') {//variation wise check
                                                        const productsIds = warning.products_ids;
                                                        if (productsIds.length > 0) {
                                                            if (productsIds.includes(parseInt(cartVariationId))) {
                                                                if (productWarnings.length === 0) {
                                                                    warningProductKeys.push(productKey);
                                                                    productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                                }
                                                                productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                            }
                                                        }
                                                    }
                                                }
                                            } else { //not include
                                                if (warning.targeted_location_type === 'dont_show') {
                                                    //check product
                                                    if (warning.targeted_product_type === 'all') {
                                                        if (productWarnings.length === 0) {
                                                            warningProductKeys.push(productKey);
                                                            productWarnings += '<div class="d-flex align-items-center justify-content-center"><div class="d-flex flex-column align-items-start justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                        }
                                                        productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                    } else if (warning.targeted_product_type === 'product') {//variation wise check
                                                        const productsIds = warning.products_ids;
                                                        if (productsIds.length > 0) {
                                                            if (productsIds.includes(parseInt(cartProductId))) {
                                                                if (productWarnings.length === 0) {
                                                                    warningProductKeys.push(productKey);
                                                                    productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                                }
                                                                productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                            }
                                                        }
                                                    } else if (warning.targeted_product_type === 'variation') {//variation wise check
                                                        const productsIds = warning.products_ids;
                                                        if (productsIds.length > 0) {
                                                            if (productsIds.includes(parseInt(cartVariationId))) {
                                                                if (productWarnings.length === 0) {
                                                                    warningProductKeys.push(productKey);
                                                                    productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                                }
                                                                productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        //console.log('billing_country: ', country);
                                        //console.log('locations: ', locations[country]);
                                    } else {
                                        //check product
                                        if (warning.targeted_product_type === 'all') {
                                            if (productWarnings.length === 0) {
                                                warningProductKeys.push(productKey);
                                                productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                            }
                                            productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                        } else if (warning.targeted_product_type === 'product') {//variation wise check
                                            const productsIds = warning.products_ids;
                                            if (productsIds.length > 0) {
                                                if (productsIds.includes(parseInt(cartProductId))) {
                                                    if (productWarnings.length === 0) {
                                                        warningProductKeys.push(productKey);
                                                        productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                    }
                                                    productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                }
                                            }
                                        } else if (warning.targeted_product_type === 'variation') {//variation wise check
                                            const productsIds = warning.products_ids;
                                            if (productsIds.length > 0) {
                                                if (productsIds.includes(parseInt(cartVariationId))) {
                                                    if (productWarnings.length === 0) {
                                                        warningProductKeys.push(productKey);
                                                        productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                    }
                                                    productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                }
                                            }
                                        }
                                    }

                                }
                            });
                            if (productWarnings.length > 0) {
                                warningContents += productWarnings + '</div></div>';
                            }
                        });
                    }

                    //console.log('warningContents: ', warningContents);

                    if (warningContents.length > 0) {
                        this.setState({ warningProductCartKeys: warningProductKeys });

                        $("#warningModal").modal({
                            backdrop: 'static',
                            keyboard: false,
                        });
                        //let modalContent = '<div class="mt-2 warning-block-cards">'+warningContents+'</div>';
                        //let modalContent = '<div class="modal-header cus-modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close" style="border: 0px;background: transparent;font-size: 25px;right: 0px;top: -5px;cursor: pointer"><span aria-hidden="true">&times;</span></button></div><div class="modal-body montserrat" style="background: '+this.state.warnings[0].design.background+'"><div class="py-3 d-flex align-items-center justify-content-center"><img src="'+this.state.warnings[0].design.icon+'" width="80px"></div><div class="py-3 px-3 text-center">'+this.state.warnings[0].messages+'</div><div class="d-flex align-items-center justify-content-center py-3"><button class="btn mr-4" style="background:'+this.state.warnings[0].buttons.cancel.background+'">Cancel</button><button class="btn btn-s">OK</button></div></div>';
                        //let modalContent = '<div class="modal-header cus-modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close" style="border: 0px;background: transparent;font-size: 25px;right: 0px;top: -5px;cursor: pointer"><span aria-hidden="true">&times;</span></button></div><div class="modal-body montserrat" style="background: '+this.state.warnings[0].design.background+'"><div class="py-3 px-3 d-flex justify-content-center align-items-start"><div class="mr-3"><img src="'+this.state.warnings[0].design.icon+'" width="80px"></div><div class="text-center">'+this.state.warnings[0].messages+'</div></div><div class="d-flex align-items-center justify-content-center py-3"><button class="btn mr-4" style="background:'+this.state.warnings[0].buttons.cancel.background+'">Cancel</button><button class="btn btn-s">OK</button></div></div>';
                        //let modalContent = '<div class="modal-header cus-modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close" style="border: 0px;background: transparent;font-size: 25px;right: 0px;top: -5px;cursor: pointer"><span aria-hidden="true">&times;</span></button></div><div class="modal-body montserrat" style="background: '+this.state.warnings[0].design.background+'"><div class="py-3 px-3 d-flex justify-content-center align-items-start"><div class="text-center">'+this.state.warnings[0].messages+'</div><div class="ml-3"><img src="'+this.state.warnings[0].design.icon+'" width="80px"></div></div><div class="d-flex align-items-center justify-content-center py-3"><button class="btn mr-4" style="background:'+this.state.warnings[0].buttons.cancel.background+'">Cancel</button><button class="btn btn-s">OK</button></div></div>';
                        //let modalContent = '<div class="modal-header cus-modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close" style="border: 0px;background: transparent;font-size: 25px;right: 0px;top: -5px;cursor: pointer"><span aria-hidden="true">&times;</span></button></div><div class="modal-body montserrat" style="background: '+this.state.warnings[0].design.background+'"><div class="py-3 px-3 text-center">'+this.state.warnings[0].messages+'</div><div class="pt-2 pb-3 d-flex align-items-center justify-content-center"><img src="'+this.state.warnings[0].design.icon+'" width="80px"></div><div class="d-flex align-items-center justify-content-center py-3"><button class="btn mr-4" style="background:'+this.state.warnings[0].buttons.cancel.background+'">Cancel</button><button class="btn btn-success">OK</button></div></div>';
                        $("#warningModalContent").html(warningContents);
                    }
                }
            }
        });
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        //this.reApplyCoupon();
        this.getCart();
        this.getAllShippingMethods();
        this.getCountryList();
        const { user } = this.props.auth;
        if (user.sales_agent_feature == "enable") {
            this.getTeamMemberList();
        }
        this.getBillingAddress();
        this.getShippingAddress();
        const cartItems = this.state.payment_data.cart_items;
        cartItems.forEach(item => {
            if (item.subscription === "yes") {
                this.state.hasSubscriptionYes = true;
            } 
            if (item.subscription === "no") {
                this.state.hasSubscriptionNo = true;
            }
        });
        if((BUNDLE_CART_COUNT() != 0 ) && GET_STORAGE("is_subscription")){
            this.state.hasSubscriptionYes = (GET_STORAGE("is_subscription") == "yes") ? true : false;
        }
    }

    // Get and calculate tax if applicable
    getTax = (checkStatus = 'no') => {

        this.setState({
            tax_amount: 0,
            tax_info: '',
            subscription_tax_amount: 0,
            subscription_tax_info: '',
            meal_tax_amount: 0,
            meal_tax_info: '',
            subscription_meal_tax_amount: 0,
            subscription_meal_tax_info: '',
        })

        if (this.state.taxStatus == 1) {
            let address_1 = '';
            let postcode = '';
            let city = '';
            let state = '';
            let country = '';
            if (checkStatus == "checked") {
                address_1 = this.state.shipping_address_first;;
                postcode = this.state.shipping_postcode_2;
                city = this.state.shipping_city_2;
                state = this.state.shipping_state_2;
                country = this.state.shipping_nation;
            } else {
                address_1 = this.state.billing_address_first;
                postcode = this.state.billing_postcode_1;
                city = this.state.billing_city_1;
                state = this.state.billing_state_1;
                country = this.state.billing_nation;
            }

            if (address_1 != '' && postcode != '' && city != '' && state != '' && country != '') {

                let taxData = {
                    address_1: address_1,
                    postcode: postcode,
                    city: city,
                    state: state,
                    country: country,
                    shipping_method_id: this.state.shipping_method_id,
                    recurring_shipping_method_id: this.state.recurring_shipping_method_id,
                    cart_items: JSON.parse(GET_STORAGE("cart")), // set shoping carts
                    meals: JSON.parse(GET_STORAGE("meals")), // set meals
                    cart_coupons: JSON.parse(GET_STORAGE("coupon")),
                }
                //TaxJar ping adjustment
                if(this.state.isCouponReapply == 1) {
                    AJAX_PUBLIC_REQUEST("POST", "order/getTax", taxData).then(results => {
                        if (parseInt(results.response.code) === 1000 && results.response.data != '') {
                            let data = results.response.data;
                            this.setState({
                                tax_amount: (data.tax_amount) ? data.tax_amount : 0,
                                tax_info: data.tax_info,
                                subscription_tax_amount: (data.subscription_tax_amount) ? data.subscription_tax_amount : 0,
                                subscription_tax_info: data.subscription_tax_info,
                                meal_tax_amount: (data.meal_tax_amount) ? data.meal_tax_amount : 0,
                                meal_tax_info: data.meal_tax_info,
                                subscription_meal_tax_amount: (data.subscription_meal_tax_amount) ? data.subscription_meal_tax_amount : 0,
                                subscription_meal_tax_info: data.subscription_meal_tax_info,
                            });
                        }
                    });
                } else {
                    let data = JSON.parse(GET_STORAGE("taxInfo"));
                    this.setState({
                        tax_amount: (data.tax_amount) ? data.tax_amount : 0,
                        tax_info: data.tax_info,
                        subscription_tax_amount: (data.subscription_tax_amount) ? data.subscription_tax_amount : 0,
                        subscription_tax_info: data.subscription_tax_info,
                        meal_tax_amount: (data.meal_tax_amount) ? data.meal_tax_amount : 0,
                        meal_tax_info: data.meal_tax_info,
                        subscription_meal_tax_amount: (data.subscription_meal_tax_amount) ? data.subscription_meal_tax_amount : 0,
                        subscription_meal_tax_info: data.subscription_meal_tax_info,
                    }); 
                }
            }
        }
    }

    timeOut = (timedata) => {
        setTimeout(function () {
            this.setState({
                success_alert_wrapper_show: false,
                server_message: ''
            });
        }.bind(this), timedata);
    }

    getCart = () => {
        CHECK_STORAGE();
        if (GET_STORAGE('cart')) {
            this.setState({ items: JSON.parse(GET_STORAGE('cart')) });
        } else {
            this.setState({ items: [] });
        }
        if (GET_STORAGE('meals')) {
            let meals = JSON.parse(GET_STORAGE('meals'));
            if (meals.hasOwnProperty('items') && meals.items.length > 0) {
                this.setState({ meals: meals });
            } else {
                REMOVE_STORAGE('meals');
                this.setState({ meals: [] });
            }
        } else {
            this.setState({ meals: [] });
        }
        if (GET_STORAGE('coupon')) {
            this.setState({ coupons: JSON.parse(GET_STORAGE("coupon")) });
        } else {
            this.setState({ coupons: [] });
        }
        if (GET_STORAGE('BundleCart')) {
            this.setState({ bundleItems: JSON.parse(GET_STORAGE('BundleCart')) });
        } else {
            this.setState({ bundleItems: [] });
        }
    }

    getAllShippingMethods = () => {
        AJAX_REQUEST("POST", "order/getShippingMethodList", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                let methods = results.response.data;
                let method = methods.filter(c => c.id === GET_STORAGE("cartMethodId"));
                let method2 = methods.filter(c => c.id === GET_STORAGE("recurringCartMethodId"));

                if (this.state.shipping_method_id) {
                    if (this.state.shipping_method_id != GET_STORAGE("cartMethodId")) {
                        SET_STORAGE("cartMethodId", methods[0].id);
                    }
                }

                this.setState({
                    shippingMethods: methods,
                    shipping_method_id: GET_STORAGE("cartMethodId"),
                    // cartShippingCost: (this.state.shipping_method_id != GET_STORAGE("cartMethodId")) ? method[0].cost : methods[0].cost,
                    cartShippingCost: (GET_STORAGE("cartMethodId")) ? method[0].cost : methods[0].cost,
                });
                if (method2) {
                    this.setState({
                        recurring_shipping_method_id: GET_STORAGE("recurringCartMethodId"),
                        recurringCartShippingCost: method2[0].cost,
                    });
                }
            } else {
                this.setState({
                    error: results.response.message,
                })
            }
        });
    }

    getBillingAddress = () => {
        AJAX_REQUEST("POST", "user/billingDetails", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    billing_first_name: results.response.data.billing_first_name,
                    billing_last_name: results.response.data.billing_last_name,
                    billing_company: results.response.data.billing_company,
                    billing_address_1: results.response.data.billing_address_1,
                    billing_address_2: results.response.data.billing_address_2,
                    billing_city: results.response.data.billing_city,
                    billing_postcode: results.response.data.billing_postcode,
                    billing_country: results.response.data.billing_country_id,
                    billing_state: results.response.data.billing_state,
                    billing_phone: results.response.data.billing_phone,
                    billing_email: results.response.data.billing_email
                });
                if (parseInt(results.response.data.billing_country_id) == 38 && CanadaRestrictedProduct()) {
                    $("#canadaRestrictedProductModal").modal({
                        backdrop: 'static',
                        keyboard: false,
                    });
                    $("#canadaRestrictedProductModalBody").html(CanadaRestrictedProduct());
                    this.setState({
                        billing_country: '',
                        billing_state: '',
                        billingStateList: [],
                    })
                }

                setTimeout(() => {
                    //load warning
                    //this.warningModalHandel(this.state.billing_country, this.state.billing_state);
                    this.getBillingStateList(this.state.billing_country);
                }, 100);
            } else {
                this.setState({
                    loading: false,
                    error_meg: results.response.message,
                })
            }
        });
    }

    getShippingAddress = () => {
        AJAX_REQUEST("POST", "user/shippingDetails", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    shipping_first_name: results.response.data.shipping_first_name,
                    shipping_last_name: results.response.data.shipping_last_name,
                    shipping_company: results.response.data.shipping_company,
                    shipping_address_1: results.response.data.shipping_address_1,
                    shipping_address_2: results.response.data.shipping_address_2,
                    shipping_city: results.response.data.shipping_city,
                    shipping_postcode: results.response.data.shipping_postcode,
                    shipping_country: results.response.data.shipping_country_id,
                    shipping_state: results.response.data.shipping_state,
                });

                setTimeout(() => {
                    this.getShippingStateList(this.state.shipping_country);
                }, 100);

            } else {
                this.setState({
                    loading: false,
                    error_meg: results.response.message,
                })
            }
        });
    }

    getCountryList = () => {
        AJAX_REQUEST("POST", "user/getCountry", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    countryList: results.response.data
                });
            } else {
                this.setState({
                    loading: false,
                    error: results.response.message,
                })
            }
        });
    }

    onChangeBillingCountry = (e) => {
        let countryId = e.target.value;

        if (!document.getElementById("ship_to_different_address").checked && parseInt(countryId) == 38 && CanadaRestrictedProduct()) {
            $("#canadaRestrictedProductModal").modal({
                backdrop: 'static',
                keyboard: false,
            });
            $("#canadaRestrictedProductModalBody").html(CanadaRestrictedProduct());
        } else {
            this.setState({ [e.target.name]: countryId })
            this.getBillingStateList(countryId);
        }
    }
    getBillingStateList = (countryId) => {
        let data = { country_id: countryId };
        AJAX_PUBLIC_REQUEST("POST", "user/getState", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({ billingStateList: results.response.data });
            } else {
                this.setState({ billingStateList: [] })
            }

            setTimeout(() => {
                this.getTax();
            }, 100);
        });
    }

    onChangeShippingCountry = (e) => {
        let countryId = e.target.value;

        if (document.getElementById("ship_to_different_address").checked && parseInt(countryId) == 38 && CanadaRestrictedProduct()) {
            $("#canadaRestrictedProductModal").modal({
                backdrop: 'static',
                keyboard: false,
            });
            $("#canadaRestrictedProductModalBody").html(CanadaRestrictedProduct());
        } else {
            this.setState({ [e.target.name]: countryId })
            this.getShippingStateList(countryId);
        }
    }
    getShippingStateList = (countryId) => {
        let data = { country_id: countryId };
        AJAX_PUBLIC_REQUEST("POST", "user/getState", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({ shippingStateList: results.response.data });
            } else {
                this.setState({ shippingStateList: [] })
            }
            setTimeout(() => {
                this.getTax();
            }, 100);
        });
    }

    changeCartShippingMethod = (e) => {
        this.setState({
            shipping_method_id: e.target.getAttribute('cid'),
            cartShippingCost: e.target.getAttribute('price'),
        })
        SET_STORAGE("cartMethodId", e.target.getAttribute('cid'))
    }
    changeRecurringCartShippingMethod = (e) => {
        this.setState({
            recurring_shipping_method_id: e.target.getAttribute('rid'),
            recurringCartShippingCost: e.target.getAttribute('price'),
        })
        SET_STORAGE("recurringCartMethodId", e.target.getAttribute('rid'))
    }

    continueWithoutVerify = (e) => {
        if (document.getElementById("continue_without_verify").checked) {
            this.setState({ continue_without_verify: 1 });
        } else {
            this.setState({ continue_without_verify: 0 });
        }
    }

    isDifferentShipping = (e) => {
        if (document.getElementById("ship_to_different_address").checked) {
            this.setState({
                ship_to_different_address: 'checked',
                shippingAreaVisible: true,
            });
            this.getTax("checked");
        } else {
            this.setState({
                ship_to_different_address: 'no',
                shippingAreaVisible: false,
                shipping_first_name: '',
                shipping_last_name: '',
                shipping_company: '',
                shipping_country: '',
                shipping_address_1: '',
                shipping_address_2: '',
                shipping_city: '',
                shipping_state: '',
                shipping_postcode: '',
            });
            if (CanadaRestrictedProduct()) {
                this.setState({
                    billing_country: '',
                    billing_state: '',
                    billingStateList: [],
                })
            }
            this.getTax();
            //load warning
            if (this.state.billing_state) {
                this.warningModalHandel(this.state.billing_country, this.state.billing_state);
            }
        }
    }
    agreeTermsPolicy = (e) => {
        if (document.getElementById("agreeTermsPolicy").checked) {
            this.setState({ agreeTermsPolicy: true });
        } else {
            this.setState({ agreeTermsPolicy: false });
        }
    }
    sendPromotionUpdate = (e) => {
        if (document.getElementById("send_promotion_update").checked) {
            this.setState({ send_promotion_update: "yes" });
        } else {
            this.setState({ send_promotion_update: "no" });
        }
    }

    getTeamMemberList = () => {
        AJAX_REQUEST("POST", "distributor/getTeamMemberListOption", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({ teamMemberList: results.response.data.team_members })
            } else {
                this.setState({ error: results.response.message })
            }
        });
    }

    onSubmitHandler = (e, forceOrderValue = 0) => {
        e.preventDefault();

        this.setState({ errors: {}, isLoading: true, place_order_loading: true });

        // Payment Method
        let billing_card_info = this.state.payment_data;
        billing_card_info.cart_coupons = JSON.parse(GET_STORAGE("coupon"));
        billing_card_info.payment_method = this.state.cartTotal > 0 ? document.querySelector(".select_payment:checked").value : '';
        if(billing_card_info.payment_method === 'authorize'){
            this.state.authorize_state = true;
        }
        billing_card_info.name_on_card = this.state.name_on_card;
        billing_card_info.expire_month = this.state.expire_month;
        billing_card_info.expire_year = this.state.expire_year;
        billing_card_info.card_number = CRYPTO_ENCRYPTION(this.state.card_number);
        billing_card_info.cvv = CRYPTO_ENCRYPTION(this.state.cvv);
        billing_card_info.force_order = (this.state.force_order == 1) ? 1 : forceOrderValue;
        billing_card_info.is_custom_bundle = (BUNDLE_CART_COUNT() != 0 ) ? 1 : 0;
        billing_card_info.custom_discount = (BUNDLE_CART_COUNT() != 0 ) ?  BUNDLE_OFFER_NEW() : 0;
        const val_return = checkoutValidate(billing_card_info);
        billing_card_info.is_california_warning = (GET_STORAGE("california-warning") == 'yes') ? GET_STORAGE("california-warning"): 'no';
        billing_card_info.is_ny_order = (GET_STORAGE("ny-order") == 'yes') ? GET_STORAGE("ny-order"): 'no';
        billing_card_info.custom_bundle_subscription = (BUNDLE_CART_COUNT() != 0 ) ? GET_STORAGE("is_subscription") : 0;
        this.setState(val_return);


        if (val_return.isValid) {

            this.props.checkoutRequest(billing_card_info).then(results => {

                // Check existing affiliate email for order
                if (parseInt(results.response.code) === 3003) {

                    this.setState({
                        isLoading: false,
                        place_order_loading: false,
                        isFormValid: false,
                        affiliateErrorMessage: results.response.message,
                    });

                    $("#affiliateErrorModal").modal({
                        backdrop: 'static',
                        keyboard: false,
                    });

                } else if (parseInt(results.response.code) === 200) {
                    window.location = results.response.data.checkout_url;

                }
                else if (parseInt(results.response.code) === 300) {
                    console.log("Payapal Checkout URL", results.response.checkoutUrl);
                    console.log("Payapal excecutionUrl URL", results.response.excecutionUrl);
                    console.log("order_data", results.response.order_id);
                    console.log("id", results.response);
                    SET_STORAGE('paypal_order_id', results.response.paypal_order_id);
                    SET_STORAGE('order_id', results.response.order_id);
                    SET_STORAGE('id', results.response.id);
                    window.location = results.response.checkoutUrl;
                    // Check product stockout
                }
                // Check product stockout
                else if (parseInt(results.response.code) === 3004) {

                    this.setState({
                        isLoading: false,
                        place_order_loading: false,
                        isFormValid: false,
                        stockoutErrorMessage: results.response.message,
                    });

                    $("#stockoutErrorModal").modal({
                        backdrop: 'static',
                        keyboard: false,
                    });

                }

                // Check existing subscription product for order
                else if (parseInt(results.response.code) === 3000) {

                    $("#subscriptionProductErrorModal").modal({
                        backdrop: 'static',
                        keyboard: false,
                    });

                    this.setState({
                        isLoading: false,
                        place_order_loading: false,
                        isFormValid: false,
                        subscriptionProductErrorMessage: results.response.message,
                    });

                }

                else {
                    this.orderSubmittedResult(results);
                }

            });

        } else {
            this.setState({
                isLoading: false,
                place_order_loading: false,
                isFormValid: false
            });
            document.querySelector("body").scrollIntoView(); // Scroll to top
        }
    }

    forceOrderSubmit = (e) => {
        this.setState({ force_order: 1 })
        this.onSubmitHandler(e, 1);
    }

    orderSubmittedResult = (results) => {
        if (parseInt(results.response.code) === 1000) {
            DESTROY_CART(); // Destroy cart information from localStorage
            this.setState({
                server_message: results.response.message,
                isLoading: false,
                place_order_loading: false,
                isFormValid: false,
                session_id: ''
            });
            document.querySelector("body").scrollIntoView(); // Scroll to top
            history.push('/order-received/' + results.response.data.order_id);
        } else {
            if (parseInt(results.response.code) === 3001) {
                this.setState({ addressVerifyCode: true })
            }
            if (parseInt(results.response.code) === 5002) {
                this.setState({ session_id: results.response.session_id })
            }
            this.setState({
                server_message: results.response.message,
                isLoading: false,
                place_order_loading: false,
                isFormValid: false
            });
            document.querySelector("body").scrollIntoView(); // Scroll to top
        }
    }

    applyCoupon = (e) => {
        e.preventDefault();
        let couponCode = (this.state.coupon_code).trim();

        this.setState({
            isFormValid: true,
            coupon_loading: true,
            success_alert_wrapper_show_coupon: false
        })

        if (couponCode == '' || couponCode == null) {
            this.setState({
                server_message: "The coupon code field is required.",
                isLoading: false,
                coupon_loading: false,
                isFormValid: false,
            });
        } else {

            let couponExists = false;
            let exCouponList = [];
            if (!GET_STORAGE("coupon")) {
                SET_STORAGE("coupon", JSON.stringify(exCouponList));
            }
            exCouponList = JSON.parse(GET_STORAGE("coupon"));

            if (exCouponList.length > 0) {
                exCouponList.forEach(function (exCoupon, key) {
                    if (exCoupon.coupon_code.toUpperCase() === couponCode.toUpperCase()) {
                        couponExists = true;
                    }
                });
            }

            if (!couponExists) {
                let applyCouponCode = [];
                if (exCouponList.length > 0) {
                    exCouponList.forEach(function (couponData, key) {
                        applyCouponCode.push(couponData.coupon_code);
                    });
                }
                applyCouponCode.push(couponCode);

                let data = {
                    coupon_code: applyCouponCode,
                    cart_items: JSON.parse(GET_STORAGE("cart")),
                    meals: JSON.parse(GET_STORAGE("meals")),
                }

                AJAX_REQUEST("POST", "coupon/applyCoupon", data).then(results => {
                    if (parseInt(results.response.code) === 1000) {

                        this.setState({
                            success_alert_wrapper_show_coupon: false,
                            coupon_loading: false,
                            coupon_code: '',
                            server_message: results.response.message,
                            success_alert_wrapper_show: true
                        })

                        let couponResponse = results.response.data;
                        if (couponResponse || couponResponse != '') {
                            exCouponList = [];
                            couponResponse.forEach(function (couponData, key) {
                                exCouponList.push(couponData);
                            });
                        }

                        SET_STORAGE("coupon", JSON.stringify(exCouponList));
                        this.getAllShippingMethods();
                        this.getCart();
                        this.getTax();
                    } else {
                        this.setState({
                            server_message: results.response.message,
                            isLoading: false,
                            coupon_loading: false,
                            isFormValid: false,
                            error: results.response.message,
                        });
                    }
                    this.timeOut(5000);
                });
            } else {
                this.setState({
                    server_message: "The coupon code already applied.",
                    isLoading: false,
                    coupon_loading: false,
                    isFormValid: false,
                });
            }
        }
    }

    reApplyCoupon = () => {
        if (GET_STORAGE("coupon")) {
            let exCouponList = JSON.parse(GET_STORAGE("coupon"));
            let coupon_code = [];
            exCouponList.forEach(function (couponData, key) {
                coupon_code.push(couponData.coupon_code);
            });
            let data = {
                coupon_code: coupon_code,
                cart_items: JSON.parse(GET_STORAGE("cart")),
                meals: JSON.parse(GET_STORAGE("meals")),
            }
            AJAX_REQUEST("POST", "coupon/applyCoupon", data).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    SET_STORAGE("coupon", JSON.stringify(results.response.data));
                } else if (parseInt(results.response.code) === 4000) {
                    REMOVE_STORAGE('coupon')
                }
                this.setState({
                    isCouponReapply: 1
                });
                this.getAllShippingMethods();
                this.getTax();
                this.getCart();
            });
        }
    }

    deleteCoupon = (e, row_id) => {
        e.preventDefault();
        if (window.confirm("Are you sure want to delete coupon?")) {
            let coupon = JSON.parse(GET_STORAGE('coupon'));
            if (coupon.splice(row_id, 1)) {
                SET_STORAGE("coupon", JSON.stringify(coupon));
                this.setState({
                    freeShipping: 0,
                    mealShippingCostStatus: true,
                    server_message: 'The coupon code successfully removed.',
                    success_alert_wrapper_show_coupon: false,
                    coupon_code: '',
                    success_alert_wrapper_show: true,
                    isCouponReapply: 1
                });
                document.querySelector("body").scrollIntoView();
                this.timeOut(5000);
                this.reApplyCoupon();
                this.getAllShippingMethods();
                this.getCart();
            }
        }
    }

    showCouponAction = (e) => {
        e.preventDefault();
        this.setState({
            show_coupon: this.state.show_coupon ? false : true
        })
    }

    haveExistsUser = (e) => {
        let data = { email: this.state.billing_email }
        AJAX_REQUEST("POST", "user/haveExists", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    haveExistsUser: results.response.data.have_exists,
                    password_check: (results.response.data.have_exists == 1) ? 0 : 1,
                })
            } else {
                this.setState({
                    haveExistsUser: 1,
                    password_check: 0
                })
            }
        });
    }
    automatedPassword = (e) => {
        if (document.getElementById("password_check").checked) {
            this.setState({ password_check: 0 })
        } else {
            this.setState({ password_check: 1 })
        }
    }

    backToCart = () => {
        $('#stockoutErrorModal').modal('hide');
        history.push('/cart');
    }


    render() {
        var efa_status = false;
        var meal_status = false;
        const {
            errors,
            server_message,
            show_coupon,
            coupon_code_button_loading,
            success_alert_wrapper_show,
            place_order_loading
        } = this.state;
        const errors_data = server_message;

        let cartTotal = 0;
        if (this.state.items.length > 0) {
            if (this.state.meals != null && this.state.meals != '' && this.state.meals.items.length > 0 && this.state.mealShippingCostStatus == true) {
                cartTotal = Number(CART_SUB_TOTAL()) + Number(MEAL_TOTAL()) + Number(this.state.cartShippingCost) + Number(this.state.tax_amount) + Number(this.state.meal_tax_amount) - Number(COUPON_TOTAL());
            } else {
                cartTotal = Number(CART_SUB_TOTAL()) + Number(MEAL_SUB_TOTAL()) + Number(this.state.cartShippingCost) + Number(this.state.tax_amount) - Number(COUPON_TOTAL());
            }
        } else if(this.state.bundleItems.length > 0){
            cartTotal = Number(BUNDLE_SUB_TOTAL()) + Number(MEAL_SUB_TOTAL()) + Number(this.state.cartShippingCost) + Number(this.state.tax_amount);
        }else {
            if (this.state.meals != null && this.state.meals != '' && this.state.meals.items.length > 0 && this.state.mealShippingCostStatus == true) {
                cartTotal = Number(MEAL_TOTAL()) + Number(this.state.meal_tax_amount) - Number(COUPON_TOTAL());
                meal_status = true;
            } else {
                cartTotal = Number(MEAL_SUB_TOTAL()) + Number(this.state.meal_tax_amount) - Number(COUPON_TOTAL());
            }
        }
        this.setState({ cartTotal: cartTotal })

        this.state.items.forEach(function (item, key) {
            if (item.cart_product_name == "EFA") {
                efa_status = true;
            }
        });
        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className='loading container full_page_loader'></div>
                        :
                        <Fragment>
                            {
                                ((this.state.shipping_country == "CA" && efa_status) || ((this.state.shipping_state == "AK" || this.state.shipping_state == "HI") && meal_status)) ?
                                    <PreventCheckoutModal productLink="#" /> : <br />
                            }
                            <div className="checkout_page">
                                <div className="container-fluid form_input_blk">
                                    <form id="orderForm" onSubmit={this.onSubmitHandler}>
                                        <Row>
                                            <Col md={8} className="checkout_left_blk white_bg">
                                                <AlertWrapper errors_data={errors_data} isFormValid={this.state.isFormValid} />
                                                <AlertWrapperSuccess errors_data={errors_data} success_alert_wrapper_show={success_alert_wrapper_show} />

                                                {
                                                    (!this.state.addressVerifyCode) ? ''
                                                        :
                                                        <Fragment>
                                                            <p className="promotion_and_product_updates addess-verification w-100 m-0">
                                                                <label className="checkbox_label m-0 d-flex align-items-center">
                                                                    <input onClick={this.continueWithoutVerify} id='continue_without_verify' className="" type="checkbox" />
                                                                    <span className="checbox"></span>
                                                                    <span className="d-block font_14 text_black fw_ar_reg pl_10">Continue without verify address?</span>
                                                                </label>
                                                            </p>
                                                        </Fragment>
                                                }
                                                <NavLink to={'/cart'} className="d-flex back_navigation">
                                                    <img src={LeftArrow} />
                                                    <span className="d-block pl_4 font_12 text_black fw_ar_reg">Return to cart</span>
                                                </NavLink>
                                                <div className="text-center mb_31">
                                                    <img src={CheckoutLogo} alt="checkout logo" />
                                                </div>
                                                <ul className="checkout_ul w-100 d-flex align-items-center mb_27">
                                                    <li className='d-flex align-items-center justify-content-center active selected'>
                                                        <span className="d-flex align-items-center justify-content-center white_bg font_11 text_black checkout_round fw_ar_reg">1</span>
                                                        <span className="font_16 d-block text_black fw_ar_reg pl_10">Information</span>
                                                    </li>
                                                    <li className='d-flex align-items-center justify-content-center active selected'>
                                                        <span className="d-flex align-items-center justify-content-center white_bg font_11 text_black checkout_round fw_ar_reg">2</span>
                                                        <span className="font_16 d-block text_black fw_ar_reg pl_10">Shipping</span>
                                                    </li>
                                                    <li className='d-flex align-items-center justify-content-center active'>
                                                        <span className="d-flex align-items-center justify-content-center white_bg font_11 text_black checkout_round fw_ar_reg">3</span>
                                                        <span className="font_16 d-block text_black fw_ar_reg pl_10">Payment</span>
                                                    </li>
                                                </ul>
                                                <span className="d-block text-center text_black font_12 fw_ar_reg">Step 3</span>
                                                <h4 className="font_16 text-center text_black mb_41 fw_ar_reg">Payment</h4>
                                                <div className="shipping_blk mb_26">
                                                    <div className="shipping_info border_btm d-flex align-items-center justify-content-between">
                                                        <div className="shipping_left d-flex align-items-center">
                                                            <span className="d-block font_16 items_text fw_ar_reg contact_label">Contact </span>
                                                            <span className="d-block pl_6 font_16 text_black fw_ar_reg">{this.state.billing_email_id}</span>
                                                        </div>
                                                        <div className="ship_rgt d-flex">
                                                            <a href="/checkout" className="font_16 items_text d-flex text_underline fw_ar_reg">Change</a>
                                                        </div>
                                                    </div>
                                                    <div className="shipping_info border_btm d-flex align-items-center justify-content-between">
                                                        <div className="shipping_left d-flex align-items-center">
                                                            <span className="d-block font_16 items_text fw_ar_reg contact_label">Billing Address </span>
                                                            <span className="d-block pl_6 font_16 text_black fw_ar_reg">{[this.state.billing_address_first, ',', this.state.billing_address_second, ',', this.state.billing_city_1, ',', this.state.billing_state_1, ',', this.state.billing_postcode_1]}</span>
                                                        </div>
                                                        <div className="ship_rgt d-flex">
                                                            <a href="/checkout" className="font_16 items_text d-flex text_underline fw_ar_reg">Change</a>
                                                        </div>
                                                    </div>
                                                    <div className="shipping_info border_btm d-flex align-items-center justify-content-between">
                                                        <div className="shipping_left d-flex align-items-center">
                                                            <span className="d-block font_16 items_text fw_ar_reg contact_label">Shipping Address </span>
                                                            <span className="d-block pl_6 font_16 text_black fw_ar_reg">{[this.state.shipping_address_first, ',', this.state.shipping_address_second, ',', this.state.shipping_city_2, ',', this.state.shipping_state_2, ',', this.state.shipping_postcode_2]}</span>
                                                        </div>
                                                        <div className="ship_rgt d-flex">
                                                            <a href="/checkout" className="font_16 items_text d-flex text_underline fw_ar_reg">Change</a>
                                                        </div>
                                                    </div>
                                                    <div className="shipping_info d-flex align-items-center justify-content-between">
                                                        <div className="shipping_left d-flex align-items-center">
                                                            <span className="d-block font_16 items_text fw_ar_reg contact_label">Method </span>
                                                            <div className="left_methid d-flex align-items-center">
                                                                {/* <label htmlFor="" className="d-flex align-items-center checkbox_label radio_label m-0"> */}
                                                                {/* <input type="radio" checked /> */}
                                                                <span className="checbox"></span>
                                                                {
                                                                    // // (this.state.items.length <= 0) ? ''
                                                                    //     :
                                                                        <Fragment>
                                                                            {
                                                                                // Start cart shipping
                                                                                (this.state.freeShipping == 0) ?
                                                                                    <Fragment>
                                                                                        {
                                                                                            (this.state.shippingMethods.length <= 0) ? null :
                                                                                                this.state.shippingMethods.map(function (shipping, key) {
                                                                                                    if (shipping.allow_for_coupon == 0 && ((this.state.shipping_method_id == shipping.id || GET_STORAGE("cartMethodId") == shipping.id))) {
                                                                                                        return (
                                                                                                            <Fragment key={key}>
                                                                                                                {/* <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                                                    <span className="d-flex align-items-center checkbox_label radio_label m-0">{shipping.label}&emsp;&emsp;&emsp;{CURRENCY_FORMAT(shipping.cost)}</span>
                                                                                                                </li> */}   
                                                                                                                <div className="shipping_left w-100 d-flex align-items-center">
                                                                                                                    <label className="d-flex align-items-center checkbox_label radio_label m-0">
                                                                                                                        {
                                                                                                                            (this.state.shipping_method_id == shipping.id || GET_STORAGE("cartMethodId") == shipping.id) ?
                                                                                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onClick={this.changeCartShippingMethod} price={shipping.cost} cid={shipping.id} defaultChecked="checked" type="radio" name="orderShippingMethod" />
                                                                                                                                :
                                                                                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onClick={this.changeCartShippingMethod} price={shipping.cost} cid={shipping.id} type="radio" name="orderShippingMethod" />
                                                                                                                        }
                                                                                                                        <span className="checbox"></span>
                                                                                                                        <span className="d-block pl_6 font_16 text_black fw_ar_reg">{shipping.label} {CURRENCY_FORMAT(shipping.cost)}</span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </Fragment>
                                                                                                        )
                                                                                                    }
                                                                                                }.bind(this))
                                                                                        }
                                                                                    </Fragment>
                                                                                    :
                                                                                    <Fragment>
                                                                                        {
                                                                                            (this.state.shippingMethods.length <= 0) ? null :
                                                                                                this.state.shippingMethods.map(function (shipping, key) {
                                                                                                    if (GET_STORAGE("cartMethodId") == shipping.id) {
                                                                                                        SET_STORAGE("cartMethodId", shipping.id);
                                                                                                        return (
                                                                                                            <Fragment key={key}>
                                                                                                                <div className="shipping_left d-flex align-items-center">
                                                                                                                    <label className="d-flex align-items-center checkbox_label radio_label m-0">
                                                                                                                        {
                                                                                                                            (this.state.shipping_method_id == shipping.id || GET_STORAGE("cartMethodId") == shipping.id) ?
                                                                                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onClick={this.changeCartShippingMethod} price={shipping.cost} cid={shipping.id} defaultChecked="checked" type="radio" name="orderShippingMethod" />
                                                                                                                                :
                                                                                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onClick={this.changeCartShippingMethod} price={shipping.cost} cid={shipping.id} type="radio" name="orderShippingMethod" />
                                                                                                                        }
                                                                                                                        <span className="checbox"></span>
                                                                                                                        <span className="d-block pl_6 font_16 text_black fw_ar_reg">{shipping.label}</span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </Fragment>
                                                                                                        )
                                                                                                    }
                                                                                                }.bind(this))
                                                                                        }
                                                                                    </Fragment>
                                                                                // End cart shipping
                                                                            }
                            
                                                                        </Fragment>
                                                                }
                                                                {/* </label> */}
                                                            </div>
                                                        </div>
                                                        <div className="ship_rgt d-flex">
                                                            <a href="/checkout-shipping" className="font_16 items_text d-flex text_underline fw_ar_reg">Change</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                { 
                                                (this.state.cartTotal > 0) ? 
                                                <div className="payment_method mb_26">
                                                    <h4 className="font_16 text_black fw_ar_reg mb_7">Payment</h4>
                                                    <p className="para mb_14 font_12 text_black fw_ar_reg">All transactions are secure and encrypted.</p>
                                                    {this.state.payment_method.map((payment, pay_index) => (
                                                        <div className="pay_lk  payment_div_method authorize_blk position-relative">
                                                            {
                                                                (
                                                                    (this.state.cartTotal < 0 && GET_STORAGE("meals") && this.state.meals.subscription == 'no')
                                                                    ||
                                                                    (this.state.cartTotal < 0 && COUNT_SUBSCRIPTION() == 0 && !GET_STORAGE("meals"))
                                                                ) ? "" :
                                                                    (payment.gateway_class == 'authorize' && this.state.cartTotal > 0) || (payment.gateway_class == 'sezzle' && this.state.cartTotal > 0) || (payment.gateway_class == 'paypal' && this.state.cartTotal > 0)?
                                                                    <Fragment>
                                                                        <input onChange={this.changeHandler} type="radio" name="select_payment" id="select_payment" className={`select_payment ${payment.gateway_class == "paypal" && (this.state.hasSubscriptionYes == false || this.state.hasSubscriptionNo == false) ? "paypal" : payment.gateway_class == "sezzle" && this.state.hasSubscriptionYes == true ? "disabled" : payment.gateway_class == "paypal" ? "disabled" : "abled"}`} value={payment.gateway_class} defaultChecked={pay_index == 0 ? 'checked' : ''} disabled={payment.gateway_class == "sezzle" && this.state.hasSubscriptionYes == true} />
                                                                        <div className={`pay_header d-flex align-items-center justify-content-between`}>
                                                                            <label className="d-flex align-items-center credit_card_radio checkbox_label radio_label m-0 mr_32">
                                                                                <span className="checbox"></span>

                                                                                {/* <span className="pl_6 text_black font_16 fw_ar_reg">Credit Card</span> */}
                                                                            </label>
                                                                            {(payment.gateway_class == 'authorize') ?

                                                                                (
                                                                                    (this.state.cartTotal < 0 && GET_STORAGE("meals") && this.state.meals.subscription == 'no')
                                                                                    ||
                                                                                    (this.state.cartTotal < 0 && COUNT_SUBSCRIPTION() == 0 && !GET_STORAGE("meals"))
                                                                                ) ? "" :
                                                                                    <Fragment>
                                                                                        <div className="d-flex align-items-center justify-content-between auth_div">
                                                                                            <label htmlFor="payment_method_icanpay" className='font_14 text_black m-0 fw_ar_semi'> {payment.title}  </label>
                                                                                            <div className="d-flex align-items-center">
                                                                                                <img src={CreditCard} alt={this.state.payment_method} />
                                                                                                <a href="javascript:void(0);" className="choose_text font_12 fw_ar_reg ml_13 d-flex">and more...</a>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <div className="d-flex align-items-center"> */}
                                                                                        {/* <label htmlFor="payment_method_icanpay"> {payment.title}  </label> */}
                                                                                        {/* <img src={CreditCard} alt={this.state.payment_method} /> */}
                                                                                        {/* <a href="javascript:void(0);" className="choose_text font_12 fw_ar_reg ml_13 d-flex">and more...</a> */}
                                                                                        {/* </div> */}
                                                                                    </Fragment>
                                                                                :   (payment.gateway_class == 'paypal') ?
                                                                                <Fragment>
                                                                                    <div className="d-flex align-items-center justify-content-between auth_div">
                                                                                        <label htmlFor="payment_method_icanpay" className='font_14 text_black m-0 fw_ar_semi'> {payment.title}  </label>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <img src={PayPalIcon } className='ml_6 sezzle_img' alt={this.state.payment_method} />
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        ((this.state.hasSubscriptionYes == false || this.state.hasSubscriptionNo == false)) ? "" :  <span className='font_14 red_text fw_ar_reg'>*One-time product and subscription product not available with PayPal. Please separate products onto two separate orders to proceed with PayPal.</span>
                                                                                    }
                                                                                    
                                                                                </Fragment>     
                                                                                :  (payment.gateway_class == 'sezzle')?
                                                                                <Fragment>
                                                                                        <div className="d-flex align-items-center justify-content-between auth_div">
                                                                                            <label htmlFor="payment_method_icanpay" className='font_14 text_black m-0 fw_ar_semi'> {payment.title}  </label>
                                                                                            <div className="d-flex align-items-center">
                                                                                                <img src={SezzleIcon}  className='ml_6 sezzle_img' alt={this.state.payment_method} />
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            ((this.state.hasSubscriptionYes == false)) ? "" :  <span className='font_14 red_text fw_ar_reg'>*Sezzle is unavailable for subscribe and save.</span>
                                                                                        }
                                                                                </Fragment> : ""
                                                                            }
                                                                        </div>
                                                                    </Fragment> : ""
                                                            }
                                                            {(payment.gateway_class == 'authorize') ?
                                                                (
                                                                    (this.state.cartTotal < 0 && GET_STORAGE("meals") && this.state.meals.subscription == 'no')
                                                                    ||
                                                                    (this.state.cartTotal < 0 && COUNT_SUBSCRIPTION() == 0 && !GET_STORAGE("meals"))
                                                                ) ? "" :
                                                                    <Fragment>
                                                                        <div className="cpay_body form_input_blk auth_hide">
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <div className="mb_12">
                                                                                        <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} value={this.state.name_on_card} type="text" required={this.state.authorize_state} className={classnames("form-control height_42", { 'pl_error_input': errors.name_on_card })} name="name_on_card" placeholder="Card Holder Name" />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <div className="mb_12">
                                                                                        <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} value={this.state.card_number} type="text" required={this.state.authorize_state} className={classnames("form-control height_42", { 'pl_error_input': errors.card_number })} name="card_number" maxLength="16" autoComplete="off" placeholder="Card Number" />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <div className="mb_12 d-flex">
                                                                                        <select disabled={this.state.isFormDisabled} onChange={this.changeHandler} required={ this.state.authorize_state} className={classnames("pull-left form-control height_42", { 'pl_error_input': errors.expire_month })} name="expire_month" >
                                                                                            <option value="">Month</option>
                                                                                            <CartMonths />
                                                                                        </select>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <div className="mb_12 d-flex">
                                                                                        <select disabled={this.state.isFormDisabled} onChange={this.changeHandler} required={this.state.authorize_state} className={classnames("pull-right form-control height_42", { 'pl_error_input': errors.expire_year })} name="expire_year" >
                                                                                            <option value="">Year</option>
                                                                                            <CartYears />
                                                                                        </select>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <div className="mb_12">
                                                                                        <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} value={this.state.cvv} type="text" required={this.state.authorize_state} className={classnames("form-control height_42", { 'pl_error_input': errors.cvv })} name="cvv" maxLength="4" placeholder="CVV" />
                                                                                    </div>
                                                                                </Col>
                                                                                {/* <Col md={12}>
                                                                                            <div className="mb_12 d-flex align-items-center w-100">
                                                                                                <Row className='upi_row'>
                                                                                                    <Col md={4}>
                                                                                                        <label htmlFor="" className="d-flex payment_upi align-items-center checkbox_label radio_label m-0 w-100">
                                                                                                            <input type="radio" />
                                                                                                            <span className="checbox"></span>
                                                                                                            <img src={PayPalIcon} alt="paypal_icon" className='ml_6' />
                                                                                                        </label>
                                                                                                    </Col>
                                                                                                    <Col md={4}>
                                                                                                        <label htmlFor="" className="d-flex payment_upi align-items-center checkbox_label radio_label m-0 w-100">
                                                                                                            <input type="radio" />
                                                                                                            <span className="checbox"></span>
                                                                                                            <img src={AmazonIcon} alt="amazon icon" className='ml_6' />
                                                                                                        </label>
                                                                                                    </Col>
                                                                                                    <Col md={4}>
                                                                                                        <label htmlFor="" className="d-flex payment_upi align-items-center checkbox_label radio_label m-0 w-100">
                                                                                                            <input type="radio" />
                                                                                                            <span className="checbox"></span>
                                                                                                            <img src={SezzleIcon} alt="sezzle icon" className='ml_6' />
                                                                                                        </label>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                        </Col> */}

                                                                            </Row>
                                                                        </div>
                                                                    </Fragment>
                                                                : ""}
                                                        </div>
                                                    ))}
                                                </div> : ""
                                                }
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="">
                                                            <label className="d-flex align-items-center checkbox_label m-0 ">
                                                                <input disabled={this.state.isFormDisabled} onClick={this.agreeTermsPolicy} id="agreeTermsPolicy" type="checkbox" className="form-check-input" />
                                                                <span className="checbox"></span>
                                                                <span className="pl_6 text_black font_16 fw_ar_reg">I agree to the <NavLink className="font_16 text_black text_underline" to={`${this.state.terms_of_use}`} target="_blank">Terms and Conditions</NavLink> and <NavLink className="font_16 text_black text_underline" to={`${this.state.privacy_policy}`} target="_blank">Privacy Policy</NavLink>. <NavLink className="font_16 text_black text_underline" to="/cancellation-process" target="_blank">Cancellation Process & Information</NavLink></span>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="mt_43 d-flex">
                                                    <a href='/checkout-shipping' className="d-flex align-items-center">
                                                        <img src={BackArrow} alt="BackArrow" />
                                                        <span className="d-block pl_4 font_12 text_black fw_ar_reg">Return to shipping</span>
                                                    </a>
                                                </div>

                                            </Col>
                                            <Col md={4} className="checkout_rgt_blk">
                                                <div className="cart_summary_blk white_bg mb_41">
                                                    <div className="d-flex align-items-center mb_18">
                                                        <img src={CartSummaryIcon} alt="cart summary icon" />
                                                        <span className="d-block pl_6 font_14 let_spa_2 text_black">Cart Summary</span>
                                                    </div>
                                                    {
                                                        (this.state.items.length <= 0) ? null :
                                                            this.state.items.map(function (item, key) {
                                                                return (
                                                                    <Fragment key={key}>
                                                                        <div className="cart_pro_su d-flex align-items-start justify-content-between">
                                                                            <div className="left_blk">
                                                                                <span className="d-block text_black left_spa_2 font_16 fw_el_bold">{item.cart_product_name}</span>
                                                                                <span className="d-block items_text left_spa_2 font_12 fw_ar_reg">{item.cart_variation_name}</span>
                                                                            </div>
                                                                            <div className="right_blk">
                                                                                <span className="d-block font_14 fw_ar_bold text_black text-right">
                                                                                    {
                                                                                        (item.subscription == "yes") ?
                                                                                            CURRENCY_FORMAT(item.quantity * item.cart_discount_price)
                                                                                            :
                                                                                            CURRENCY_FORMAT(item.quantity * item.cart_sale_price)
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            }.bind(this))
                                                    }
                                                    <ul className="pro_cart_det mt_30">
                                                        {
                                                            (this.state.items.length <= 0) ? '' :
                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Sub Total</span>
                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(Number(CART_SUB_TOTAL()) + Number(MEAL_SUB_TOTAL()))}</span>
                                                            </li>
                                                        }
                                                        {
                                                            (this.state.bundleItems.length <= 0) ? '' :
                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Sub Total</span>
                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(Number(BUNDLE_SUB_TOTAL()) + Number(MEAL_SUB_TOTAL()))}</span>
                                                            </li>
                                                        }
                                                        {
                                                            // Start coupon list
                                                            (this.state.coupons.length <= 0) ? "" :
                                                                this.state.coupons.map(function (coupon, key) {

                                                                    if (coupon.free_shipping == 1 && coupon.coupon_type == "product") {
                                                                        this.setState({
                                                                            freeShipping: 1,
                                                                            cartShippingCost: 0,
                                                                        })
                                                                    } else if (coupon.free_shipping == 1 && coupon.coupon_type == "meal") {
                                                                        this.setState({
                                                                            mealShippingCostStatus: false,
                                                                        })
                                                                    } else if (coupon.free_shipping == 1 && coupon.coupon_type == "any") {
                                                                        this.setState({
                                                                            freeShipping: 1,
                                                                            cartShippingCost: 0,
                                                                            mealShippingCostStatus: false
                                                                        });
                                                                    }

                                                                    return (
                                                                        <Fragment key={key}>
                                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">COUPON: <span className="fw_ar_bold">{coupon.coupon_code}</span> </span>
                                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(coupon.discount_amount)}</span>
                                                                                <a onClick={(e) => this.deleteCoupon(e, key)} className="red_text font_14 w-100 d-flex fw_ar_reg" href="javascript:void(0)">Remove</a>
                                                                            </li>

                                                                        </Fragment>
                                                                    )
                                                                }.bind(this))
                                                            // End coupon list
                                                        }
                                                        {
                                                            // (this.state.items.length <= 0) ? '' :
                                                                <Fragment>
                                                                    {
                                                                        // Start cart shipping

                                                                        (this.state.freeShipping == 0) ?
                                                                            <Fragment>
                                                                                {
                                                                                    (this.state.shippingMethods.length <= 0) ? null :
                                                                                        this.state.shippingMethods.map(function (shipping, key) {
                                                                                            if (shipping.allow_for_coupon == 0 && ((this.state.shipping_method_id == shipping.id || GET_STORAGE("cartMethodId") == shipping.id))) {
                                                                                                return (
                                                                                                    <Fragment key={key}>
                                                                                                        <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Shipping Cost</span>
                                                                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(shipping.cost)}</span>
                                                                                                        </li>
                                                                                                    </Fragment>
                                                                                                )
                                                                                            }
                                                                                        }.bind(this))
                                                                                }
                                                                            </Fragment>
                                                                            :
                                                                            <Fragment>
                                                                                {
                                                                                    (this.state.shippingMethods.length <= 0) ? null :
                                                                                        this.state.shippingMethods.map(function (shipping, key) {
                                                                                            if (GET_STORAGE("cartMethodId") == 1) {
                                                                                                this.setState({
                                                                                                    cartShippingCost: shipping.cost
                                                                                                });
                                                                                            } else {
                                                                                                this.setState({
                                                                                                    cartShippingCost: 0
                                                                                                });
                                                                                            }
                                                                                            if (GET_STORAGE("cartMethodId") == shipping.id) {
                                                                                                SET_STORAGE("cartMethodId", shipping.id);
                                                                                                return (
                                                                                                    <Fragment key={key}>
                                                                                                        <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Shipping Cost</span>
                                                                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.cartShippingCost)}</span>
                                                                                                        </li>
                                                                                                    </Fragment>
                                                                                                )
                                                                                            }
                                                                                        }.bind(this))
                                                                                }
                                                                            </Fragment>
                                                                        // End cart shipping
                                                                    }
                                                                </Fragment>
                                                        }
                                                        {/* Start meal shipping cost for total */}
                                                        {
                                                            (this.state.meals != null && this.state.meals != '' && this.state.meals.items.length > 0) ?
                                                                <Fragment>
                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Meal Shipping Cost</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">
                                                                            {
                                                                                (this.state.meals.shipping_cost > 0 && this.state.mealShippingCostStatus == true) ?
                                                                                    CURRENCY_FORMAT(this.state.meals.shipping_cost)
                                                                                    : "Free Shipping"
                                                                            }
                                                                        </span>
                                                                    </li>
                                                                </Fragment>
                                                                : ''
                                                        }
                                                        {/* End meal shipping cost for total */}

                                                        {
                                                            (this.state.taxStatus == 0) ? ''
                                                                :
                                                                <Fragment>
                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Tax</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">
                                                                            {CURRENCY_FORMAT(Number(this.state.tax_amount) + Number(this.state.meal_tax_amount))}
                                                                        </span>
                                                                    </li>
                                                                </Fragment>
                                                        }
                                                        <li className="d-flex align-items-center flex-wrap mb_7">
                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Total</span>
                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">
                                                                {CURRENCY_FORMAT(CART_TOTAL_CURRENCY_FORMAT(this.state.cartTotal))}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="w-100">
                                                    {
                                                        (this.state.agreeTermsPolicy) ?
                                                            <button disabled={this.state.isLoading} type="submit" className={this.state.agreeTermsPolicy ? "w-100 justify-content-center d-flex cont_ship_btn shop_more take_quiz_btn font_16 text_white fw_ar_reg" : "w-100 justify-content-center d-flex cont_ship_btn shop_more take_quiz_btn font_16 text_white fw_ar_reg disable"} >{place_order_loading ? "Please Wait..." : "Place Order"}</button>
                                                            :
                                                            <button disabled className="w-100 justify-content-center d-flex cont_ship_btn shop_more take_quiz_btn font_16 text_white fw_ar_reg disable">Continue To Payment</button>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                    {/* Affiliate email check modal start  */}

                                    <div className="modal fade" id="affiliateErrorModal" tabIndex="-1" role="dialog" aria-labelledby="affiliateErrorModal" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header cus-modal-header">
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body montserrat">
                                                    <div className="text-danger cus-modal-title">Alert!</div>
                                                    <p className="modal-body-content">{this.state.affiliateErrorMessage}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Affiliate email check modal end  */}

                                    {/* Product stockout check modal start  */}

                                    <div className="modal fade" id="stockoutErrorModal" tabIndex="-1" role="dialog" aria-labelledby="stockoutErrorModal" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header cus-modal-header">
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body montserrat">
                                                    <div className="text-danger cus-modal-title">Alert!</div>
                                                    <p className="modal-body-content">{Parser(this.state.stockoutErrorMessage)}</p>
                                                    <NavLink to="#" onClick={this.backToCart} className="wc-forward">Back to Cart</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Product stock check modal end  */}

                                    {/* subscription product check modal start  */}

                                    <div className="modal fade" id="subscriptionProductErrorModal" tabIndex="-1" role="dialog" aria-labelledby="subscriptionProductErrorModal" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header cus-modal-header">
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body montserrat">
                                                    <div className="text-danger cus-modal-title">Alert!</div>
                                                    <p className="modal-body-content">{this.state.subscriptionProductErrorMessage}</p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button onClick={(e) => this.forceOrderSubmit(e)} type="button" className="cus_button" data-dismiss="modal">Agree &amp; Place Order</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* subscription product check modal end  */}

                                    {/* warning modal start  */}
                                    <div className="modal fade" id="warningModal" tabIndex="-1" role="dialog" aria-labelledby="warningModal" aria-hidden="true">
                                        <div className="modal-dialog modal-lg" role="document" style={{ marginTop: '5%' }}>
                                            <div className="modal-content warningModalContent">
                                                <div className="modal-header cus-modal-header">
                                                    <h3>Warnings</h3>
                                                    <button type="button" className="close pl-warning-close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body montserrat pl-warning-body">
                                                    <div className="mt-2 warning-block-cards" id="warningModalContent"></div>
                                                    <div className="d-flex align-items-center justify-content-center py-3">
                                                        <div className="d-flex flex-column">
                                                            <button className="btn mr-4 btn-close" onClick={this.warningModalCancelHandel} type="button" >Cancel</button>
                                                            <span className="warning-cancel-help-text">Remove item(s) from the cart</span>
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <button className="btn btn-confirm" data-dismiss="modal">OK Proceed</button>
                                                            <sapn className="warning-cancel-help-text"></sapn>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* warning modal end  */}

                                    {/* canadaRestrictedProductModal start  */}

                                    <div className="modal fade" id="canadaRestrictedProductModal" tabIndex="-1" role="dialog" aria-labelledby="canadaRestrictedProductModal" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header cus-modal-header">
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body montserrat">
                                                    <div className="text-danger cus-modal-title">Alert!</div>
                                                    <p className="modal-body-content" style={{ fontWeight: 'bold' }} id="canadaRestrictedProductModalBody"></p>
                                                </div>
                                                <div className="modal-footer" style={{ justifyContent: "center" }}>
                                                    <a href="/cart" className="">Back to Cart</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* canadaRestrictedProductModal end  */}
                                </div>
                            </div>
                        </Fragment >
                }
            </Fragment>
        );
    }
}

CheckoutPayment.propTypes = {
    checkoutRequest: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, { checkoutRequest })(CheckoutPayment);
