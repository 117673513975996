import React, { Component, Fragment } from 'react';
import { AJAX_REQUEST } from "../../../Constants/AppConstants";
import TeamMemberLists from './TeamMemberLists';
import Pagination from '../../Common/Pagination';
import OrderLists from './OrderLists';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';
import Accordion from 'react-bootstrap/Accordion';

let dist_team_members = [];

class OrderByMe extends Component {
    constructor(props) {
        super(props)
        this.state = {
            order_id: '',
            customer_name: '',
            team_member_id: '',
            is_affiliate_sale: '',
            order_type: '',
            orders: [],
            error_meg: '',
            error_meg_dtm: '',
            dis_team_members: [],
            total_records: 0,
            total_page: 0,
            per_page: 0,
            pagenum: 1,
            loading: true,
            filter_loading: false
        }
        document.title = "Orders -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        AJAX_REQUEST("POST", "distributor/getTeamMemberListOption", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                dist_team_members = results.response.data.team_members;
            } else {
                // console.log(results.response.message);
            }
        });

        AJAX_REQUEST("POST", "order/getList", {
            pagenum: 1
        }).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    orders: results.response.data.orders,
                    total_records: parseInt(results.response.data.total_records),
                    total_page: parseInt(results.response.data.total_page),
                    per_page: parseInt(results.response.data.per_page),
                    pagenum: parseInt(results.response.data.pagenum),
                    error_meg: results.response.message,
                    dis_team_members: dist_team_members
                });
            } else {
                this.setState({
                    loading: false,
                    error_meg: results.response.message
                });
            }
        });
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    pagenationHandle = (pageNumber) => {
        this.setState({
            loading: true
        });
        document.querySelector("body").scrollIntoView();
        const pagenum = parseInt(pageNumber);
        AJAX_REQUEST("POST", "order/getList", {
            pagenum: pagenum,
            team_member_id: document.getElementById("team_member_id").value,
            is_affiliate_sale: document.getElementById("is_affiliate_sale").value,
            order_type: document.getElementById("order_type").value,
            customer_name: document.getElementById("customer_name").value,
            order_id: document.getElementById("order_id").value,
        }).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    pagenum,
                    orders: results.response.data.orders,
                    total_records: parseInt(results.response.data.total_records),
                    total_page: parseInt(results.response.data.total_page),
                    per_page: parseInt(results.response.data.per_page),
                    error_meg: results.response.message
                });
            } else {
                this.setState({
                    loading: false,
                    error_meg: results.response.message,
                    total_records: 0,
                    total_page: 0,
                    per_page: 0,
                    pagenum: 1,
                    orders: []
                });
            }
        });
    }

    selectedMember = (event) => {
        const memberId = parseInt(event.target.value);
        this.setState({
            team_member_id: memberId
        });
    }

    selectedIsReferrer = (event) => {
        this.setState({
            is_affiliate_sale: event.target.value
        });
    }

    selectedOrderType = (event) => {
        this.setState({
            order_type: event.target.value
        });
    }

    onSubmitHandler = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            filter_loading: true
        });
        AJAX_REQUEST("POST", "order/getList", {
            team_member_id: document.getElementById("team_member_id").value,
            is_affiliate_sale: document.getElementById("is_affiliate_sale").value,
            order_type: document.getElementById("order_type").value,
            customer_name: document.getElementById("customer_name").value,
            order_id: document.getElementById("order_id").value,
            pagenum: 1
        }).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    filter_loading: false,
                    pagenum: 1,
                    orders: results.response.data.orders,
                    total_records: parseInt(results.response.data.total_records),
                    total_page: parseInt(results.response.data.total_page),
                    per_page: parseInt(results.response.data.per_page),
                    error_meg: results.response.message
                });
            } else {
                this.setState({
                    loading: false,
                    filter_loading: false,
                    error_meg: results.response.message,
                    total_records: 0,
                    total_page: 0,
                    per_page: 0,
                    pagenum: 1,
                    orders: []
                });
            }
        });
    }

    render() {
        const { user } = this.props.auth;
        return (
            <div className="woocommerce-MyAccount-content inner_content">
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <Fragment>
                            <h2 className="font_26 m-0 text_black let_spa_2 fw_el_bold">Orders</h2>
                            <h3 className="font_16 mb_15 text_black fw_ar_reg">Affiliate Dashboard &gt; Orders &gt; Overview</h3>
                            <div className="mb_20 table_search table_header_search d-flex align-items-center justify-content-between">
                                <form onSubmit={this.onSubmitHandler} method="post" className='w-100 table_search_select d-flex align-items-center justify-content-end'>
                                    <label className='items_text m-0 fw_ar_reg font_12'>Team Member</label>
                                    {
                                        user.sales_agent_feature === "enable" ?
                                            <Fragment>

                                                <select onChange={this.selectedMember} id="team_member_id" className="form-control" name="team_member_id" defaultValue={this.state.team_member_id} style={{ width: "110px" }}>
                                                    <option value="">Select One</option>
                                                    {
                                                        this.state.dis_team_members.length <= 0 ? Parser(this.state.error_meg_dtm) :
                                                            this.state.dis_team_members.map(function (disTeamMember, key) {
                                                                return (
                                                                    <TeamMemberLists
                                                                        key={key}
                                                                        disTeamMember={disTeamMember}
                                                                    />
                                                                )
                                                            })
                                                    }
                                                </select>

                                            </Fragment>
                                            :
                                            <Fragment>
                                                <input type="hidden" id="team_member_id" name="team_member_id" defaultValue="" />
                                            </Fragment>
                                    }
                                    <select onChange={this.selectedIsReferrer} id="is_affiliate_sale" className="form-control" name="is_affiliate_sale" defaultValue={this.state.is_affiliate_sale} style={{ width: "100px" }}>
                                        <option value="">Is Referrer</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                    <select onChange={this.selectedOrderType} id="order_type" className="form-control" name="order_type" defaultValue={this.state.order_type} style={{ width: "100px" }}>
                                        <option value="">All Types</option>
                                        <option value="supplement">Product</option>
                                        <option value="meal">Meal</option>
                                    </select>
                                    <input className="form-control" type="text" id="customer_name" name="customer_name" onChange={this.changeHandler} placeholder="Customer Name" value={this.state.customer_name} />
                                    <input className="form-control" type="text" id="order_id" name="order_id" onChange={this.changeHandler} placeholder="Order ID" value={this.state.order_id} style={{ width: "100px" }} />
                                    <input className="search_input_btn" type="submit" name="action" value="" />
                                </form>
                            </div>

                            <Accordion defaultActiveKey="0">
                                {
                                    this.state.orders.length <= 0 ? <div className='no_record_found'>{Parser(this.state.error_meg)}</div> :
                                        this.state.orders.map(function (order, key) {
                                            return (
                                                <Accordion.Item eventKey={'o' + key}>
                                                    <OrderLists
                                                        key={key}
                                                        order={order}
                                                        dist_team_members={dist_team_members}
                                                    />
                                                </Accordion.Item>
                                            )
                                        })
                                }

                            </Accordion>



                            <Pagination
                                pagenationHandle={this.pagenationHandle}
                                total_records={this.state.total_records}
                                total_page={this.state.total_page}
                                per_page={this.state.per_page}
                                pagenum={this.state.pagenum}
                            />

                            <div className="woocommerce-notices-wrapper"></div>
                        </Fragment>
                }
            </div>
        );
    }
}


OrderByMe.propTypes = {
    auth: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps)(OrderByMe);