import React, { Fragment, PureComponent } from 'react';
import { CURRENCY_FORMAT, AJAX_PUBLIC_REQUEST } from "../../Constants/AppConstants";
import ReactImageFallback from "react-image-fallback";
import classnames from 'classnames';

class CartProductList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        let product = this.props.product;
        if (this.state.quantity) {
            this.setState({ quantity: parseInt(this.state.quantity) })
        } else {
            this.setState({ quantity: parseInt(product.quantity) })
        }
        this._isMounted = true;
        this.getProductDetails();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    getProductDetails = () => {
        const productId = this.props.product.cart_product_id;
        const data = {
            product_ids: productId,
        };
    
        AJAX_PUBLIC_REQUEST("POST", "product/getProductStatus", data).then(results => {
            if (this._isMounted) { 
                if (parseInt(results.response.code) === 1000) {
                    const products_status = results.response.data[0].status;
                    this.setState(prevState => ({
                        product_status: products_status,
                    }));
                }
            }
        });
    }

    quantityIncrement = (e) => {
        this.setState({ quantity: parseInt(Number(this.state.quantity) + 1) })
    }

    quantityDecrement = (e) => {
        this.setState({ quantity: parseInt(Number(this.state.quantity) - 1) });
    }

    render() {

        let product = this.props.product;

        return (
            <Fragment>
                 <div className={classnames("cart_pro_list d-flex align-items-center justify-content-between white_bg", { 'pl_error_input': (this.state.product_status == "0")})} key={product.row_id}>
                    <div className="cart_pro_list_left d-flex align-items-center">
                        <div className="cart_pro_img">
                            <ReactImageFallback
                                src={product.cart_image}
                                fallbackImage={require('../../Assets/images/preloader.gif')}
                                initialImage={require('../../Assets/images/preloader.gif')}
                                alt=''
                                className="cart_product_img" />
                        </div>
                        <div className="cart_pro_det pl_13">
                            <h4 className="font_16 text_black let_spa_2 fw_el_bold m-0 line_height_25">{product.cart_product_name}</h4>
                            <span className="d-block items_text font_12 mb_15 fw_ar_reg">{product.cart_variation_name}</span>
                            <div data-title="Remove" className='d-flex'>
                                <a onClick={(e) => product.deleteItem(e, product.row_id)} href="#" className="font_12 d-flex red_text fw_ar_reg">Delete</a>
                            </div>
                        </div>
                    </div>
                    <div className="cart_quantity quan_cart_blk">
                    {   (this.state.product_status != "0") ?
                        <div data-title="Quantity" className="white_bg input-group refer_product_input_group">
                            <div className="input-group-prepend">
                                <button type="button" onClick={this.quantityDecrement} disabled={(this.state.quantity <= 1) ? "disabled" : ""} className="btn btn-sm"><i className="fa fa-minus"></i></button>
                            </div>
                            <input onChange={this.changeHandler} value={this.state.quantity} key={Math.random()} name="quantity[]" type="text" readOnly className="text-center form-control form-control-sm product_quantity_content refer_product_quantity_content" step="1" min="0" max="" title="Qty" size="4" pattern="[0-9]*" inputMode="numeric" />
                            <div className="input-group-prepend">
                                <button type="button" onClick={this.quantityIncrement} disabled={(parseInt(this.state.quantity) >= parseInt(product.in_stock)) ? "disabled" : ""} className="btn btn-sm"><i className="fa fa-plus"></i></button>
                            </div>
                        </div>: " "
                    }
                    </div>
                    <div className="cart_total">
                        {   (this.state.product_status != "0") ?
                            <h4 className="font_16 m-0 text_black let_spa_2 text-uppercase fw_ar_bold" data-title="total">
                                {
                                    (product.subscription == "yes") ?
                                        CURRENCY_FORMAT(product.cart_discount_price * product.quantity)
                                        :
                                        CURRENCY_FORMAT(product.cart_sale_price * product.quantity)
                                }</h4> : <h4 className="font_16 red_text fw_ar_bold" data-title="total">OUT OF STOCK</h4>
                        }
                    </div>
                </div>
                {/* <tr key={product.row_id} className="cart_page_data_list">
                    <td data-title="Remove" className="cart_product_remove">
                        <a onClick={(e) => product.deleteItem(e, product.row_id)} href="#" className="remove"><i className="fa fa-times" aria-hidden="true"></i></a>
                    </td>
                    <td data-title="Product" className="product-thumbnail">
                        <div className="cart_page_product_img distributor_cart_product">
                            <ReactImageFallback
                                src={product.cart_image}
                                fallbackImage={require('../../Assets/images/preloader.gif')}
                                initialImage={require('../../Assets/images/preloader.gif')}
                                alt=''
                                className="cart_product_img" />
                        </div>
                        <div className="cart_product_details distributor_cart_details mob_left_right_none">
                            <a href="#">
                                {product.cart_product_name} - &nbsp;
                                {product.cart_variation_name}
                            </a>
                            {
                                (product.subscription == "yes") ?
                                    <dl className="variation">
                                        <dt className="variation-Every1Months"> {product.cart_subscription_msg}: </dt>
                                        <dd className="variation-Every1Months"><p>{CURRENCY_FORMAT(product.cart_discount_price)} each</p></dd>
                                    </dl>
                                    : null
                            }
                        </div>
                    </td>
                    <td data-title="Price" className="cart_product_price text-right">
                        <span className="Price-currencySymbol">
                            {
                                (product.subscription == "yes") ?
                                    CURRENCY_FORMAT(product.cart_discount_price)
                                    :
                                    CURRENCY_FORMAT(product.cart_sale_price)
                            }
                        </span>
                    </td>
                    <td data-title="Quantity" className="cart_product_number text-center">
                        <button type="button" onClick={this.quantityDecrement} disabled={this.state.quantity <= 1 ? "disabled" : ""} className="decrement btn btn-sm">-</button>
                        <input onChange={this.changeHandler} value={this.state.quantity} key={Math.random()} name="quantity[]" type="text" readOnly className="input-text qty text" step="1" min="0" max="" title="Qty" size="4" pattern="[0-9]*" inputMode="numeric" />
                        <button type="button" onClick={this.quantityIncrement} disabled={parseInt(this.state.quantity) >= parseInt(product.in_stock) ? "disabled" : ""} className="increment btn btn-sm">+</button>
                    </td>
                    <td data-title="Total" className="cart-product-subtotal text-right">
                        <span className="Price-currencySymbol">
                            {
                                (product.subscription == "yes") ?
                                    CURRENCY_FORMAT(product.cart_discount_price * product.quantity)
                                    :
                                    CURRENCY_FORMAT(product.cart_sale_price * product.quantity)
                            }
                        </span>
                    </td>
                </tr> */}
            </Fragment>
        );
    }
}

export default CartProductList;